import { ReactNode } from "react";
import { PageTitle } from "./PageTitle";
import { SimpleButton } from "./Button";
import React from "react";

interface PageBarProps {
    title:     ReactNode;
    children?: ReactNode;
}

export function PageBar({ title, children }: PageBarProps) {
    return <div className="d-flex align-items-center gap-2">
        <PageTitle>
            {title}
        </PageTitle>
        {children}
    </div>;
}

interface ToolButtonProps {
    className?: string;
    onClick:    () => void;
    children:   React.ReactNode;
}

export function ToolButton( { className = "", onClick, children }: ToolButtonProps ) {    
    return <SimpleButton variant="sm"                     
                  className={`d-flex-align-center px-2 ${className}`}
                  style={{ paddingTop: "2px", paddingBottom: "2px" }}
                  onClick={onClick}>
        {children}
    </SimpleButton>;
}