import { Card, CardBody, ListGroup, ListGroupItem } from "react-bootstrap";
import { del } from "../Data/Util";
import { Button, SimpleButton } from "./Button";
import { getStateEnumFromCode, showStateName } from "../Data/UsState";
import { VehicleDetails } from "../Data/ApiTypes";
import { CarFrontFill } from "react-bootstrap-icons";
import { VehicleLinkType } from "../Data/ApiTransport";
import { Hyperlink } from "./Hyperlink";
import { useParamIds } from "../Data/Common";

export interface VehicleCardProps {
    veh:         VehicleDetails;
    setVehicles: ( veh: VehicleDetails[] ) => void;
    vehicles:    VehicleDetails[];
    disabled?:   boolean;
}

export function VehicleCard( { veh, setVehicles, vehicles, disabled = false }: VehicleCardProps ) {
    const { facilityId, parkerId, vehicleId } = useParamIds();
    const ls = veh.links;
    const reservations  = ls.filter( l => l.type === VehicleLinkType.Reservation  ).map( l => ({ reservationId:  l.reservationId!   }) );
    const subscriptions = ls.filter( l => l.type === VehicleLinkType.Subscription ).map( l => ({ subscriptionId: l.subscriptionId! }) );
    const notUsed = reservations.length === 0 && subscriptions.length === 0;
    return <Card className="mb-2 position-relative">
        <Card.Body className="gap-3"
            style={{
                display:             "grid",
                alignItems:          "center",
                gridTemplateColumns: "min-content 1fr auto",
                gridTemplateRows:    "auto auto"
            }}>
            {/* <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: veh.color }} /> */}
            <CarFrontFill size={24}
                          color={veh.color} />
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {veh.make} {veh.model}
                </div>
                <div>
                    {showStateName( getStateEnumFromCode( veh.stateCode )! )} {veh.licensePlateNumber}
                </div>
            </div>
            {/* {!disabled && <div className="w-100">
                <Button onClick={() => setVehicles( del( vehicles, veh ) )}>Remove</Button>
            </div>} */}
            <ListGroup variant="flush" className="grid-col-span-3 grid-row-2 mb-2">
                {notUsed && <>
                    This vehicle isn't used in any reservations or subscriptions
                </>}
                {reservations.map( r => <ListGroupItem>
                    Used on Reservation <Hyperlink to={`/facility/${facilityId}/parker/${parkerId}/reservation/${r.reservationId}`}>#{r.reservationId}</Hyperlink>
                </ListGroupItem>)}
                {subscriptions.map( s => <ListGroupItem>
                    Used on Plan <Hyperlink to={`/facility/${facilityId}/parker/${parkerId}/plan/${s.subscriptionId}`}>#{s.subscriptionId}</Hyperlink>
                </ListGroupItem>)}
            </ListGroup>
        </Card.Body>
    </Card>;
}
