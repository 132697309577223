//third-party
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { At, KeyFill } from "react-bootstrap-icons";
import { Alert, Card, Form } from "react-bootstrap";

//spotsync
import { Api } from "./Data/Api";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { CenteredPage } from "./Controls/CenteredPage";
import { HeaderText } from "./Controls/HeaderText";
import { useAtom } from "jotai";
import { authAtom, redirectAtom } from "./Data/Atoms";
import { parseStaff } from "./Data/ApiParse";
import { Staff } from "./Data/ApiTypes";

interface ResetPasswordForm {
    emailAddress:    string;
    password:        string;
    confirmPassword: string;
}

export const ResetPasswordPage = () => {
    const nav = useNavigate();
    const [searchParams, _] = useSearchParams();
    const resetCode = searchParams.get( 'code' )!;
    const [ auth,     setAuth     ] = useAtom( authAtom );
    const [ msg,      setMsg      ] = useState<string>();
    const [ redirect, setRedirect ] = useAtom( redirectAtom );
    const staff = useLoaderData() as Staff;

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ResetPasswordForm>( { defaultValues: { emailAddress: staff.emailAddress } });

    function submit( data: ResetPasswordForm ) {
        Api.resetPassword( {
            resetPasswordCode: resetCode!,
            newPassword:       data.password
        } ).then( res => {
            if( res.isOk ) {
                const parker = res.value;
                setAuth( { isLoggedIn: true, account: parker } );
                debugger;
                if( redirect !== undefined ) {
                    setRedirect( undefined );
                    nav( `${redirect}` );
                    return;
                }
                nav( "/" );
                return;
            }
            setMsg( res.error );
        } );
    }

    return <CenteredPage style={{ maxWidth: "30em" }}>
        <Card className="w-100">
            <Form onSubmit={handleSubmit( submit )}>
                <Card.Header>
                    <HeaderText>Reset My Password</HeaderText>
                </Card.Header>
                <Card.Body>
                    <div className="text-secondary mb-3">
                        Reset your password below.
                    </div>
                    <Input
                        type="email"
                        readOnly
                        label="Email Address"
                        icon={<At className="fs-5" />}
                        placeholder="Enter your email address"
                        error={errors.emailAddress}
                        {...register( "emailAddress", { required: "Email Address is required" } )}
                    />
                    <Input type="password"
                        label="New Password"
                        icon={<KeyFill className="fs-5" />}
                        error={errors.password}
                        {...register( "password", { required: "Password is required" } ) }
                    />
                    <Input type="password"
                        label="Confirm Password"
                        icon={<KeyFill className="fs-5" />}
                        error={errors.confirmPassword}
                        {...register( "confirmPassword", {
                            required: "Please confirm your password",
                            validate: value => value === watch( 'password' ) || "Passwords do not match"
                        } )}
                    />
                    {msg && <Alert variant="secondary">{msg}</Alert>}
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex flex-row justify-content-end">
                        <Button type="submit" className="w-50">
                            Reset Password
                        </Button>
                    </div>
                </Card.Footer>
            </Form>
        </Card>
    </CenteredPage>;
};