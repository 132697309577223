import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Select } from "./Controls/Select";
import { Button } from "./Controls/Button";
import { Api } from "./Data/Api";
import { InviteStaffReq } from "./Data/ApiTypes";
import { StaffRole } from "./Data/ApiTransport";
import { HeaderText } from "./Controls/HeaderText";

interface StaffInviteModalProps {
    show:    boolean;
    setShow: ( show: boolean ) => void;
    onSave?: ( data: InviteStaffReq ) => void;
}

export default function StaffInviteModal( { show, setShow, onSave }: StaffInviteModalProps ) {
    const { register, handleSubmit, formState: { errors } } = useForm<InviteStaffReq>( {
        defaultValues: {
            name:         "",
            emailAddress: "",
            phoneNumber:  "",
            password:     "",
            note:         "",
            role:         StaffRole.OperatorAdmin
        }
    } );

    async function submit( data: InviteStaffReq ) {
        const res = await Api.staffInvite( data );
        if( res.isOk ) {
            onSave?.( data );
            setShow( false );
            return;
        }
        // You might want to handle the error differently in a modal context
        alert( res.error );
    }

    return (
        <Modal show={show} centered onHide={() => setShow( false )}>
            <Form onSubmit={handleSubmit( submit )}>
                <Modal.Header closeButton>
                    <HeaderText className="font-semibold">Invite Staff Member</HeaderText>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="text"
                        label="Staff Name"
                        {...register( "name", { required: "Name is required" } )}
                        error={errors.name}
                    />

                    <Input
                        type="email"
                        label="Email"
                        {...register( "emailAddress", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        } )}
                        error={errors.emailAddress}
                    />

                    <Input
                        type="text"
                        label="Phone Number (optional)"
                        {...register( "phoneNumber", {
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        } )}
                        placeholder="555-555-5555"
                        error={errors.phoneNumber} />                    

                    <Select
                        disabled
                        label="Role"
                        {...register( "role", { required: "Role is required" } )}
                        error={errors.role}>
                        <option value={StaffRole.OperatorAdmin}>Operator Admin</option>
                    </Select>

                    <Input
                        type="text"
                        label="Note"
                        {...register( "note" )}
                        error={errors.note}
                    />
                    <div className="d-flex gap-2">
                        <Button className="w-100" onClick={ () => setShow( false ) }>Cancel</Button>
                        <Button className="w-100" type="submit">Invite</Button>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    );
}