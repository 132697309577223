import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { ImportPaymentReq, OrderDebt, PmtApply } from "./Data/ApiTypes";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { HeaderText } from "./Controls/HeaderText";
import { MoneyInput } from "./Controls/MoneyInput";
import { ImportPaymentReqApi } from "./Data/ApiTransport";
import { parseImportPaymentReq } from "./Data/ApiParse";

interface ImportPaymentFormModalProps {
    facilityId: number;
    parkerId:   number;
    show:       boolean;
    setShow:    ( show: boolean ) => void;
    onSave:     ( checkInfo: ImportPaymentReq ) => void;
}

export function ImportPaymentFormModal( { facilityId, parkerId, show, setShow, onSave }: ImportPaymentFormModalProps ) {
    const { register, watch, setValue, formState: { errors }, reset, handleSubmit } = useForm<ImportPaymentReqApi>({
        defaultValues: {
            note:   "",
            amount: 0
         }
    } );

    function submit( data: ImportPaymentReqApi ) {
        onSave( parseImportPaymentReq( data ) );
        reset();
        setShow(false);
    }
    const note = watch( "note" );
    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header className="fs-5">
                <HeaderText>
                    Add External Payment
                </HeaderText>
            </Modal.Header>
            <Modal.Body>
                <Input
                    type="text"
                    label="Note"
                    maxLength={64}
                    placeholder="Ex: Authorize .Net Payment"
                    explanation={`Where was the payment made? ${note.length}/64`}
                    {...register( "note", {
                        required: "Note is required"
                    })}
                    error={errors.note} />

                <MoneyInput
                    label="Amount"
                    inputClassName="text-end"
                    explanation="Check amount in dollars and cents"
                    min="0"
                    {...register("amount", {
                        valueAsNumber: true,
                        required:      "Amount is required",
                        validate: (value: number) => value != 0 && (!isNaN(value)) || "Please enter a valid number"
                    })}
                    error={errors.amount} />
                    
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}