import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { LocalDate } from "@js-joda/core";
import { CheckCreateReq, OrderDebt, PmtApply } from "./Data/ApiTypes";
import { CheckCreateReqApi } from "./Data/ApiTransport";
import { showBrowserDate } from "./Data/Date";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { HeaderText } from "./Controls/HeaderText";
import { MoneyInput } from "./Controls/MoneyInput";
import { parseCheckCreateReq } from "./Data/ApiParse";

interface CheckFormModalProps {
    facilityId: number;
    parkerId:   number;
    show:       boolean;
    setShow:    ( show: boolean ) => void;
    onSave:     ( checkInfo: CheckCreateReq ) => void;
}

export function CheckFormModal( { facilityId, parkerId, show, setShow, onSave }: CheckFormModalProps) {
    const [openOrders, setOpenOrders] = useState<OrderDebt[]>( [] );
  //const [appls,      setAppls     ] = useState<PmtApply[]>(  [] );
    const { register, watch, setValue, formState: { errors }, reset, handleSubmit } = useForm<CheckCreateReqApi>({
        defaultValues: {
            checkDate:   showBrowserDate( LocalDate.now() ),
            checkMemo:   "",
            checkNumber: "",
            amount:       0
         }
    } );
    
    //useEffect( () => {
    //    Api.orderOpenList( facilityId, parkerId ).then(
    //        res => processResult( res,
    //            vals => setOpenOrders( vals ),
    //            err => alert( err )
    //    ) );
    //}, [show, facilityId, parkerId] );

    function submit( data: CheckCreateReqApi ) {
        onSave( parseCheckCreateReq( data ) );
        reset();
        setOpenOrders( [] );
        setShow(false);
    }

    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header className="fs-5">
                <HeaderText>
                    Add Check Payment
                </HeaderText>
            </Modal.Header>
            <Modal.Body>
                <Input
                    type="date"
                    label="Check Date"
                    explanation="Date written on the check"
                    {...register("checkDate", {
                        required: "Check date is required"
                    })}
                    error={errors.checkDate} />

                <MoneyInput
                    label="Amount"
                    explanation="Check amount in dollars and cents"
                    min="0"
                    {...register("amount", {
                        valueAsNumber: true,
                        required: "Amount is required",
                        validate: (value) =>
                            !isNaN(value) || "Please enter a valid number"
                    })}
                    error={errors.amount} />

                <Input
                    type="text"
                    label="Check Number"
                    {...register("checkNumber")}
                    error={errors.checkNumber} />

                <Input
                    type="text"
                    label="Check Memo"
                    {...register("checkMemo")}
                    error={errors.checkMemo} />
                    
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}