import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Facility, AccessKey, AccessKeyLinkInfo } from "./Data/ApiTypes";
import AccessKeyModal, { AccessKeyData } from "./AccessKeyModal";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { CircleFill, Pencil, SquareFill } from "react-bootstrap-icons";
import { showInstantDateTime } from "./Data/Date";
import { AccessKeyLinkType } from "./Data/ApiTransport";
import { MediumPage } from "./Controls/MediumPage";
import React from "react";
import { Hyperlink } from "./Controls/Hyperlink";

export function FacilityAccessKeyDetailsPage() {
    const { facilityId, accessKeyId } = useParamIds();    
    const ldKey    = useLoaderData() as AccessKey;
    const facility = useRouteLoaderData( "facility" ) as Facility;
    const [ showModal, setShowModal ] = useState( false );
    const [ key,       setKey       ] = useState( ldKey );

    async function save( data: AccessKeyData ) {
        const res = await Api.facilityAccessKeyUpdate( facilityId, accessKeyId, {
            accessKeyNumber: data.accessKeyNumber,
            note: data.note
        } );
        const ld = processResult( res, val => {
            setKey( { ...val } );
        }, err => alert( err ) );
    }

    async function assign( data: AccessKeyData ) {
        const res = await Api.facilityAccessKeyUpdate( facilityId, accessKeyId, {
            accessKeyNumber: data.accessKeyNumber,
            note: data.note
        } );
        const ld = processResult( res, val => {
            setKey( { ...val } );
        }, err => alert( err ) );
    }

    function getLink( link: AccessKeyLinkInfo ) {
        if( link.type === AccessKeyLinkType.Subscription ) {
            return `/facility/${facilityId}/parker/${link.subscription?.parker.parkerId}/plan/${link.subscription?.subscriptionId}`;
        }
        if( link.type === AccessKeyLinkType.SubscriptionParker ) {
            return `/facility/${facilityId}/parker/${link.guest?.parker?.parkerId}`;
        }
        if( link.type === AccessKeyLinkType.VehicleSubscription ) {
            alert( "not implemented!" );
            return;
        }
        alert( "Invalid Access Key Link type!" );
    }

    return <MediumPage>
        <AccessKeyModal
            initialData={ldKey}
            onSave={data => save(data)}
            setShow={setShowModal}
            show={showModal}
        />        
        <Row>
            <Col>
                <PageBar title={`Access Key ${ldKey.accessKeyNumber}`}>
                    <ToolButton onClick={() => setShowModal(true)}>
                        <Pencil /> Edit Key
                    </ToolButton>
                </PageBar>
            </Col>
        </Row>
        <Row>
            <Col>
                <Table style={{ tableLayout: "auto" }}>
                    <tbody>
                        <tr>
                            <td>Number</td>
                            <td>{ldKey.accessKeyNumber}</td>
                        </tr>
                        <tr>
                            <td>Note</td>
                            <td>{ldKey.note}</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>{showInstantDateTime( ldKey.createdWhen, facility.timeZone )}</td>
                        </tr>
                        <tr>
                            <td>Created By</td>
                            <td>{ldKey.createdByStaff.name}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="h3">Timeline</div>
                <Table>
                    <thead>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr>                                
                            <td>
                                <SquareFill className="text-primary" />
                            </td>
                            <td>
                                {showInstantDateTime( key.createdWhen, facility.timeZone )}
                            </td>
                            <td>
                                Created Access Key
                            </td>
                        </tr>
                        {key.links.toSorted( (a, b) => a.createdWhen.compareTo( b.createdWhen ) ).map( akl => <React.Fragment>
                            {akl.type === AccessKeyLinkType.Subscription && <tr>
                                <td><CircleFill className="text-primary" /></td>
                                <td>{showInstantDateTime( akl.createdWhen, facility.timeZone )}</td>
                                <td>
                                    Assigned to {akl.subscription?.parker.name} <Hyperlink to={getLink( akl )}>Subscription</Hyperlink>
                                </td>
                            </tr>}
                            {akl.type === AccessKeyLinkType.SubscriptionParker && <tr>
                                <td><CircleFill color="text-primary" /></td>
                                <td>{showInstantDateTime( akl.createdWhen, facility.timeZone )}</td>
                                <td>Assigned to Guest Parker {akl.guest?.parker?.name}</td>
                            </tr>}
                            {akl.type === AccessKeyLinkType.VehicleSubscription && <tr>
                                <td><CircleFill color="text-primary" /></td>
                                <td>{showInstantDateTime( akl.createdWhen, facility.timeZone )}</td>
                                <td>Assigned to Parker Vehicle {akl.vehicle?.parker?.name}</td>
                            </tr>}
                    </React.Fragment>)}
                    </tbody>                        
                </Table>
            </Col>
        </Row>
    </MediumPage>;
}