import { useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { Invoice } from "./Data/ApiTypes";
import { useLoaderData, useNavigate } from "react-router-dom";
import { showMoney } from "./Data/Money";
import { BalanceStatusRenderer, colInstantDate, colText, DataGrid } from "./Controls/DataGrid";
import { showOrderType } from "./Data/Finance";
import { showDate } from "./Data/Date";
import { PageBar } from "./Controls/PageBar";
import { gridOneCol } from "./Data/Common";
import { Col, Container, Row } from "react-bootstrap";

export function FacilityParkerInvoiceListPage() {
    const nav      = useNavigate();
    const invoices = useLoaderData() as Invoice[];
    const columns = useMemo<ColDef<Invoice>[]>( () => [
        { field: "invoiceNumber",          headerName: "Invoice #", type: "rightAligned" },
        { ...colInstantDate( "createdWhen", x => x.facility.timeZone ), headerName: "Created Date" },
        { field: "order.orderType",        headerName: "Type", valueFormatter: x => showOrderType( x.value ) },
        { ...colText( "order.summary" ),   headerName: "Summary" },
        // { ...colMoney( "openingBalance" ), headerName: "Starting Balance" },
        { field: "endingBalance",          headerName: "Amount Due", type: "rightAligned", valueFormatter: p => showMoney( p.value ) },
        { field: "dueWhen",                headerName: "Due Date", valueFormatter: x => showDate( x.value ) },
        { field: "order.balance.balance",  headerName: "Status", cellRenderer: BalanceStatusRenderer }
    ], [] );

    const onRowClicked = ( event: any ) => {
        const invoice: Invoice = event.data;
        const facilityId = invoice.facility.facilityId;
        const parkerId = invoice.order.parkerId;
        const invoiceId = invoice.invoiceId;
        nav( `/facility/${facilityId}/parker/${parkerId}/invoice/${invoiceId}` );
    };
    
    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col style={gridOneCol( "max-content 1fr 5px" )}>
                <PageBar title="Invoices" />
                <div style={{ height: "100%", width: '100%' }}>
                    <DataGrid
                        rowData={invoices}
                        columnDefs={columns}
                        onRowClicked={onRowClicked} />
                </div>
            </Col>
        </Row>
    </Container>;
}
