import { forwardRef, ReactNode, useEffect, useId, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ChangeHandler, FieldError } from "react-hook-form";

export interface PhoneInputProps {
    name:         string;
    className?:   string;
    label:        string;
    icon?:        ReactNode;
    placeholder?: string;
    explanation?: string;
    onChange:     ChangeHandler;
    onBlur:       ChangeHandler;
    error?:       FieldError | undefined;
    value?:       string;
}

function fakeChangeEvent( elem: HTMLInputElement ): React.ChangeEvent<HTMLInputElement> {
    const event = {
        target: elem,
        preventDefault:  () => {},
        stopPropagation: () => {},
    } as React.ChangeEvent<HTMLInputElement>;
    return event;
}

function fmt( value: string ): string {
    const numbers = value.replace( /\D/g, "" ).slice( 0, 10 );
    if( numbers.length === 0 ) return "";
    if( numbers.length <=  3 ) return numbers;
    if( numbers.length <=  6 ) return `${numbers.slice( 0, 3 )}-${numbers.slice( 3 )}`;
    return `${numbers.slice( 0, 3 )}-${numbers.slice( 3, 6 )}-${numbers.slice( 6 )}`;
}

const unfmt = ( s: string ) => s.replace( /\D/g, "" );

const getUnsel = ( s: string, a: number, b: number ) => {
    if( a == b ) { return unfmt( s ); }
    const pre  = s.slice( 0, a );
    const tail = s.slice( b );
    const res = unfmt( pre + tail );
    return res;
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
    ( {
        className = "mb-3",
        label,
        icon,
        error,
        placeholder = "555-555-5555",
        explanation = "",
        name,
        onChange,
        onBlur,
        value = "",
    }, ref ) => {
        const validNums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const inputRef = useRef<HTMLInputElement>();
        const [val, setVal] = useState( unfmt( value ) );
        const displayValue = fmt( val );

        // accessibility stuff
        const inputId = useId();        
        const helpId  = `${inputId}-help`;

        useEffect( () => {
            if( !inputRef.current ) return;
            setVal( unfmt( value ) );
        }, [value] );

        useEffect( () => {
            if( !inputRef.current              ) { return; }
            if( inputRef.current.value === val ) { return; }
            const formattedVal = fmt( val );
            inputRef.current.value = formattedVal;
            onChange( fakeChangeEvent( inputRef.current! ) );
        }, [val] );

        function handleKeyDown( e: React.KeyboardEvent<HTMLInputElement> ): void {
            const input = e.currentTarget;
            const disp  = displayValue;
            const a     = input.selectionStart ?? 0;
            const b     = input.selectionEnd   ?? 0;
            let   aa    = a === b && e.key === "Backspace" ? a - 1 : a;
            let   bb    = a === b && e.key === "Delete"    ? b + 1 : b;

            if( e.key === "Backspace" || e.key === "Delete" ) {
                e.preventDefault();
                setVal( getUnsel( disp, aa, bb ) );
                return;
            }

            //there's no point looking at a and b here, because we know the key was a number
            if( validNums.includes( e.key ) ) {
                e.preventDefault();
                const nums   = getUnsel( disp, a, b );
                const newVal = nums.slice( 0, a ) + e.key + nums.slice( b );
                if( newVal.length <= 10 ) {
                    setVal( newVal );
                }
                return;
            }
        }

        function setRef( el: HTMLInputElement ) {
            if( typeof ref === "function" ) { ref( el ); }
            else if( ref ) { ref.current = el; }
            inputRef.current = el;
        }

        function handlePaste( e: React.ClipboardEvent<HTMLInputElement>) {
            e.preventDefault();
            const input = e.currentTarget;
            const a     = input.selectionStart ?? 0;
            const b     = input.selectionEnd   ?? 0;
            const clip  = e.clipboardData.getData( "text" );
            const nums  = ( getUnsel( displayValue, a, b ) + unfmt( clip ) );
            setVal( nums.slice( 0, 10 ) );
        }

        type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
        function autofillHandler( e: React.ChangeEvent<FormControlElement> ): void {
            setVal( unfmt( e.target.value ) );
        }

        return <Form.Group className={className}>
            <Form.Label htmlFor={inputId} className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>{icon}</InputGroup.Text>}
                <Form.Control
                    id={inputId}
                    type="tel"
                    onPaste={handlePaste}
                    aria-describedby={helpId}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    onChange={autofillHandler}
                    value={displayValue} />
            </InputGroup>
            <input
                style={{ display: "none" }}
                type="text"
                name={name}
                ref={setRef} />
            {explanation && (
                <Form.Text id={helpId} className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>
            )}
            {error && (
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>
            )}
        </Form.Group>;
    }
);