import { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { useParamIds } from "./Data/Common";
import { colInstantDate, DataGrid } from "./Controls/DataGrid";
import { Key } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Facility, AccessKey, AccessKeyLinkInfo } from "./Data/ApiTypes";
import AccessKeyModal, { AccessKeyData } from "./AccessKeyModal";
import { Api, identity } from "./Data/Api";
import { loadResult } from "./Data/Result";
import { ZoneId } from "@js-joda/core";

export function FacilityAccessKeyListPage() {
    const nav            = useNavigate();
    const { facilityId } = useParamIds();
    const [ showModal, setShowModal] = useState( false );
    const [ modalData, setModalData] = useState<AccessKeyData>();
    const keys     = useLoaderData() as AccessKey[];
    const facility = useRouteLoaderData("facility") as Facility;
 
    const columns = useMemo<ColDef<AccessKey>[]>( () => [
     // { field: "accessKeyId", headerName: "ID", type: "rightAligned" }, 
     // { field: "type", headerName: "Type", valueFormatter: x => showAccessKeyType( x.value ) },
        { field: "accessKeyNumber", headerName: "Key", type: "rightAligned" },        
        { valueGetter: x => x?.data?.links.length, headerName: "Assigned To" },
        { field: "createdByStaff.name", headerName: "Created By" },
        { ...colInstantDate( "createdWhen", x => x.facility.timeZone ) },        
        { field: "note", headerName: "Note" }
    ], [] ); 

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    }; 

    const onRowClicked = ( event: any ) => {
        const key: AccessKey = event.data;
        setModalData( { ...key } );
        setShowModal( true );
    };

    async function save( data: AccessKeyData ) {
        if( !data.accessKeyId ) {    
            const res = await Api.facilityAccessKeyCreate( facilityId, {
                accessKeyNumber: data.accessKeyNumber,            
                note:            data.note
            } );
            const ld = loadResult( res, identity, identity );
            return;
        }        
        const accessKeyId = data.accessKeyId;
        const res = await Api.facilityAccessKeyUpdate( facilityId, accessKeyId, {
            accessKeyNumber: data.accessKeyNumber,
            note:            data.note
        } );
        const ld = loadResult( res, identity, identity );
    }

    return <Container fluid className="h-100">
        <AccessKeyModal
            initialData={modalData}
            onSave={ data => save( data ) }
            setShow={setShowModal}
            show={showModal} />
        <Row className="h-100">
            <Col style={{ display:             "grid",
                          gridTemplateColumns: "1fr",
                          gridTemplateRows:    "max-content 1fr 5px" }}>
                <div className="d-flex align-items-center gap-2">
                    <PageBar title="Access Keys">
                        <ToolButton onClick={ () => {
                            setModalData( undefined );
                            setShowModal( true );
                        } }>
                            <Key color="maroon" /> Create Key
                        </ToolButton>
                    </PageBar>
                </div>
                <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid   
                        onRowClicked={onRowClicked}
                        rowData={keys}
                        columnDefs={columns}
                        onGridReady={onGridReady}                        
                    />
                </div>
            </Col>
        </Row>
    </Container>;    
}