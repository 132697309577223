
export const pluralize   = ( n: number, singular: string, plural: string ) => n == 1 ? singular : plural;
export const yieldSpace  = ( a: string, b: string ) => a.length > 0 && b.length > 0 ? " " : ""; 
export const concatWords = ( ...words: string[] ) => words.reduce( (a, b) => `${a}${yieldSpace( a, b )}${b}` , "" )

export function getOrdinal( n: number ) {
    let suffix = "th";
    if( n % 100 < 11 || n % 100 > 13 ) {
        switch( n % 10 ) {
            case 1: suffix = "st"; break;
            case 2: suffix = "nd"; break;
            case 3: suffix = "rd"; break;
        }
    }
    return n + suffix;
}