import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { useEffect } from "react";
import { HeaderText } from "./Controls/HeaderText";

interface AccessKeyModalProps {
    initialData?: AccessKeyData;
    show:         boolean;
    setShow:      ( show: boolean ) => void;
    onSave:       ( data: AccessKeyData ) => void;
}

export interface AccessKeyData {
    accessKeyId?:    number;
    accessKeyNumber: string;
    note:            string;
}

const defaults = {
    accessKeyId:     undefined,
    accessKeyNumber: "",
    note:            ""
};

export default function AccessKeyModal( { initialData, show, setShow, onSave }: AccessKeyModalProps ) {
    const { reset, register, formState: { errors }, watch, handleSubmit } = useForm<AccessKeyData>( {
        defaultValues: initialData ?? defaults
    } );

    useEffect( () => {
        reset( initialData, { keepDefaultValues: true } );
    }, [initialData] );

    function submit( data: AccessKeyData ) {
        onSave(  data  );
        setShow( false );
        reset( initialData ?? defaults );
    }

    const accessKeyId = watch( "accessKeyId" );
    return (
        <Modal show={show} centered onEscapeKeyDown={ () => setShow( false ) } onHide={ () => setShow( false ) }>
            <Form onSubmit={ handleSubmit( submit ) }>
                <Modal.Header closeButton>
                    <HeaderText>{accessKeyId === undefined ? <>Add</> : <>Edit</>} Access Key</HeaderText>
                </Modal.Header>                
                <Modal.Body>
                    <Input type="text"
                        label="Name"
                        {...register( "accessKeyNumber", { required: "Key number is required" } )}
                        placeholder="Name or Number"
                        explanation="Record the key's name or number here"
                        error={errors.accessKeyNumber} />

                    {/* <Select
                        label="Key Type"
                        {...register( "type", { required: "Key type is required" } )}
                        error={errors.type}>
                        <option value={AccessKeyType.LockKey}>Lock Key</option>
                        <option value={AccessKeyType.MagneticCard}>Magnetic Card</option>
                        <option value={AccessKeyType.ProximityCard}>Proximity Card</option>
                    </Select> */}

                    <Input as="textarea"
                        type="text"
                        label="Notes"
                        {...register( "note" )}
                        placeholder=""
                        rows={3}
                        error={errors.note} />

                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow( false )}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}