import { Col, Container, Row } from "react-bootstrap";
import { ArrowLeftCircle, ArrowUpCircle, BarChart, CurrencyDollar, IconProps, PersonCircle, PiggyBank, Receipt, Stripe } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { MenuHeader } from "./AppFacility";
import { ExtendedFacility } from ".";
import { MenuItem } from "./Controls/MenuItem";

export function AppFacilityFinance() {
    //for handling mobile and desktop versions of these pages
    const loc = useLocation();
    const { facilityId } = useParamIds();
    const facility = useLoaderData() as ExtendedFacility;    
    const defaultPath = `/facility/${facilityId}/finance`;
    const [menuClassName, contentClassName] = loc.pathname === ( defaultPath ) ? ["", "d-none"] : ["d-none", ""];
    const active = loc.pathname === ( defaultPath ) ? true : false;

    //just to keep things neat below
    const PiggyBankIcon = ( props: IconProps ) => <PiggyBank className="fs-3" style={{ transform: "scaleX(-1)" }} />
    return <Container fluid className="fullheight pt-2">
        <Row className="fullheight">
            <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight border-end border-1 d-print-none`}>
                <MenuHeader text="Finance" />
                <MenuItem icon={<ArrowLeftCircle />} default to={`/facility/${facilityId}`}      text="Back to Facility" />
                <MenuItem icon={<PersonCircle    />} default active={active} to="./overview"     text="Overview"         />
                <MenuItem icon={<Receipt         />}                         to="./invoice"      text="Invoices"         />
                <MenuItem icon={<CurrencyDollar  />}                         to="./payment"      text="Payments"         />
                <MenuItem icon={<BarChart        />}                         to="./aging"        text="Aging"            />
                <MenuItem icon={<PiggyBankIcon   />}                         to="./payout"       text="Payouts"          />
                <MenuItem icon={<Stripe          />}                         to="./proc/payout"  text="Payouts (Stripe)" />
            </Col>
            <Col md={7} lg={7} xl={8} xxl={9} className={`${contentClassName} d-md-block fullheight scroll-y-auto`}>
                <div className="d-md-none w-100 p-3 d-flex justify-content-center d-print-none">
                    <NavLink to="" className="d-flex align-items-center gap-1">
                        <ArrowUpCircle />Return to Finance Menu
                    </NavLink>
                </div>
                <Outlet />
            </Col>
        </Row>
    </Container>;
}