import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { gridOneCol, useParamIds } from "./Data/Common";
import { colInstantDateTime, colMoney, DataGrid } from "./Controls/DataGrid";
import { PageBar } from "./Controls/PageBar";
import { Facility, FacilityPayment } from "./Data/ApiTypes";
import { showInstantDateTime } from "./Data/Date";
import { showPaymentType } from "./Data/Finance";
import { ZoneId } from "@js-joda/core";
import { PaymentType } from "./Data/ApiTransport";

export function FacilityFinancePaymentListPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const invoices       = useLoaderData() as FacilityPayment[];
    const facility       = useRouteLoaderData( "facilityReport" ) as Facility;
    const columns = useMemo<ColDef<FacilityPayment>[]>( () => [
        { field: "paymentId",         headerName: "Payment #",    type: "rightAligned" },
        { ...colInstantDateTime( "createdWhen", x => facility.timeZone ), headerName: "Created" },
        { field: "parker.name",       headerName: "Parker" },
        { field: "type",              headerName: "Payment Type", valueFormatter: x => {
            return showPaymentType( x.data!.type );
        } },
        { ...colMoney( "amount" ),    headerName: "Amount"    },
        { ...colMoney( "remaining" ), headerName: "Remaining" },
        { field: "paymentIntentKey",  headerName: "Method"    },
     // { field: "paymentMethod", headerName: "Method" },
     // { field: "parentPaymentId", headerName: "Parent Payment", type: "rightAligned", hide: true }
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        const payment: FacilityPayment = event.data;
        const parkerId = payment.parker.parkerId;
        const paymentId = payment.paymentId;        
        nav( `/facility/${facilityId}/parker/${parkerId}/payment/${paymentId}` );
    };

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={gridOneCol( "max-content 1fr 5px" )}>
                <PageBar title="Payments" />
                <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid
                        onRowClicked={onRowClicked}
                        rowData={invoices}
                        columnDefs={columns}
                        onGridReady={onGridReady}                        
                    />
                </div>
            </Col>
        </Row>
    </Container>
}