import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { HeaderText } from "./Controls/HeaderText";
import { useEffect } from "react";
import { StaffUpdateReq } from "./Data/ApiTypes";

interface StaffEditModalProps {
    show:         boolean;
    setShow:      ( show: boolean         ) => void;
    onSave:       ( data: StaffEditData ) => void;
    initialData?: StaffEditData;
}

export interface StaffEditData extends StaffUpdateReq {
    staffId: number;
}

export default function StaffEditModal( { show, setShow, onSave, initialData }: StaffEditModalProps ) {

    const { register, reset, formState: { errors }, handleSubmit } = useForm<StaffEditData>( {
        defaultValues: initialData
    } );

    useEffect( () => {
        reset( initialData );
    }, [initialData] );

    function submit( data: StaffEditData ) {
        onSave( data );
        setShow( false );
    }

    return (
        <Modal show={show} centered onHide={() => setShow( false )}>
            <Form onSubmit={handleSubmit( submit )}>
                <Modal.Header closeButton>
                    <HeaderText>
                        Edit Staff Member
                    </HeaderText>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="text"
                        label="Name"
                        {...register( "name", { required: "Name is required" } )}
                        placeholder="Staff Member Name"
                        error={errors.name}
                    />

                    <Input
                        type="email"
                        label="Email Address"
                        {...register( "emailAddress", { required: "Email is required" } )}
                        placeholder="staff@example.com"
                        error={errors.emailAddress}
                    />

                    <Input
                        type="text"
                        label="Phone Number"
                        {...register( "phoneNumber", {
                            // required: "Phone number is required",
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        } )}
                        placeholder="555-555-5555"
                        error={errors.phoneNumber}
                    />

                    <Input
                        as="textarea"
                        type="text"
                        label="Notes"
                        {...register( "note" )}
                        placeholder="Staff member notes"
                        rows={3}
                        error={errors.note}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow( false )}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}