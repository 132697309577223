import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { useParamIds } from "./Data/Common";
import { colInstantDate, colMoney, DataGrid } from "./Controls/DataGrid";
import { PageBar } from "./Controls/PageBar";
import { Facility, FacilityPayout } from "./Data/ApiTypes";

export function FacilityFinancePayoutListPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const payouts = useLoaderData() as FacilityPayout[];
    const facility = useRouteLoaderData("facilityReport") as Facility;

    const columns = useMemo<ColDef<FacilityPayout>[]>(() => [
        { field: "payoutId",       headerName: "Payout #",    type: "rightAligned" },
        { field: "payoutKey",      headerName: "Processor ID"                     , hide: true },
        { ...colMoney( "amount" ), headerName: "Amount"                            },
        {
            ...colInstantDate( "processorCreatedWhen", x => facility.timeZone ),
            headerName:     "Created Date", 
        },
        {
            ...colInstantDate( "arrivalWhen", x => facility.timeZone ),
            headerName:    "Arrival Date", 
            headerTooltip: "The Date the money is expected to arrive in your bank account",
        },
        { 
            field:       "transactions", 
            headerName:  "Transactions",
            type:        "rightAligned",
            valueGetter: params => params.data?.transactions?.length
        }
    ], [facility.timeZone]);

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = (event: { data: FacilityPayout }) => {
        nav(`/facility/${facilityId}/finance/payout/${event.data.payoutId}`);
    };

    return <Container fluid className="h-100">
            <Row className="h-100">
                <Col className="h-100" style={{ 
                    display:             "grid",
                    gridTemplateColumns: "1fr",
                    gridTemplateRows:    "max-content 1fr 5px" }}>
                    <PageBar title="Payouts" />
                    <div style={{ height: "100%", width: "100%" }}>
                        <DataGrid                            
                            onRowClicked={onRowClicked}
                            rowData={payouts}
                            columnDefs={columns}
                            onGridReady={onGridReady} />
                    </div>
                </Col>
            </Row>
        </Container>;
}