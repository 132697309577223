//css
import './index.scss'
import './index.css';

//react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";

//pages and components
import { AppLoggedIn                       } from './AppLoggedIn';
import { FacilityListPage         } from "./FacilityListPage";
import { FacilityEditPage         } from "./FacilityEditPage";
import { AppFacility              } from "./AppFacility";
import { FacilityPlanListPage     } from "./FacilityPlanListPage";
import { FacilityPlanEditPage     } from "./FacilityPlanEditPage";
import { FacilityRateListPage     } from "./FacilityRateListPage";
import { FacilityParkerListPage   } from "./FacilityParkerListPage";
import { FacilityFinanceInvoiceListPage  } from "./FacilityFinanceInvoiceListPage";
import { FacilitySchedulePage     } from "./FacilitySchedulePage";
import { FacilityRateDurationPage } from "./FacilityRateDurationPage";
import { LogInPage                } from "./LogInPage";
import { ForgotPasswordPage                } from "./ForgotPasswordPage";
import { AppUser                           } from "./AppUser";
import { UserProfilePage                   } from "./UserProfilePage";
import { UserAdvancedPage                  } from "./UserAdvancedPage";
import { FacilityParkerDetailsPage         } from "./FacilityParkerDetailsPage";
import { FacilityClosuresPage              } from "./FacilityClosuresPage";
import { FacilityInvoiceCreatePage           } from "./FacilityCustomOrderCreatePage";
import { AppFacilityParker                 } from "./AppFacilityParker";
import { FacilityParkerInvitePage          } from "./FacilityParkerInvitePage";
import { FacilityParkerApplicationViewPage } from "./FacilityParkerViewApplication";
import { FacilityParkerOrderListPage    } from "./FacilityParkerOrderListPage";
import { FacilityParkerVehicleListPage         } from "./FacilityParkerVehicleListPage";
import { FacilityExample } from "./FacilityExample";
import { AccessKey, BalanceChange, Facility, FacilityInvoice, FacilityPayment, FacilityPayout, FacilityStats, FacilitySub, Invoice, Order, OrderDetails, ParkerAgingRecord, ParkerDetails, ParkerListEntry, Payment, Plan, PlanDetails, RateProgram, Session, Staff, StripeLinkResp, SubEntryForEdit } from "./Data/ApiTypes";
import { loadResult } from "./Data/Result";
import { Api, identity, unknown } from "./Data/Api";
import { parseOperator, parseStripeLinkResp} from "./Data/ApiParse";
import { App } from "./App";
import { CustomFontSource, loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { getDefaultStore } from "jotai";
import { authAtom, redirectAtom } from "./Data/Atoms";
import { ConnectAccountManagePage } from "./AccountManagePage";
import { AppOperator } from "./AppOperator";
import { OperatorDashboard } from "./OperatorDashboard";
import { FacilityFinanceStripePayoutListPage } from "./FacilityFinanceStripePayoutListPage";
import { FacilityListingPage } from "./FacilityListingPage";
import { FacilityOnboardingPage } from "./FacilityOnboardingPage";
import { Sandbox } from "./Sandbox";
import { FacilityParkerPaymentListPage } from "./FacilityParkerPaymentListPage";
import { FacilityPlanCreatePage } from "./FacilityPlanCreatePage";
import { FacilityReportAgingPage } from "./FacilityReportAgingPage";
import { paramIds } from "./Data/Common";
import { FacilityPlanDetailsPage } from "./FacilityPlanDetailsPage";
import { FacilityParkerVehicleDetailsPage } from "./FacilityParkerVehicleDetailsPage";
import { FacilityParkerSubscriptionEditPage } from "./FacilityParkerSubscriptionEditPage";
import { FacilityParkerOrderDetailsPage } from "./FacilityParkerOrderDetailsPage";
import { FacilityParkerPaymentDetailsPage } from "./FacilityParkerPaymentDetailsPage";
import { FacilityParkerInvoiceListPage } from "./FacilityParkerInvoiceListPage";
import { FacilityParkerInvoiceDetailsPage } from "./FacilityParkerInvoiceDetailsPage";
import { Vehicle } from "./Data/FakeData";
import { FacilityPlanSignPage } from "./FacilityPlanSignPage";
import { FacilityRateSignPage } from "./FacilityRateSignPage";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { FacilityAccessKeyListPage } from "./FacilityAccessKeyListPage";
import { FacilityAccessKeyDetailsPage } from "./FacilityAccessKeyDetailsPage";
import { FacilityOverviewPage } from "./FacilityOverviewPage";
import { AppFacilityFinance } from "./AppFacilityFinance";
import { FacilityFinancePaymentListPage } from "./FacilityFinancePaymentListPage";
import { FacilityParkerBalanceHistoryPage } from "./FacilityParkerBalanceHistoryPage";
import { FacilityFinanceStripePaymentListPage } from "./FacilityFinanceStripePaymentListPage";
import { AppFacilityFrame } from "./AppFacilityFrame";
import { FacilitySessionListPage } from "./FacilitySessionListPage";
import { FacilityFinanceInvoiceBatchPage } from "./FacilityFinanceInvoiceBatchPage";
import { FacilityParkerSubscriptionManagePage } from "./FacilityParkerSubscriptionManagePage";
import { OperatorStaffListPage } from "./OperatorStaffListPage";
import { OperatorStaffDetailsPage } from "./OperatorStaffDetailsPage";
import { FacilityFinancePayoutListPage } from "./FacilityFinancePayoutListPage";
import { FacilityFinancePayoutDetailsPage } from "./FacilityFinancePayoutDetailsPage";

const ldReset: LoaderFunction = async( { request, params } ) => {
    const url   = new URL( request.url );
    const store = getDefaultStore();
    const code  = url.searchParams.get( "code" );

    //allow it to pass through
    if( code === null ) {
        console.debug( "there was no reset code" );
        return null;
    }
    if( url.pathname === "/reset" ) {
        console.debug( "we were not on the reset page" );
        return null;
    }
    console.debug( `storing redirect to ${url.pathname}` );
    url.searchParams.delete( "code" );
    store.set( redirectAtom, `${url.pathname}?${url.searchParams}` );
    return redirect( `/reset?code=${code}` );
}

const ldAuth: LoaderFunction = async( args ) => {
    const ld = await ldReset( args );
    if( ld === null ) {
        return await Api.amILoggedIn()
            .then( res => loadResult( res, identity, err => redirect( "/login" ) ) );
    }
    return ld;
}

const ldFacilities: LoaderFunction<Facility[]> = async( { params } ) => {
    const op = await Api.opDetails().then(
        res => loadResult( res, parseOperator, unknown ) );

    const fs = await Api.facilityList()
        .then( res => loadResult( res, identity, unknown ) );

    return [fs, op];
}

export interface ExtendedFacility extends Facility {
    connectInstance: StripeConnectInstance;
    account:         StripeLinkResp;
}

const ldFacility: LoaderFunction<ExtendedFacility> = async( { params } ) => {
    const { facilityId } = paramIds( params )

    const facility = await Api.facilityDetails( facilityId )
        .then( res => loadResult( res, identity, unknown ) );

    const link = await Api.stripeLink( facilityId )
        .then( res => loadResult( res, parseStripeLinkResp, unknown ) );

    const auth = defaultStore.get( authAtom );
    if( !auth.isLoggedIn ) {
        return redirect( "/login" );
    }

    const fetchClientSecret = async () => {
        return loadResult( await Api.stripeInit( facilityId ),
            val => val.clientSecret,
            err => "Err!" );
    };

    const connectInstance = loadConnectAndInitialize( {
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        fetchClientSecret: fetchClientSecret,        
        appearance: {
            variables: {
                fontFamily:                     "silka",
                bodyMdFontWeight:               "400",
                bodySmFontWeight:               "400",
                labelMdFontWeight:              "300",
                labelSmFontWeight:              "300",
                fontSizeBase:                   "16px",
                spacingUnit:                    "9px",
                buttonBorderRadius:             "16px",
                colorPrimary:                   "#D61F84", //optional appearance param,
                buttonPrimaryColorText:         "#000000",
                buttonPrimaryColorBackground:   "#FFFFFF",
                buttonPrimaryColorBorder:       "#D61F84",
                buttonSecondaryColorBackground: "#FFFFFF",
                buttonSecondaryColorBorder:     "#D61F84",
                buttonSecondaryColorText:       "#000000",
            },
        },
        fonts: [silkaRegular, silkaBold],
    } );
    if( !facility || !link ) {
        return undefined;
    }
    const ret: ExtendedFacility = {
        ...facility,
        connectInstance: connectInstance,
        account:            link
     };
    return ret;
}

const ldAgingReport: LoaderFunction<ParkerAgingRecord[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityAgingReport( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPlans: LoaderFunction<Plan[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityPlanList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldRate: LoaderFunction<RateProgram> = async( { params } ) => {
    const { facilityId, rateId } = paramIds( params );
    return await Api.facilityRateGet( facilityId, rateId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldFacilityParker: LoaderFunction<ParkerDetails> = async( { params }) => {
    const { facilityId, parkerId } = paramIds( params );
    return await Api.facilityParkerDetails( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldFacilityInvoices: LoaderFunction<FacilityInvoice[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityInvoiceList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldFacilityPayouts: LoaderFunction<FacilityPayout[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityPayoutList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldFacilityPayout: LoaderFunction<FacilityPayout[]> = async( { params } ) => {
    const { facilityId, payoutId } = paramIds( params )
    return await Api.facilityPayoutDetails( facilityId, payoutId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldFacilityPayments: LoaderFunction<FacilityPayment[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityPaymentList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldBalanceHistory: LoaderFunction<[BalanceChange[], Facility]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    const facility = await Api.facilityDetails( facilityId )
        .then( res => loadResult( res, identity, unknown ) );

    const balanceHist = await Api.balanceHistory( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );

    return [balanceHist, facility];
}

const silkaRegular: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-regular-webfont.woff) format('woff')",
    weight: "normal"
}

const silkaBold: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-bold-webfont.woff) format('woff')",
    weight: "bold"
}

const defaultStore = getDefaultStore();
const ldOperator: LoaderFunction<Facility> = async( { params } ) => {
    return await Api.opDetails().then(
        res => loadResult( res, parseOperator, unknown ) );
}

const ldFacilityParkers: LoaderFunction<[ParkerDetails[], FacilitySub[]]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    const parkers = await Api.facilityParkerList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
    const subs = await Api.facilitySubscriptionList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
    return [parkers, subs];
}
const ldFacilitySessions: LoaderFunction<[Session[]]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    const parkers = await Api.facilitySessionList( facilityId )
        .then( res => loadResult( res, identity, unknown ) ); 
    return parkers;
}

const ldOrders: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.orderList( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldOrderDetails: LoaderFunction<OrderDetails> = async( { params } ) => {
    const { facilityId, parkerId, orderId } = paramIds( params )
    return await Api.orderDetails( facilityId, parkerId, orderId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldInvoices: LoaderFunction<Invoice[]> = async ( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.invoiceList( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldInvoiceDetails: LoaderFunction<Invoice> = async ( { params } ) => {
    const { facilityId, parkerId, invoiceId } = paramIds( params )
    return await Api.invoiceDetails( facilityId, parkerId, invoiceId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPayments: LoaderFunction<Payment[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.paymentList( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPaymentDetails: LoaderFunction<Payment> = async( { params } ) => {
    const { facilityId, parkerId, paymentId } = paramIds( params )
    return await Api.paymentDetails( facilityId, parkerId, paymentId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPlanDetails: LoaderFunction<PlanDetails> = async( { params } ) => {
    const { facilityId, planId } = paramIds( params )
    return await Api.facilityPlanGet( facilityId, planId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldSubDetails: LoaderFunction<SubEntryForEdit> = async( { params } ) => {
    const { facilityId, subscriptionId } = paramIds( params )
    return await Api.facilitySubscriptionEditGet( facilityId, subscriptionId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldSubDetailsManage: LoaderFunction<[SubEntryForEdit, Vehicle[]]> = async( { params } ) => {
    const { facilityId, parkerId, subscriptionId } = paramIds( params )
    const subDetails = await Api.facilitySubscriptionGet( facilityId, subscriptionId )
        .then( res => loadResult( res, identity, unknown ) );    

    const vehicles = await Api.facilityParkerVehicleList( facilityId, parkerId )
        .then( res => loadResult( res, val => val, unknown ) );

    return [subDetails, vehicles];
}

const ldVehicles: LoaderFunction<Vehicle[]> = async ( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.facilityParkerVehicleList( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
};

const ldAccessKeys: LoaderFunction<AccessKey[]> = async ( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityAccessKeyList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
};

const ldAccessKey: LoaderFunction<AccessKey> = async ( { params } ) => {
    const { facilityId, accessKeyId } = paramIds( params )
    return await Api.facilityAccessKeyGet( facilityId, accessKeyId )
        .then( res => loadResult( res, identity, unknown ) );
};

const ldFacilityStats: LoaderFunction<FacilityStats> = async ( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityStatsGet( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
};

const ldResetCode: LoaderFunction = async( { request, params } ) => {
    const url   = new URL( request.url );
    const store = getDefaultStore();
    const code  = url.searchParams.get( "code" );
    return await Api.resetCodeCheck( { resetPasswordCode: code! } )
        .then( res => loadResult( res, identity, err => {
            if( err === "Invalid or expired reset code" ) {
                var desiredUrl = store.get( redirectAtom );
                var auth = store.get( authAtom );
                console.log( "reset code was invalid" );
                if( auth.isLoggedIn && desiredUrl !== undefined ) {
                    console.log( `redirecting to ${desiredUrl}` );
                    return redirect( desiredUrl );
                }
                if( auth.isLoggedIn ) {
                    console.log( `redirecting to /` );
                    return redirect( "/" );
                }
                console.log( `redirecting to /login` );
                return redirect( `/login` );
            }
            return null;
        }
    ) );
}

const ldStaffList: LoaderFunction<Staff[]> = async ( { params } ) => {    
    return await Api.staffList()
        .then( res => loadResult( res, identity, unknown ) );
};

const ldStaffDetails: LoaderFunction<Staff[]> = async ( { params } ) => {    
    const { staffId } = paramIds( params );
    return await Api.staffGet( staffId )
        .then( res => loadResult( res, identity, unknown ) );
};

//based on the type of the account we'll default to facilities list with other things
const router = createBrowserRouter( [
    { element: <App />, loader: ldReset, children: [
        { path: "/sandbox", element: <Sandbox           />, },
        { path: "/login",  element: <LogInPage          />, },
        { path: "/forgot", element: <ForgotPasswordPage />, },
        { path: "/reset",  element: <ResetPasswordPage  />, loader: ldResetCode },        
    ] },
    { element: <App />, children: [
        { element: <AppLoggedIn />, loader: ldAuth, children: [
            { path: "/operator",                          element: <AppOperator />, loader: ldOperator, children: [
                { path: "/operator",                      element: <OperatorStaffListPage    />, loader: ldStaffList },
                { path: "/operator/staff",                element: <OperatorStaffListPage    />, loader: ldStaffList },
                { path: "/operator/staff/:staffId",       element: <OperatorStaffDetailsPage />, loader: ldStaffDetails },
                { path: "/operator/profile",              element: <OperatorDashboard        />, },
                { path: "/operator/account",              element: <ConnectAccountManagePage />, },
                { path: "/operator/facility/create",      element: <FacilityEditPage         />, },
            ] },
            { path: "/",                                  element: <FacilityListPage         />, loader: ldFacilities },
            //user stuff
            { path: "/user",                              element: <AppUser />, children: [
                { path: "/user",                          element: <UserProfilePage          />, },
                { path: "/user/profile",                  element: <UserProfilePage          />, },
                { path: "/user/advanced",                 element: <UserAdvancedPage         />, },
            ] },
            { path: "/facility",                          element: <FacilityListPage         />, },
            { path: "/facility/example",                  element: <FacilityExample          />, },
            { path: "/facility/:facilityId",              element: <AppFacilityFrame />, loader: ldFacility, id: "facility", children: [
                { path: "",                                 element: <AppFacility              />, children: [
                    { path: "",                               element: <FacilityOverviewPage     />, loader: ldFacilityStats },
                    { path: "overview",                       element: <FacilityOverviewPage     />, loader: ldFacilityStats },
                    { path: "listing",                        element: <FacilityListingPage      />, },
                    { path: "schedule",                       element: <FacilitySchedulePage     />, },
                    { path: "closure",                        element: <FacilityClosuresPage     />, },
    
                    { path: "status",                         element: <FacilitySessionListPage />, loader: ldFacilitySessions },
                    { path: "parker",                         element: <FacilityParkerListPage />, loader:  ldFacilityParkers, children: [
                        { path: "active",                     element: <FacilityParkerListPage />, },
                        { path: "waiting",                    element: <FacilityParkerListPage />, },
                        { path: "applied",                    element: <FacilityParkerListPage />, },
                    ] },
                    { path: "parker/invite",                  element: <FacilityParkerInvitePage           />, loader: ldPlans },
                    { path: "parker/invite/:parkerId",        element: <FacilityParkerApplicationViewPage  />, },
    
                    { path: "plan",                           element: <FacilityPlanListPage               />, loader: ldPlans       },
                    { path: "plan/create",                    element: <FacilityPlanCreatePage             />, loader: ldFacility    },
                    { path: "plan/:planId",                   element: <FacilityPlanDetailsPage            />, loader: ldPlanDetails },
                    { path: "plan/:planId/edit",              element: <FacilityPlanEditPage               />, loader: ldPlanDetails },
                    { path: "plan/:planId/sign",              element: <FacilityPlanSignPage               />, loader: ldPlanDetails },
    
                    { path: "rate",                           element: <FacilityRateListPage               />, loader: ldFacility    },
                    { path: "rate/sign",                      element: <FacilityRateSignPage               />, loader: ldFacility    },
                    { path: "rate/:rateId",                   element: <FacilityRateDurationPage           />, loader: ldRate        },
    
                    { path: "rate/create",                    element: <FacilityRateDurationPage           />, },                    
    
                    //access keys
                    { path: "key",                           element: <FacilityAccessKeyListPage           />, loader: ldAccessKeys, shouldRevalidate: x => true },
                    { path: "key/:accessKeyId",              element: <FacilityAccessKeyDetailsPage        />, loader: ldAccessKey,  shouldRevalidate: x => true  },
                    // { path: "key/:accessKeyId",           element: <FacilityAccessKeyCreatePage         /> },
                    // { path: "key/create",                 element: <FacilityAccessKeyCreatePage         /> },
                ] },
                { path: "finance",    element: <AppFacilityFinance                    />, id: "facilityReport", loader: ldFacility, children: [
                    { path: "/facility/:facilityId/finance", element: <FacilityOnboardingPage                />, },
                    { path: "overview",                      element: <FacilityOnboardingPage                />, },
                    { path: "payment",                       element: <FacilityFinancePaymentListPage        />, loader: ldFacilityPayments },
                    { path: "invoice",                       element: <FacilityFinanceInvoiceListPage        />, loader: ldFacilityInvoices },
                    { path: "invoice/create",                element: <FacilityInvoiceCreatePage             />  },
                    { path: "invoice/batch",                 element: <FacilityFinanceInvoiceBatchPage       />  },
                    { path: "aging",                         element: <FacilityReportAgingPage               />, loader: ldAgingReport },
                    { path: "proc/payment",                  element: <FacilityFinanceStripePaymentListPage  />, },                    
                    { path: "payout",                        element: <FacilityFinancePayoutListPage         />, loader: ldFacilityPayouts },
                    { path: "payout/:payoutId",              element: <FacilityFinancePayoutDetailsPage      />, loader: ldFacilityPayout },
                    { path: "proc/payout",                   element: <FacilityFinanceStripePayoutListPage   />, },
                ] },
                { path: "parker/:parkerId",     element: <AppFacilityParker           />, loader: ldFacilityParker, id: "parker", children: [
                    { path: "/facility/:facilityId/parker/:parkerId", element: <FacilityParkerDetailsPage   />, loader: ldFacilityParker },
                    { path: "overview",                      element: <FacilityParkerDetailsPage            />, loader: ldFacilityParker },
                    { path: "plan/:subscriptionId",          element: <FacilityParkerSubscriptionEditPage   />, loader: ldSubDetails },
                    { path: "plan/:subscriptionId/manage",   element: <FacilityParkerSubscriptionManagePage />, loader: ldSubDetailsManage },
    
                    { path: "balance/history",               element: <FacilityParkerBalanceHistoryPage     />, loader: ldBalanceHistory },
                    { path: "order",                         element: <FacilityParkerOrderListPage          />, loader: ldOrders },
                    { path: "order/:orderId",                element: <FacilityParkerOrderDetailsPage       />, loader: ldOrderDetails },
    
                    { path: "invoice/",                      element: <FacilityParkerInvoiceListPage        />, loader: ldInvoices },
                    { path: "invoice/:invoiceId",            element: <FacilityParkerInvoiceDetailsPage     />, loader: ldInvoiceDetails },
    
                    { path: "payment",                       element: <FacilityParkerPaymentListPage        />, loader: ldPayments },
                    { path: "payment/:paymentId",            element: <FacilityParkerPaymentDetailsPage     />, loader: ldPaymentDetails },                    
    
                    { path: "vehicle",                       element: <FacilityParkerVehicleListPage        />, loader: ldVehicles },
                    { path: "vehicle/:vehicleId",            element: <FacilityParkerVehicleDetailsPage     />, loader: ldVehicles }
                ] }
            ] },
        ] }
    ] } ]
);

const root = ReactDOM.createRoot(
    document.getElementById( 'root' ) as HTMLElement
); 

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
