import { ReactNode, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { EnvelopePaper, Lock, PencilSquare, Person, Unlock } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Staff } from "./Data/ApiTypes";
import StaffEditModal, { StaffEditData } from "./OperatorStaffListPageEditModal";
import { Api, identity, showRole, unknown } from "./Data/Api";
import { showDate, showInstantLocalDateTime } from "./Data/Date";
import { ZonedDateTime } from "@js-joda/core";
import { ConfirmModal } from "./Controls/ConfirmModal";
import { ClipboardLink } from "./Controls/ClipboardLink";
import { loadResult } from "./Data/Result";

function Info( { title, children }: { title: string, children: ReactNode } ) {
    return <>
        <div className="text-secondary ">
            {title}
        </div>
        <div className="mb-3">
            {children}
        </div>
    </>;
}

export function OperatorStaffDetailsPage() {
    const nav   = useNavigate();
    const { revalidate } = useRevalidator();
    const staff = useLoaderData() as Staff;
    const [ confirmDisable, setConfirmDisable ] = useState( false );
    const [ confirmEnable,  setConfirmEnable  ] = useState( false );
    const [ showEdit,       setShowEdit       ] = useState( false );
    const [ staffToEdit,    setStaffToEdit    ] = useState<Staff>();

    function edit() {
        setStaffToEdit( staff );
        return setShowEdit( true );
    }

    function setupEnable() { setConfirmEnable( true ); }
    async function enable() {
        const x = loadResult( await Api.staffEnable( staff.staffId ), identity, unknown );
        revalidate();
    }

    function setupDisable() { setConfirmDisable( true ); }
    async function disable() {
        const x = loadResult( await Api.staffDisable( staff.staffId ), identity, unknown );
        revalidate();
    }

    return <Container fluid className="h-100">
        <ConfirmModal
            title="Disable Account?"
            message="Are you sure you want to disable this account?"
            cancelText="Cancel"
            confirmText="Disable Account"
            show={confirmDisable}
            setShow={setConfirmDisable}
            onConfirm={disable} />

        <ConfirmModal
            title="Enable Account?"
            message="Are you sure you want to enable this account?"
            cancelText="Cancel"
            confirmText="Enable Account"
            show={confirmEnable}
            setShow={setConfirmEnable}
            onConfirm={enable} />

        <StaffEditModal
            show={showEdit}
            setShow={setShowEdit}
            onSave={ async ( data: StaffEditData ) => {
                const updated = await Api.staffUpdate( data.staffId, data );
                revalidate();
            }}
            initialData={staffToEdit} />
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr 5px" }}>
                <PageBar title="Staff Details">
                    <ToolButton onClick={edit}>
                        <PencilSquare color="maroon" size="16" /> Edit Contact
                    </ToolButton>
                    {staff.removedWhen !== null && <ToolButton onClick={setupEnable}>
                        <Unlock color="maroon" size="16" /> Enable Account
                    </ToolButton>}
                    {staff.removedWhen === null && <ToolButton onClick={setupDisable}>
                        <Lock color="maroon" size="16" /> Disable Account
                    </ToolButton>}
                </PageBar>
                <div>
                    <Info title="Name">
                        {staff.name} <Badge>{staff.removedWhen === null ? "Enabled" : "Disabled"}</Badge>
                    </Info>
                    <Info title="Role">
                        {showRole( staff.role )}
                    </Info>
                    <Info title="Email">
                        <ClipboardLink>{staff.emailAddress}</ClipboardLink>
                    </Info>
                    {staff.phoneNumber && <Info title="Phone">
                        <ClipboardLink>{staff.phoneNumber}</ClipboardLink>
                    </Info>}
                    {staff.note.trim().length !== 0 && <Info title="Note">
                        {staff.note}
                    </Info>}
                    <Info title="Account Created">
                        {showInstantLocalDateTime( staff.createdWhen )}
                    </Info>
                    {staff.removedWhen && <Info title="Account Removed">
                        {staff.removedWhen && showInstantLocalDateTime( staff.removedWhen )}
                    </Info>}
                </div>
            </Col>
        </Row>
    </Container>;
}