import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

export interface InfoIconProps {
    header: string;
    body:   string;
}

export function InfoIcon( props: InfoIconProps ) {
    const { header, body } = props;

    const popover = <Popover className="position-fixed" id="popover-basic">
        <Popover.Header as="h4">
            {header}
        </Popover.Header>
        <Popover.Body>
            {body}
        </Popover.Body>
    </Popover>;
    return <OverlayTrigger overlay={popover}>
        <InfoCircle color="black" />
    </OverlayTrigger>;
}

export interface InfoIconSmallProps {    
    children: React.ReactNode;
}

export function InfoIconSmall( props: InfoIconSmallProps ) {
    const { children } = props;
    const popover = <Popover className="position-fixed" id="popover-basic">
        <Popover.Body>
            {children}
        </Popover.Body>
    </Popover>;
    return <OverlayTrigger overlay={popover}>
        <InfoCircle color="black" />
    </OverlayTrigger>;
}

export interface TooltipContentProps {
    children: React.ReactNode;
    tooltip: React.ReactNode;
}

export function TooltipContent(props: TooltipContentProps) {
    const { children, tooltip } = props;    
    const popover = <Popover className="position-fixed" id="popover-basic">
        <Popover.Body>
            {tooltip}
        </Popover.Body>
    </Popover>;
    return <OverlayTrigger overlay={popover}>
        <span>{children}</span>
    </OverlayTrigger>;
}