import { LocalDate } from "@js-joda/core";
import { SubscriptionStatus } from "./ApiTransport";
import { Subscription } from "./ApiTypes";

export interface StatusOption { lbl: string; expl: string; val: SubscriptionStatus; }


export const showSubscriptionStatus = ( status: SubscriptionStatus ): string => {
    switch ( status ) {
        case SubscriptionStatus.Applied: return 'Applied';
        case SubscriptionStatus.Rejected: return 'Rejected';
        case SubscriptionStatus.WaitingForContract: return 'Waiting for Contract';
        case SubscriptionStatus.Active:    return 'Active';
        case SubscriptionStatus.Cancelled: return 'Cancelled';
        default:
            return 'Unknown Status';
    }
};

export const reject = {
    lbl: "Reject Application",
    expl: "The application will be rejected",
    val: SubscriptionStatus.Rejected
};

export const accept = {
    lbl: "Accept Application",
    expl: "The parker will be sent a contract to fill out via email",
    val: SubscriptionStatus.WaitingForContract
};

export const undoReject = {
    lbl:  "Undo Reject Application",
    expl: "Set to Applied",
    val: SubscriptionStatus.Applied
};

export const undoAccept = {
    lbl:  "Undo Accept Application",
    expl: "Set to Applied",
    val: SubscriptionStatus.Applied
};

export const acceptContract = {
    lbl:  "Accept Contract",
    expl: "Set to Active",
    val: SubscriptionStatus.Active
};

export const cancel = {
    lbl:  "Cancel Plan",
    expl: "The Plan will be cancelled and will not renew",
    val: SubscriptionStatus.Cancelled
};

export const reactivate = {
    lbl:  "Reactivate Plan",
    expl: "Set to Active",
    val: SubscriptionStatus.Active
};

export function getInstructions( status: SubscriptionStatus ): string {
    if( status === SubscriptionStatus.Applied ) {
        return "Review the application and accept or reject it";
    }
    if( status === SubscriptionStatus.Rejected ) {
        return "Application has been rejected.  The hold on their payment method has been removed.  This subscription is permanently inactive.";
    }
    if( status === SubscriptionStatus.WaitingForContract ) {
        return "Contract has been sent to customer. Once received, you can accept the contract";
    }
    if( status === SubscriptionStatus.Active ) {
        return "Subscription is active and will auto-renew if payment method is present.";
    }
    if( status === SubscriptionStatus.Cancelled ) {
        return "Subscription is cancelled and will not renew.";
    }
    return "Unknown status";
}

export function filterStatusOptions( status: SubscriptionStatus ): StatusOption[] {
    if( status === SubscriptionStatus.Applied ) {
        return [reject, accept];
    }
    if( status === SubscriptionStatus.Rejected ) {
        return [undoReject];
    }
    if( status === SubscriptionStatus.WaitingForContract ) {
        return [acceptContract];
    }
    if( status === SubscriptionStatus.Active ) {
        return [cancel];
    }
    if( status === SubscriptionStatus.Cancelled ) {
        return [reactivate];
    }
    return [];
}

export function currentSpaces( sub: Subscription ) {
    const currentQuantity = sub.accessPeriods
                               .filter( ap => ap.start.isBefore( LocalDate.now() ) && LocalDate.now().isBefore( ap.end ) )
                               .map( ap => ap.quantity ).reduce( (a, b) => a + b, 0 );
    return currentQuantity;
}