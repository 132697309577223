import { Modal, Form } from "react-bootstrap";
import { useForm   }    from "react-hook-form";
import { Button    }    from "./Controls/Button";
import { Input     }    from "./Controls/Input";
import { UsAddress }    from "./Data/ApiTypes";

interface AddressModalProps {
    address: UsAddress;
    entry:   string;
    show:    boolean;
    setShow: ( show:    boolean         ) => void;
    onSave:  ( address: AddressAndEntry ) => void;
}

export interface AddressAndEntry extends UsAddress {
    entry: string;
}

export function AddressModal( { address, entry, show, onSave, setShow }: AddressModalProps ) {

    const { register, watch, formState: { errors }, handleSubmit } = useForm<AddressAndEntry>(
        { defaultValues: { ...address, entry: entry } } );

    function submit( data: AddressAndEntry ) {        
        onSave( data );
        setShow( false );
    }

    return <Modal show={show} centered onEscapeKeyDown={ () => setShow( false ) }>
        <Form onSubmit={handleSubmit( submit )}>
            <Modal.Header className="fs-5">
                Edit Address and Entry
            </Modal.Header>
            <Modal.Body>
                <Input
                    type="text"
                    {...register( "addressLine1", { required: "Street address 1 is required" } )}
                    label="Street Address"
                    error={errors.addressLine1} />

                <Input
                    type="text"
                    {...register( "city", { required: "City is required" } )}
                    label="City"
                    error={errors.city} />
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} className="gap-2">
                    <Input
                        type="text"
                        {...register( "state", { required: "State is required", maxLength: 2 } )}
                        label="State"
                        maxLength={2}
                        error={errors.state} />
                    <Input
                        type="text"
                        {...register( "zipCode", { required: "Zip code is required", maxLength: 10 } )}
                        label="Zip Code"
                        maxLength={10}
                        error={errors.zipCode} />
                </div>
                <Input
                    rows={3}
                    className="pt-0"
                    type="text"
                    as="textarea"
                    {...register( "entry", { required: "Entry instructions are required" } )}                    
                    label="Entry Instructions"
                    error={errors.entry} />
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow( false )}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}