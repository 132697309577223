import React from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { Alphabet, At, Telephone } from "react-bootstrap-icons";
import { PhoneInput } from "./Controls/PhoneInput";

interface AddRecipientModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: (recipientInfo: RecipientForm) => void;
}

export interface RecipientForm {
    name:         string;
    emailAddress: string;
    phoneNumber:  string | null;
}

export function AddRecipientModal( { show, setShow, onSave }: AddRecipientModalProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<RecipientForm>();

    function submit( data: RecipientForm ) {
        onSave( data );
        setShow( false );
    }

    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
            <Form onSubmit={handleSubmit(submit)}>
                <Modal.Header className="fs-5">
                    <HeaderText>
                        Set Recipient
                    </HeaderText>
                </Modal.Header>
                <Modal.Body className="add-recipient">
                    <Input className="mb-2"
                        type="text"
                        icon={<Alphabet className="fs-5" />}
                        {...register("name", { required: "First name is required" })}
                        label="Name"
                        error={errors.name} />
                    <Input className="mb-2"
                        type="email"
                        icon={<At className="fs-5" />}
                        {...register("emailAddress", {
                            required: "Email address is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        label="Email Address"
                        error={errors.emailAddress} />
                    <PhoneInput
                        label="Phone Number"
                        explanation="Optional"
                        icon={<Telephone className="fs-5" />}
                        placeholder="###-###-####"
                        error={errors.phoneNumber}
                        {...register("phoneNumber", {
                            pattern: {
                                value:   /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        })} />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>;
}