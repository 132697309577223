import { Alert, Col, Container, Row } from "react-bootstrap";

//spotsync controls

//spotsync data
import { useRouteLoaderData } from "react-router-dom";
import { HeaderText } from "./Controls/HeaderText";
import { ConnectNotificationBanner, ConnectBalances, ConnectAccountManagement, ConnectAccountOnboarding } from "@stripe/react-connect-js";
import { ExtendedFacility } from ".";
import { PageTitle } from "./Controls/PageTitle";

export function FacilityOnboardingPage() {    
    const facility = useRouteLoaderData( "facilityReport" ) as ExtendedFacility;
    const acct     = facility.account;    
    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Overview
                </PageTitle>
            </Col>
        </Row>
        <Row>
            <Col>
                <ConnectAccountOnboarding
                    onExit={() => {
                        console.log( "The account has exited onboarding" );
                    }}>
                </ConnectAccountOnboarding>
            </Col>
        </Row>
        <Row>
            <Col>{acct.isOnboarded && <ConnectAccountManagement />}</Col>
        </Row>
        {!acct.isOnboarded && <Row>
            <Col>
                <Alert variant="warning">
                    You have not completed onboarding. <a target="_blank" href={acct.connectLink!}>
                        Click here to finish onboarding.
                    </a>
                </Alert>
            </Col>
        </Row>}
        <Row>
            <Col xs="12" md="12">
                <ConnectNotificationBanner collectionOptions={{ fields: "eventually_due", futureRequirements: "include" }} />
                <br />
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="12">
                <HeaderText>Balance</HeaderText>
                <ConnectBalances />
                <br />
            </Col>
        </Row>        
    </Container>;
}
