import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { cents, showMoney } from "./Data/Money";
import { CurrencyDollar, Hash } from "react-bootstrap-icons";
import { multiply } from "dinero.js";
import { OrderCustomItemReqApi } from "./Data/ApiTransport";
import { HeaderText } from "./Controls/HeaderText";
import { MoneyInput } from "./Controls/MoneyInput";

interface AddRecipientModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: (recipientInfo: OrderCustomItemReqApi ) => void;
}

export function AddLineItemModal( { show, setShow, onSave }: AddRecipientModalProps) {
    const { register, watch, reset, formState: { errors }, handleSubmit } = useForm<OrderCustomItemReqApi>( { defaultValues: {
        name:      "",
        category:  "",
        unitPrice: 0,
        quantity:  1
    } } );

    const vals = watch();
    function submit( data: OrderCustomItemReqApi ) {
        console.log(data);
        onSave( data );
        reset();
        setShow(false);
    }

    let total = "Invalid Input";
    let totalClassName = "text-danger";
    try {
        total = showMoney( multiply( cents( vals.unitPrice ), vals.quantity ) );
    }
    catch {
        //nothing to do...
    }
    totalClassName = "text-success";
    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header>
                <HeaderText>Add Line Item</HeaderText>
            </Modal.Header>
            <Modal.Body className="add-recipient">                
                {/* <Input className="mb-2"
                    type="text"
                    {...register( "category", { required: "Category is required" })}
                    label="Category (optional)"
                    error={errors.category}
                /> */}
                <Input className="mb-2"
                    type="text"
                    autoFocus
                    {...register( "name", { required: "Product or Service is required" })}
                    label="Product or Service"
                    error={errors.name}
                />
                <div className="two-col">
                    <MoneyInput                        
                        className="mb-2"
                        icon={<CurrencyDollar className="fs-5" />}
                        {...register( "unitPrice", {
                            required: "Unit Price is required",
                            min:           0,
                            minLength:     1,
                            valueAsNumber: true
                        })}
                        label="Unit Price"
                        error={errors.unitPrice}
                    />
                    <Input className="mb-2"
                        type="number"
                        minLength={1}
                        min={1}
                        icon={<Hash className="fs-5" />}
                        {...register( "quantity", {
                            required: "Quantity is required",
                            min:           1,
                            minLength:     1,
                            valueAsNumber: true
                        })}
                        label="Quantity"
                        error={errors.quantity}
                    />
                </div>
                <div className="two-col mt-2">
                    <div className="text-end">Total Price</div>
                    <div className={`text-end fw-bold text-success ${totalClassName}`}>
                        {total}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>; 
}