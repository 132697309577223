import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cents, showMoney } from "./Data/Money";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { useLoaderData, useNavigate } from "react-router-dom";
import { ParkerAgingRecord } from "./Data/ApiTypes";
import { DataGrid } from "./Controls/DataGrid";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { EnvelopePaper } from "react-bootstrap-icons";

export function FacilityReportAgingPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const agingRecords = useLoaderData() as ParkerAgingRecord[];
    
    const highlight = ( num: number, cls: string ) => num > 0 ? `text-end ${cls}` : 'text-end';
    const agingColumns = useMemo<ColDef<ParkerAgingRecord>[]>(() => [
        {
            field: "parker.parkerId",
            headerName: "ID",
            width: 80
        },
        {
            field: "parker.name",
            headerName: "Name",
            valueGetter: (params) => params.data!.parker.name
        },
        {
            field: "parker.emailAddress",
            headerName: "Email",
            valueGetter: (params) => params.data!.parker.emailAddress,
            flex: 1
        },
        {
            field: "days30",
            headerName: "30 Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-success" )
        },
        {
            field: "days60",
            headerName: "60 Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-warning" )
        },
        {
            field: "days90",
            headerName: "90+ Days",
            type: "rightAligned",
            valueFormatter: (params) => showMoney( cents( params.value ) ),
            cellClass: (params) => highlight( params.value, "text-danger" )
        },
        {
            headerName: "Total",
            type: "rightAligned",
            valueGetter: (params) => params.data!.days30 + params.data!.days60 + params.data!.days90,
            valueFormatter: (params) => showMoney( cents( params.value )),
            cellClass: (params) => highlight( params.value, "text-success fw-bold" )
        }
    ], []);

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: RowClickedEvent<any> ) => {
        if( event.rowPinned ) {
            return;
        }
        nav( `/facility/${facilityId}/parker/${event.data.parker.parkerId}` );
    };

    //footer
    const agingFooter = useMemo( () => {
        const total30 = agingRecords.reduce( ( sum, r ) => sum + r.days30, 0 );
        const total60 = agingRecords.reduce( ( sum, r ) => sum + r.days60, 0 );
        const total90 = agingRecords.reduce( ( sum, r ) => sum + r.days90, 0 );
        const grandTotal = total30 + total60 + total90;

        return [
            {
                parker: {
                          parkerId:  null,
                          firstName: 'Total',
                          lastName:  '',
                          email:     ''
                },
                days30: total30,
                days60: total60,
                days90: total90,
                total:  grandTotal
            }
        ];
    }, [agingRecords] );

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto 1fr 5px", gridAutoColumns: "1fr" }}>
                    <PageBar title="Parker Aging Report" />
                    <div style={{height: "100%", width: '100%'}}>
                        <DataGrid
                            rowData={agingRecords}
                            columnDefs={agingColumns}
                            onGridReady={onGridReady}
                            onRowClicked={onRowClicked}
                            pinnedBottomRowData={agingFooter}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}