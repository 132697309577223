import { useMemo } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

import { Order } from "./Data/ApiTypes";
import { useLoaderData, useNavigate } from "react-router-dom";
import { showMoney } from "./Data/Money";
import { showOrderInitiator, showOrderType } from "./Data/Finance";
import { showInstantDateTime } from "./Data/Date";
import { PageTitle } from "./Controls/PageTitle";
import { BalanceRenderer, DataGrid } from "./Controls/DataGrid";
import { PageBar } from "./Controls/PageBar";
import { gridOneCol } from "./Data/Common";

export function FacilityParkerOrderListPage() {
    const nav = useNavigate();
    const orders = useLoaderData() as Order[];

    const defaultColDef = useMemo<ColDef>( () => ( {
        sortable:  true,
        filter:    true,
        resizable: true,
    } ), [] );

    const orderColumns = useMemo<ColDef<Order>[]>( () => [
        { field: 'orderId',                   headerName: 'Order #',        type: 'rightAligned' },
        { field: 'createdWhen',               headerName: 'Created Date',   hide: false, filter: 'agDateColumnFilter',
            valueFormatter: ps => showInstantDateTime( ps.value, ps.data?.facility.timeZone! ) },
        { field: 'type',                      headerName: 'Type',           valueFormatter: ps => showOrderType( ps.value ) },
        { field: 'summary',                   headerName: 'Summary',        valueFormatter: ps => showOrderType( ps.value ) },        
        { field: 'initiatedByStaffId',        headerName: 'Staff Id',       type: 'rightAligned', hide: true },
        { field: 'parkerId',                  headerName: 'Parker Id',      type: 'rightAligned', hide: true },
        { field: 'facilityId',                headerName: 'Facility Id',    type: 'rightAligned', hide: true },
        { field: 'price',                     headerName: 'Price',          type: 'rightAligned', valueFormatter: ps => ps.value ? showMoney( ps.value ) : "" },
        { field: 'processorPaymentIntentKey', headerName: 'Payment Intent', hide: true },
        { field: 'balance.balance',           headerName: 'Balance',        type: 'rightAligned', cellRenderer: BalanceRenderer },
        { field: 'initiator',                 headerName: 'Created By',     hide: true, valueFormatter: ps => showOrderInitiator( ps.value ) },
    ], [] );

    const paymentColumns = useMemo<ColDef[]>( () => [
        { field: 'type',        headerName: 'Type' },
        { field: 'paymentId',   headerName: 'Payment #',   type: 'rightAligned' },
        { field: 'amount',      headerName: 'Amount',      type: 'rightAligned' },
        { field: 'createdWhen', headerName: 'Created Date' },
    ], [] );

    const onRowClicked = ( event: any ) => {
        const order: Order = event.data;
        nav( `/facility/${order.facilityId}/parker/${order.parkerId}/order/${order.orderId}` );
    };

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col style={gridOneCol("max-content 1fr 5px")}>
                <PageBar title="Orders" />
                <div style={{height: "100%", width: '100%'}}>
                    <DataGrid
                        onRowClicked={onRowClicked}
                        rowData={orders}
                        columnDefs={orderColumns}
                    />
                </div>
            </Col>
        </Row>
    </Container>;
}