import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { MoneyInput } from "./Controls/MoneyInput";
import { Money, showMoney } from "./Data/Money";

interface PaymentAdjustModalProps {
    currentTotalDebt: Money;
    show:          boolean;
    setShow:       ( show:       boolean         ) => void;
    onSave:        ( adjustData: OrderAdjustForm ) => void;
}

export interface OrderAdjustForm { 
    newTotalDebt: number;
    reason:       string;
}

export function OrderAdjustModal( { currentTotalDebt, show, setShow, onSave }: PaymentAdjustModalProps ) {
    const { register, watch, setValue, formState: { errors }, reset, handleSubmit } = useForm<OrderAdjustForm>({
        defaultValues: {
            newTotalDebt: 0,
            reason:       "", 
         }
    } );
    
    function submit( data: OrderAdjustForm ) {
        onSave( data );
        reset();
        setShow(false);
    }

    const reason = watch( "reason" );
    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header className="fs-5">
                <HeaderText>
                    Adjust Order Debt
                </HeaderText>
            </Modal.Header>
            <Modal.Body>
                <p className="p-2 text-secondary">
                    Use this page to set the order's debt to a new value.
                    You should only use this when there was an error in data entry 
                    such as entering 10 when you intended 100 
                </p>
                <Input
                    type="text"
                    label="Reason"
                    maxLength={64}
                    placeholder="Accidentally Entered 10 instead of 100"
                    explanation={`Why is an adjustment needed? ${reason.length}/64`}
                    {...register( "reason", {
                        required: "Note is required"
                    })}
                    error={errors.reason} />

                <Input
                    inputClassName="text-end"
                    type="text"
                    label="Current Order Debt"
                    disabled
                    value={showMoney( currentTotalDebt )}
                    placeholder=""
                    explanation={`The current value of the payment in the system`} />

                <MoneyInput
                    label="New Amount"
                    inputClassName="text-end"
                    explanation="The order's total debt will be set to this amount"
                    min="0"
                    {...register("newTotalDebt", {
                        valueAsNumber: true,
                        required:      "Amount is required",
                        validate: (value: number) => value != 0 && (!isNaN(value)) || "Please enter a valid number"
                    })}
                    error={errors.newTotalDebt} />
                    
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}