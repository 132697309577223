import React, { forwardRef, ReactNode, useId } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { FieldError } from "react-hook-form";

interface MonthInputProps extends FormControlProps {
    label:        string;
    icon:         ReactNode;
    placeholder:  string;
    explanation?: string;
    error?:       FieldError | undefined
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export const MonthInput = forwardRef<FormControlElement, MonthInputProps>(
    ( { label, icon, placeholder, explanation, error, ...props }, ref ) => {        
        const inputId = useId();
        const helpId  = `${inputId}-help`;
        return <Form.Group className="mb-3">
            <Form.Label className="mb-1">{label}</Form.Label>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">
                    {icon}
                </InputGroup.Text>
                <Form.Control ref={ref}
                    placeholder={placeholder}
                    type="month"
                    className="text-center"
                    {...props} />
            </InputGroup>
            {explanation &&
                <Form.Text id={helpId} className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);

MonthInput.displayName = 'MonthInput';
export default MonthInput;
