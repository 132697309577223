import { useMemo, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { useLoaderData, useNavigate } from "react-router-dom";
import { PlanDetails, PlanPriceDetails, SubscriberInfo } from "./Data/ApiTypes";
import { cents, showMoney, showMoneyShort, toCents } from "./Data/Money";
import { HeaderText } from "./Controls/HeaderText";
import { subtract } from "dinero.js";
import { Pencil, QrCode, X } from "react-bootstrap-icons";
import { colText, colNumber, DataGrid } from "./Controls/DataGrid";
import { useParamIds } from "./Data/Common";
import { PageBar, ToolButton } from "./Controls/PageBar";

export function FacilityPlanDetailsPage() {
    const nav = useNavigate();
    const { facilityId, planId } = useParamIds();
    const plan = useLoaderData() as PlanDetails;
    const [selPrice, setSelPrice] = useState<PlanPriceDetails>( plan.prices[0] );

    function totalSubscribersImpl( subInfo: SubscriberInfo[] ) {
        return subInfo.map( x => x.quantity ).reduce( ( a, b ) => a + b, 0 );
    }

    function totalMonthlyImpl( p: PlanPriceDetails, numSpaces: number ): any {
        return cents( toCents( p.price ) * numSpaces );
    }

    const columns = useMemo<ColDef<SubscriberInfo>[]>( () => [
        colText(   'name'         ),
        colText(   'emailAddress' ),
        colText(   'phoneNumber'  ),
        colNumber( 'quantity'     ),
        colText(   'phoneNumber'  ),
        { headerName: "Monthly MRR",
            valueFormatter: v => showMoney( totalMonthlyImpl( selPrice, v.data?.quantity! ) ),
            type: "rightAligned"
        }
    ], [] );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const sortedPrices = plan.prices.toSorted( ( a, b ) => toCents( subtract( a.price, b.price ) ) );
    return (
        <Container fluid className="pt-2 h-100" >
            <Row className="h-100">
                <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto 1fr 5px", gridAutoColumns: "1fr" }}>
                    <div className="d-flex align-items-center gap-2">
                        <PageBar title={plan.name}>
                            <ToolButton onClick={ () => nav( `/facility/${facilityId}/plan/${planId}/edit` ) }>
                                <Pencil color="maroon" /> Edit Plan
                            </ToolButton>
                            <ToolButton onClick={ () => nav( `/facility/${facilityId}/plan/${planId}/sign` ) }>
                                <QrCode color="maroon" /> View QR Code
                            </ToolButton>
                        </PageBar>
                    </div>
                    <Container fluid className="g-0 py-0 my-0 h-100">
                        <Row className="h-100">
                            <Col xs="3">
                                <Card className="p-0 m-0 gap-0">
                                    <Card.Header>
                                        <HeaderText className="text-center">
                                            Prices
                                        </HeaderText>
                                    </Card.Header>
                                    <Card.Body className="p-0 m-0 gap-0">
                                        <Table variant="hover">
                                            <thead>
                                                <tr>
                                                    <th className="text-end pe-3">Price  </th>
                                                    <th className="text-end pe-3" colSpan={2}>Parkers</th>
                                                    <th className="text-end pe-3">MRR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedPrices.map( p => {
                                                        const sel = p === selPrice ? "bg-primary text-white" : "";
                                                        const monthly = totalMonthlyImpl( p, totalSubscribersImpl( p.subscribers ) );
                                                        return <tr className={`text-center ${sel}`} onClick={() => setSelPrice( p )}>
                                                                <td className={`${sel} text-end pe-3`}>{showMoney( p.price )}</td>
                                                                <td className={`${sel} text-end pe-3`}><X /></td>
                                                                <td className={`${sel} text-end pe-3`}>{totalSubscribersImpl( p.subscribers )}</td>
                                                                <td className={`${sel} text-end pe-3`}>{showMoneyShort( monthly )}</td>
                                                        </tr>;
                                                    } )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs="9" className="h-100">
                                <div style={{ height: "100%", width: '100%' }}>
                                    <DataGrid
                                        rowData={selPrice.subscribers}
                                        columnDefs={columns}
                                        onGridReady={onGridReady}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

