import { Container, Row, Col, Card, CloseButton, Modal, Badge } from "react-bootstrap";
import { useParamIds } from "./Data/Common";
import { getStateEnumFromCode, showStateName } from "./Data/UsState";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { ParkerInfo, Subscription, SubscriptionParkerChangeReq, Vehicle } from "./Data/ApiTypes";
import { useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { Hyperlink } from "./Controls/Hyperlink";
import { currentSpaces as getCurrentSpaces, getInstructions, showSubscriptionStatus } from "./Data/Subscription";
import { authAtom } from "./Data/Atoms";
import { useAtomValue } from "jotai";
import { parseSubscriptionParker } from "./Data/ApiParse";
import { pluralize } from "./Data/English";
import { CreditCard } from "react-bootstrap-icons";
import { Button } from "./Controls/Button";
import { HeaderText } from "./Controls/HeaderText";
import { PageTitle } from "./Controls/PageTitle";
import { CardSection } from "./Controls/CardSection";
import { showDate } from "./Data/Date";
import React from "react";
import { InviteParkerForm, InviteParkerModal } from "./UserPlanDetailsPageInviteParkerModal";
import { QuantityModal } from "./UserPlanDetailsPageChangeQuantityModal";
import { ConfirmModal } from "./Controls/ConfirmModal";
import { ChooseVehicleModal } from "./Controls/ChooseVehicleModal";
import { MediumPage } from "./Controls/MediumPage";
import { CardSectionDisabled } from "./Controls/CardSectionDisabled";

export interface ReservationVehicleCardProps {
    vehicle: Vehicle;
    onRemove: ( v: Vehicle ) => void;
}

export function ReservationVehicleCard( { vehicle, onRemove }: ReservationVehicleCardProps ) {
    return <div className="mb-2 m-2">
        <div className="gap-1"
            style={{
                display:             "grid",
                alignItems:          "center",    /*  color make model */
                gridTemplateColumns: "min-content 1fr auto"
            }}>
            <div className="rounded-2 border border-secondary me-2"
                style={{ height: "32px", width: "32px", backgroundColor: vehicle.color }} />
            <div style={{ display: "grid", alignItems: "center", gridTemplateRows: "auto auto" }}>
                <div className="fw-bold d-flex flex-column gap-1">
                    {vehicle.make} {vehicle.model}
                </div>
                <div className="grid-col-1">
                    {showStateName( getStateEnumFromCode( vehicle.stateCode )! )} {vehicle.licensePlateNumber}
                </div>
            </div>
            <div className="ms-3">
                <CloseButton onClick={() => onRemove( vehicle )} />
            </div>
        </div>
    </div>;
}

interface PaymentMethodModalProps {
    show:                boolean;
    setShow:             (show: boolean) => void;
    handleRemovePayment: () => void;
    paymentMethod:       string;
}

export function PaymentMethodModal({ show, setShow, paymentMethod, handleRemovePayment }: PaymentMethodModalProps) {
    return (
        <Modal centered show={show} onEscapeKeyDown={() => setShow(false)}>
            <Modal.Header>
                <HeaderText>Payment Method</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3 px-2">
                    <p>
                        This plan is set up to renew using <strong>{paymentMethod}</strong>
                    </p>
                    <p>
                        You can stop the auto renewal of this plan by removing this payment method
                    </p>
                </div>
                <div className="d-flex flex-row justify-content-end gap-2">
                    <Button className="w-50 m-2" onClick={ () => setShow(false) }>
                        Close
                    </Button>
                    <Button className="w-50 m-2" onClick={handleRemovePayment}>
                        Remove                        
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export function FacilityParkerSubscriptionManagePage() {
    const nav = useNavigate();
    const { planId, parkerId }              = useParamIds();
    const [showInvite, setShowInviteParker] = useState( false );
    const [showAdd, setShowAdd]             = useState( false );
    const [showChoose, setShowChoose]       = useState( false );
    const [showQuantity, setShowQuantity]   = useState<SubscriptionParkerChangeReq>();
    const auth                              = useAtomValue( authAtom );
    const [loadedSub,   loadedVehicles]     = useLoaderData() as [Subscription, Vehicle[]];
    const [sub,         setSub]             = useState( loadedSub );
    const [parkers,     setParkers]         = useState( sub.parkers    );
    const [subVehicles, setSubVehicles]     = useState( sub.vehicles   );
    const [myVehicles,  setMyVehicles]      = useState( loadedVehicles );
    const [showMethod,  setShowMethod]      = useState( false );

    //remove parker
    const [showRemove,   setShowRemove]     = useState( false );
    const [removeParker, setRemoveParker]   = useState<ParkerInfo>();

    //convenience vars
    const plan                              = sub.currentEntry.plan;
    const facility                          = sub.facility;
    const quantity                          = sub.currentEntry.quantity;
    const facilityId                        = facility.facilityId;

    function parkerInvite( data: InviteParkerForm ) {
        Api.subParkerInvite( facilityId, sub.subscriptionId, { emailAddress:   data.emailAddress,
                                                   name:           data.name,
                                                   quantity:       1,
                                                   subscriptionId: sub.subscriptionId
        } ).then( resp => processResult( resp,
            val => setParkers( [...parkers, parseSubscriptionParker( val )] ),
            str => alert( str )
        ) );
    }

    function parkerRemove( parker: ParkerInfo ): void {
        Api.subParkerRemove( facilityId, sub.subscriptionId, { parkerId: parker.parkerId } )
            .then( resp => processResult( resp,
                val => {
                    const updated = parkers.filter( p => ( p.parker.parkerId !== parker.parkerId ) ? true : false );
                    setParkers( [ ...updated] )
                },
                err => alert( err )
        ) );
    }

    // async function vehicleAdd( data: Vehicle ) {
    //     const addRes = await Api.vehicleAdd( data );
    //     if( !addRes.isOk ) {
    //         alert( addRes.error );
    //         return;
    //     }
    //     setMyVehicles( [ ...myVehicles, addRes.value ] );
    //     await vehicleLink( addRes.value );
    // }

    async function vehicleLink( vehicle: Vehicle ) {
        const addToSub = await Api.subscriptionVehicleAdd( facilityId, sub.subscriptionId, { vehicleId: vehicle.vehicleId } );
        if( !addToSub.isOk ) {
            alert( addToSub.error );
            return;
        }
        setSubVehicles( [ ...subVehicles, vehicle ] );
    }

    async function vehicleRemove( vehicle: Vehicle ) {
        const deleteRes = await Api.subscriptionVehicleDelete( facilityId, 
            sub.subscriptionId, vehicle.vehicleId, { vehicleId: vehicle.vehicleId }
        );
        if( !deleteRes.isOk ) {
            alert( deleteRes.error );
            return;
        }
        setSubVehicles( subVehicles.filter( v => {
            return vehicle.vehicleId !== v.vehicleId;
        } ) );
    }

    async function parkerQuantityChange( data: SubscriptionParkerChangeReq )  {
        Api.subParkerChange( facilityId, sub.subscriptionId, data )
            .then( resp => processResult( resp,
                val => {
                    const updated = parkers.map( p => ( p.parker.parkerId !== data.parkerId ) ? p : { ...p, quantity: data.quantity } );
                    setParkers( [ ...updated] )
                },
                err => alert( err )
            ) );
    }

    function getOrdinal( n: number ) {
        let suffix = "th";
        if( n % 100 < 11 || n % 100 > 13 ) {
            switch( n % 10 ) {
                case 1: suffix = "st"; break;
                case 2: suffix = "nd"; break;
                case 3: suffix = "rd"; break;
            }
        }
        return n + suffix;
    }

    const status     = showSubscriptionStatus( sub.status );
    const instr      = getInstructions(        sub.status );
    const spaces     = getCurrentSpaces( sub );

    return <MediumPage>
        <InviteParkerModal show={showInvite}
            setShow={setShowInviteParker}
            submit={parkerInvite} />

        <QuantityModal
            show={showQuantity}
            setShow={setShowQuantity}
            onSave={parkerQuantityChange} />

        {/* <AddVehicleModal
            show={showAdd}
            setShow={setShowAdd}
            onSubmit={vehicleAdd} /> */}

        <ChooseVehicleModal
            show={showChoose}
            setShow={setShowChoose}
            onSubmit={vehicleLink}
            vehicles={myVehicles}
            exclude={subVehicles.map( sv => sv.vehicleId )} />

        <ConfirmModal title={"Remove Parker"}
            message={"Are you sure you want to remove this parker from your plan?"}
            show={showRemove}
            setShow={setShowRemove}
            onConfirm={() => {
                if( removeParker !== undefined ) {
                    parkerRemove( removeParker );
                }
            }} />

        <Row>
            <Col>
                <Container fluid className="g-0">
                    <Row>
                        <Col xs="12">
                            <PageTitle>
                                {facility.name}
                            </PageTitle>
                            <div className="d-flex gap-2 fs-5 fw-semibold text-muted align-items-center">
                                <Badge>{status}</Badge>{plan.name}
                            </div>
                            <div>
                                {instr}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="mb-3 gap-2 w-100" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
                            {/* <CardButton
                                onClick={() => nav( `/user/plan/${planId}/edit` )}
                                title={quantity}
                                subtitle="Spots" /> */}

                            {/* <CardButton
                                onClick={ () => {
                                    if( sub.paymentMethod === null ) {
                                        nav( `/user/plan/${planId}/setup` );
                                        return;
                                    }
                                    setShowMethod( true );
                                }}
                                title={sub.paymentMethod !== ""
                                    ? <CheckLg fill="green" />
                                    : <X fill="red" />
                                }
                                subtitle={sub.paymentMethod !== ""
                                    ? "Auto Renew"
                                    : "Add Payment Method"
                                } /> */}

                        </div>
                        <Col xs="12">
                            <div className="gap-2 w-100" style={{ display: "grid", gridTemplateColumns: "2fr 1fr", gridAutoRows: "1fr" }}>
                              <CardSectionDisabled                                    
                                    title={`Parking`}
                                    content={<div>
                                        <div>Your plan includes <strong>{spaces}</strong> purchased spots</div>
                                    </div>}
                                    icon={quantity}
                                    change="Spots"
                                />

                                <CardSectionDisabled
                                    icon={<CreditCard />}                                    
                                    title={"Billing"}
                                    change={sub.paymentMethod !== null
                                        ? `${sub.paymentMethod}`
                                        : "Add Payment Method"
                                    }
                                    content={sub.paymentMethod !== null
                                        ? "You will be billed on the " + getOrdinal( plan.dayOfMonthDue )
                                        : "Autopay is not enabled" } />

                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2 g-2 mb-4">
                        <Col xs="12" lg="12">
                            <Card className="shadow-1">
                                <Card.Header style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content" }}>
                                    <div>Parkers</div>
                                    <div>

                                    </div>
                                    <Link to="" onClick={() => setShowInviteParker( true )}>Invite Parker</Link>
                                </Card.Header>
                                <Card.Body className="p-0 m-0 border-0">
                                    {parkers.length == 0 && <div className="grid-col-1 text-secondary p-3">
                                        Invite others to this plan to let them manage their own vehicles.
                                    </div>}
                                    {parkers.map( ( p, i ) => {
                                        const parker = p.parker;
                                        return (
                                            <div className="rounded-0 border-1 border-0-top w-100"
                                                role="alert">
                                                <div className="p-2 px-3 d-flex align-items-center gap-2 bg-primary-subtle bg-gradient rounded-0">
                                                    <div className="me-auto">
                                                        {p.parker.name} &nbsp;
                                                        <small>
                                                            <small className="text-secondary">
                                                                <span>{p.parker.emailAddress}</span>
                                                            </small>
                                                        </small>
                                                    </div>
                                                    <Hyperlink onClick={() => setShowQuantity( {
                                                        parkerId: p.parker.parkerId,
                                                        quantity: p.quantity,
                                                    } )} >
                                                        {p.quantity} {pluralize( p.quantity, "spot", "spots" )}
                                                    </Hyperlink>
                                                    <CloseButton
                                                        onClick={() => { setRemoveParker( p.parker ); setShowRemove( true ) }}
                                                        className="cursor-pointer" />
                                                </div>
                                                <div className="p-2 px-4 pe-2 gap-1 align-items-center">
                                                    {p.vehicles.length === 0 && <>{parker.name} hasn't added any vehicles</>}
                                                    {p.vehicles.map( v => <ReservationVehicleCard key={v.vehicleId}
                                                        vehicle={v}
                                                        onRemove={vehicleRemove}
                                                    /> )}
                                                </div>
                                            </div>
                                        );
                                    } )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Card className="mt-3">
                    <Card.Header style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content max-content" }}>
                        <div>
                            My Vehicles
                        </div>
                        <div>

                        </div>
                        <div>
                            <Link to="" onClick={() => setShowAdd( true )}>Add New</Link>&nbsp; |
                            {myVehicles.length !== 0 && <>&nbsp;<Link to="" onClick={() => setShowChoose( true )}>Add Existing</Link></>}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {subVehicles.length === 0 && <div className="text-secondary">
                            You can add or remove vehicles to this plan at any time.  Each vehicle listed here uses a spot
                        </div>}
                        {subVehicles.map( v => <ReservationVehicleCard key={v.vehicleId} vehicle={v} onRemove={vehicleRemove} /> )}
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Header>
                        <div>
                            Access Periods
                        </div>
                    </Card.Header>
                    <Card.Body style={{ display: "grid", gridTemplateColumns: "auto auto auto auto" }}>
                        <div className="border-bottom border-primary border-1 text-center">Id</div>
                        <div className="border-bottom border-primary border-1 text-center">Spots</div>
                        <div className="border-bottom border-primary border-1 text-center">Start</div>
                        <div className="border-bottom border-primary border-1 text-center">End</div>
                        {sub.accessPeriods.map( ap => <React.Fragment>
                            <div className="text-center">#{ap.accessPeriodId}</div>
                            <div className="text-center">{ap.quantity} Spots</div>
                            <div className="text-center">{showDate( ap.start )}</div>
                            <div className="text-center">{showDate( ap.end )}</div>
                        </React.Fragment> )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </MediumPage>;
}
