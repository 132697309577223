import '@js-joda/timezone';
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";

//spotsync data
import { useMemo } from "react";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { FacilityStats } from "./Data/ApiTypes";
import { cents, Money, showMoney, showMoneyShort, toCents } from "./Data/Money";
import { ExtendedFacility } from ".";
import { pluralize } from "./Data/English";
import { Duration, Instant, ZoneId } from "@js-joda/core";
import { add } from "dinero.js";
import { showDurationShort } from "./Data/Date";
import { HeaderText } from "./Controls/HeaderText";
import { SubscriptionStatus } from "./Data/ApiTransport";

interface OverviewCardProps {
    size?:      number;
    label?:     string;    
    children?:  React.ReactNode;
    className?: string;
    style?:     React.CSSProperties;
    to?:        string;
}

function OverviewCard( { size      = 1,
                         label     = "",
                         className = "",
                         children  = <></>,        
                         style     = {},
                         to }: OverviewCardProps ) {

    const nav = useNavigate();
    if( !!to ) {
        className += "zoom-small-link";
    }
    return <Col xs={6} lg={6} xl={3}>
        <Card className={`shadow py-2 h-100 border-2 zoom-small rounded-4 text-nowrap ${className}`}
              onClick={ () => to !== undefined ? nav( to ) : 0 }>
            <Card.Body className="d-flex flex-column">
                <div className={`w-100 flex-grow-1 fs-${size} text-center`}>
                    {children}
                </div>
                <div className="text-center text-muted">
                    {label}
                </div>
            </Card.Body>
        </Card>
    </Col>;
}

export function FacilityOverviewPage() {
    const facility    = useRouteLoaderData( "facility" ) as ExtendedFacility;
    const stats       = useLoaderData()                  as FacilityStats;
    const subsByPrice = useMemo( () => {
        const priceGroups = stats.subscriptions.reduce( ( acc, sub ) => {
            const priceKey = `${sub.unit}`;
            if( !acc[priceKey] ) {
                acc[priceKey] = { value: 0, label: `$${showMoney( sub.unit )}` };
            }
            acc[priceKey].value += sub.qty;
            return acc;
        }, {} as Record<string, { value: number; label: string; }> );
        return Object.values( priceGroups );
    }, [stats.subscriptions] );

    const orderStats = useMemo( () => {
        const typeLabels: Record<number, string> = { 5: 'Init', 20: 'Renewal', 30: 'Upgrade', 40: 'Active' };
        return stats.orderStats.reduce( ( acc, order ) => {
            const type = typeLabels[order.type] || 'Other';
            if( !acc[type] ) {
                acc[type] = { paid: 0, unpaid: 0 };
            }
            acc[type][order.isPaid ? 'paid' : 'unpaid'] += toCents( order.price );
            return acc;
        }, {} as Record<string, { paid: number; unpaid: number; }> );
    }, [stats.orderStats] );

    const barData = useMemo( () =>
        Object.entries( orderStats ).map( ( [type, values] ) => ( {
            type,
            paid: Math.round( values.paid ),
            unpaid: Math.round( values.unpaid ),
        } ) ), [orderStats] );

    const startOfDay = Instant.now().atZone( ZoneId.of( facility.timeZone ) ).withHour( 0 ).withMinute( 0 ).toInstant();
    const rs = stats.reservations;
    const [totDurRes, totRes] = [
        rs.map( r => Duration.between( r.start, r.end ) ).reduce( (a, b) => a.plus( b ), Duration.ZERO ),
        rs.map( r => r.price ).reduce( (a, b) => add( a, b ), cents( 0 ) ),
    ]
    const addMon = ( a: Money,    b: Money    ) => add( a, b );
    const addDur = ( a: Duration, b: Duration ) => a.plusDuration( b  );
    const addNum = (a : number, b  : number   ) => a + b;

    const ords = stats.orderStats;
    const subs = stats.subscriptions;
    const totalPaid  = ords.filter( o => o.isPaid  ).map( o => o.price ).reduce( addMon, cents( 0 ) );
    const totalDebt  = ords.filter( o => !o.isPaid ).map( o => o.price ).reduce( addMon, cents( 0 ) );
    const activeSubs = subs.filter( s => s.status === SubscriptionStatus.Active );    
    const totSpots = activeSubs.map( s => s.qty ).reduce( addNum, 0 );
    const avgSpots = totSpots / activeSubs.length;    
    function facilityLink( link: string ) { return `/facility/${facility.facilityId}/${link}`; }
    return <Container fluid className="mb-3">
        <Row>
            <Col>
                <PageTitle>
                    {facility.name}
                </PageTitle>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="d-flex align-items-center">
                <HeaderText>Plans</HeaderText>
                <div className="flex-grow-1">

                </div>
                {/* <div>
                    <ButtonGroup>
                       <Button>All-Time</Button>
                       <Button>This Month</Button>
                       <Button>Today</Button>
                    </ButtonGroup>
                </div> */}
            </Col>
        </Row>
        <Row className="gy-3 gx-3">
            <OverviewCard label={pluralize( stats.pendingParkers, "New Application", "New Applications" )}
                          to={facilityLink("parker/applied")}>
                {stats.pendingParkers}
            </OverviewCard>
            <OverviewCard label="MRR" to={facilityLink("parker")}>
                {showMoneyShort( cents( stats.monthlyRecurringRevenue ) )}
            </OverviewCard>
            <OverviewCard label="Monthly Utilization" to={facilityLink("status")}>
                {totSpots} <span className="fs-5">spots</span>
            </OverviewCard>            
            <OverviewCard label="Active Plans">                
                {stats.subscriptions.filter( s => s.status === SubscriptionStatus.Active).length}
            </OverviewCard>
            {/* <OverviewCard label="Average Spots / Plan">                
                {avgSpots.toFixed( 1 )}
            </OverviewCard> */}

        </Row>
        <Row className="mt-3">
            <Col>
                <HeaderText>Reservations</HeaderText>
            </Col>
        </Row>
        <Row className="gy-3 gx-3">
            <OverviewCard label="Active">
                {stats.activeReservations}
            </OverviewCard>
            <OverviewCard label="Upcoming">
                {stats.upcomingReservations}
            </OverviewCard>
            <OverviewCard label="Total">
                {stats.reservations.length}
            </OverviewCard>
            <OverviewCard label="Time Reserved">
                {showDurationShort( totDurRes )}
            </OverviewCard>
        </Row>
        <Row className="mt-3">
            <Col>
                <HeaderText>
                    Orders
                </HeaderText>
            </Col>
        </Row>
        <Row className="gy-3 gx-3">
            <OverviewCard label="Today's Orders">
                {stats.orderStats.filter( o => startOfDay.isBefore( o.created ) ).length}
            </OverviewCard>
            <OverviewCard label="Today's Orders">
                {showMoneyShort( stats.orderStats.filter( o => startOfDay.isBefore( o.created ) ).map( o => o.price ).reduce( addMon, cents( 0 ) ) )}
            </OverviewCard>
            <OverviewCard label="Paid">
                {showMoneyShort( totalPaid )}
            </OverviewCard>
            <OverviewCard label="Unpaid">
                {showMoneyShort( totalDebt )}
            </OverviewCard>
        </Row>
    </Container>;
}
