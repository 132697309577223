import { Badge, Col, Row } from "react-bootstrap";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Invoice, ParkerDetails } from "./Data/ApiTypes";
import { cents, showMoney } from "./Data/Money";
import { showDate, showInstantDate, showInstantDateTime } from "./Data/Date";
import { showAddressHtml } from "./Data/Location";
import { Envelope, Telephone } from "react-bootstrap-icons";
import { showOrderType } from "./Data/Finance";
import { LargePage } from "./Controls/LargePage";
import { equal } from "dinero.js";

export function FacilityParkerInvoiceDetailsPage() {
    const invoice  = useLoaderData()              as Invoice;
    const parker   = useRouteLoaderData("parker") as ParkerDetails;
    const facility = invoice.facility;
    const order    = invoice.order;
    return <LargePage>
        {equal( invoice.order.balance.balance, cents( 0 ) ) === true ? <div>
            <Badge>Paid </Badge>
        </div> : <Badge>Unpaid</Badge>}
        <div className="p-0 border-0 p-md-3 shadow-md mt-3 rounded-0">
            <Row>
                <Col xs={6}>
                    <div className="d-flex flex-column">
                        <strong className="fs-5 text-uppercase">
                            {facility.name}
                        </strong>
                        <div>
                            {showAddressHtml( facility.address )}
                        </div>
                        <div className="mt-1 d-flex-align-center gap-2">
                            <Envelope color="maroon" /> {facility.emailAddress}
                        </div>
                        <div className="d-flex-align-center gap-2">
                            <Telephone color="maroon" /> {facility.phoneNumber}
                        </div>
                    </div>
                </Col>
                <Col xs={6} className="text-md-end">
                    <div>
                        <span className="fw-semibold text-secondary">INVOICE</span>
                        &nbsp;
                        <span className="fw-bold">
                            #{invoice.invoiceNumber}
                        </span>
                    </div>
                    <div>
                        {showInstantDate( invoice.createdWhen, facility.timeZone )}
                    </div>
                </Col>
            </Row>
            <hr />
            <Row className="mb-4 gy-2">
                <Col lg={4}>
                    <div className="fs-4 text-secondary text-uppercase">
                        Parker
                    </div>
                    <div className="d-flex flex-column">
                        <div>
                            {parker.name}
                        </div>
                        <div className="d-flex-align-center gap-2">
                            <Envelope color="maroon" />{parker.emailAddress}
                        </div>
                        {!!parker.phoneNumber && <div className="d-flex-align-center gap-2">
                            <Telephone color="maroon" /> {parker.phoneNumber}
                        </div>}
                    </div>
                </Col>
                <Col lg={8}>
                    <div className="fs-4 ms-1 text-secondary text-uppercase">
                        Details
                    </div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Service Type</td>
                                <td>{showOrderType( order.orderType )}</td>
                            </tr>
                            <tr>
                                <td>Service Details</td>
                                <td>{order.summary}</td>
                            </tr>
                            <tr>
                                <td>Opening Balance</td>
                                <td>{showMoney( invoice.openingBalance )}</td>
                            </tr>
                            <tr>
                                <td>Service Price</td>
                                <td className="">{showMoney( order.price )}</td>
                            </tr>
                            <tr>
                                <td>Ending Balance</td>
                                <td>{showMoney( invoice.endingBalance )}</td>
                            </tr>
                            <tr>
                                <td>Due Date</td>
                                <td>{showDate( invoice.dueWhen )}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-muted d-flex flex-column">
                        <div>Note: Make checks payable to {facility.name}</div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="d-flex flex-column justify-content-center gap-2 ms-2 mt-3 text-muted">
            {invoice.emailSentWhen !== null ? <div>
                Email Sent {showInstantDateTime( invoice.emailSentWhen, facility.timeZone )}
            </div> : <></>}
        </div>
    </LargePage>
}