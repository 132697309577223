import { useMemo, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { ColDef, GridReadyEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useLoaderData, useNavigate } from "react-router-dom";
import { ApplyPayment, Payment } from "./Data/ApiTypes";
import { showMoney } from "./Data/Money";
import { showPaymentType } from "./Data/Finance";
import { CheckFormModal } from "./FacilityParkerPaymentListPageAddCheckModal";
import { gridOneCol, useParamIds } from "./Data/Common";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { Building, CardText, Crosshair, CurrencyDollar, FileRichtext, FolderFill, Inboxes, Pen, Pencil, Plus, Radar } from "react-bootstrap-icons";
import { colInstantDateTime, DataGrid } from "./Controls/DataGrid";
import { ImportPaymentFormModal } from "./FacilityParkerPaymentListPageAddImportedPaymentModal";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { PaymentType } from "./Data/ApiTransport";

export function FacilityParkerPaymentListPage() {
    const nav = useNavigate();
    const { facilityId, parkerId } = useParamIds();
    const loadedPayments = useLoaderData() as Payment[];

    //state
    const [ showAddCheck,  setShowAddCheck  ] = useState( false );
    const [ showAddImport, setShowAddImport ] = useState( false );
    const [ payments,      setPayments      ] = useState( loadedPayments );

    const desc = ( src: ValueGetterParams<Payment, ApplyPayment[]> ): string => {
        const apps = src.data!.applications;
        if( apps.length === 0 ) { return "Payment Not Applied";       }
        if( apps.length === 1 ) { return `Order #${apps[0].orderId}`; }
        const orders = [...new Set( apps.map( a => a.orderId ) )].map( orderId => `#${orderId}` ).join( ", " );
        return `Orders ${orders}`;
    }

    const paymentColumns = useMemo<ColDef<Payment>[]>( () => [
        { field: 'paymentId',    headerName: 'Payment #', type: 'rightAligned' },
        { ...colInstantDateTime( "createdWhen", x => x.facility.timeZone ), headerName: "Created Date" },
        { field: 'type',         headerName: 'Type',                         valueFormatter: x => showPaymentType( x.value ) },
        {                        headerName: "Orders Affected",              valueGetter: desc },
        { field: 'amount',       headerName: 'Amount',    type: 'rightAligned', valueFormatter: x => showMoney( x.value ) },
        { field: 'remaining',    headerName: 'Remaining', type: 'rightAligned', valueFormatter: x => showMoney( x.value ) },
        { field: 'unapplied',    headerName: 'Unapplied', type: 'rightAligned', valueFormatter: x => showMoney( x.value ) },
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        const pmt: Payment = event.data;
        nav( `/facility/${pmt.facilityId}/parker/${parkerId}/payment/${pmt.paymentId}` );
    };

    return <Container fluid className="h-100">
        <CheckFormModal
            show={showAddCheck}
            setShow={setShowAddCheck}
            facilityId={facilityId}
            parkerId={parkerId}
            onSave={ data => {
                Api.paymentCheckCreate( facilityId, parkerId, data ).then(
                    res => processResult( res,
                        val => setPayments( [...payments, val] ) ),
                        err => console.log( err ) );
            }} />

        <ImportPaymentFormModal
            show={showAddImport}
            setShow={setShowAddImport}
            facilityId={facilityId}
            parkerId={parkerId}
            onSave={ data => {
                Api.paymentImportCreate( facilityId, parkerId, data ).then(
                    res => processResult( res,
                        val => setPayments( [...payments, val] ) ),
                        err => console.log( err )
                );
            }} />

        <Row className="h-100">
            <Col className="h-100" style={gridOneCol( "max-content 1fr 5px" )}>
                <PageBar title="Payments">
                    <ToolButton onClick={ () => setShowAddCheck( true ) }>
                        <Plus size={18} color="red" /> Paper Check
                    </ToolButton>
                    <ToolButton onClick={ () => setShowAddImport( true ) }>
                        <Plus size={18} color="magenta" /> External Payment
                    </ToolButton>
                </PageBar>
                <div style={{ height: "100%", width: '100%' }}>
                    <DataGrid
                        onRowClicked={onRowClicked}
                        rowData={payments}
                        columnDefs={paymentColumns}
                        onGridReady={onGridReady}
                        domLayout="autoHeight"
                    />
                </div>
            </Col>
        </Row>
    </Container>;
}