import { useMemo } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent, ICellRendererParams, RowClassParams } from "ag-grid-community";
import { useParamIds } from "./Data/Common";
import { compareLocalDateTime, DataGrid, filterInstantDateTime } from "./Controls/DataGrid";
import { PageBar } from "./Controls/PageBar";
import { Facility, FacilityPayout, PayoutTransaction, PayoutTransactionPayment } from "./Data/ApiTypes";
import { showInstantDateTime } from "./Data/Date";
import { cents, Money, showMoney } from "./Data/Money";
import { add, equal } from "dinero.js";
import { Check, Clipboard2, Clipboard2Check, Clipboard2Data, Clipboard2XFill, X } from "react-bootstrap-icons";
import { showPaymentType } from "./Data/Finance";
import { InfoIconSmall } from "./Controls/InfoIcon";
import { ClipboardIcon } from "./Controls/ClipboardIcon";

export function FacilityFinancePayoutDetailsPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const payout = useLoaderData() as FacilityPayout;
    const facility = useRouteLoaderData("facilityReport") as Facility;
    const zn = facility.timeZone;

    interface Rec {
        payout:      FacilityPayout;
        transaction: PayoutTransaction;
        payment:     PayoutTransactionPayment | null;
    }
    const filtered = payout.transactions;
    const recs: Rec[] = filtered.map( t => ({ payout: payout, transaction: t, payment: t.payment }) );

    const transactionColumns = useMemo<ColDef<Rec>[]>(() => [
        { field: "transaction.payoutTransactionId", headerName: "#",                                                                                             },
        { field: "transaction.key",                 headerName: "Stripe Key",     hide: true                                                                     },
        { field: "payment.parker.name",             headerName: "Parker",                                                                                        },
        { field: "payment.paymentId",               headerName: "Pmt #",                                                                                         },
        { field: "payment.createdWhen",             headerName: "Pmt Date",                             valueFormatter: x => !!x.value ? showInstantDateTime( x.value, zn ) : "",
            comparator: compareLocalDateTime, filter: "agDateColumnFilter", filterParams: filterInstantDateTime },
        { field: "payment.type",                    headerName: "Pmt Type",                             valueFormatter: x => !!x.value ? showPaymentType( x.value ) : "" },
        {                                           headerName: "Orders",                               valueGetter: x => x.data?.payment?.applications.map( y => y.orderId ).join( ", " )},
      //{ field: "transaction.type",                headerName: "Tran Type",                                                                                     },      
      //{ field: "transaction.createdWhen",         headerName: "Date",                                 valueFormatter: x => showInstantDateTime( x.value, zn )  },
        { field: "transaction.gross",               headerName: "Gross",          type: "rightAligned", valueFormatter: x => x.value ? showMoney( x.value ) : "" },
        { field: "transaction.fee",                 headerName: "Fee",            type: "rightAligned", valueFormatter: x => x.value ? showMoney( x.value ) : "" },
        { field: "transaction.net",                 headerName: "Net",            type: "rightAligned", valueFormatter: x => x.value ? showMoney( x.value ) : "" },
      //{ field: "payment.amount",                  headerName: "Amount",         type: "rightAligned", valueFormatter: x => x.value ? showMoney( x.value ) : "" },
    ], [] );

    const onRowClicked = ( event: { data: Rec } ) => {
        const rec = event.data;
        if( rec.payment !== null ) {
            const pmt    = rec.payment;
            const parker = rec.payment?.parker;
            const pmtId  = pmt.parentPaymentId ?? pmt.paymentId;
            nav(`/facility/${facilityId}/parker/${parker.parkerId}/payment/${pmtId}`);
        }
    };

    const onGridReady = ( params: GridReadyEvent ) => { params.api.sizeColumnsToFit(); };

    const zero   = cents( 0 );
    const paySum = recs.filter( r => r.payment !== null )
                       .map<Money>( r => r.payment!.amount )
                       .reduce( ( a, b ) => add( a, b ), zero );

    const missingPmts = payout.transactions.filter( t => t.payment === null ).length;
    const tranGross   = payout.transactions.map( t => t.gross ).reduce( ( a, b ) => add( a, b ), zero );
    const tranFee     = payout.transactions.map( t => t.fee   ).reduce( ( a, b ) => add( a, b ), zero );
    const tranNet     = payout.transactions.map( t => t.net   ).reduce( ( a, b ) => add( a, b ), zero );
    const tranNetSumEqualPayout = equal( tranNet, payout.amount );
    const tranGrossSumEqualPaymentsSum = equal( tranGross, paySum );
    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "max-content max-content 1fr 5px" }}>
                <PageBar title={`Payout ${payout.payoutId}`}>
                    <ClipboardIcon payload={payout.payoutKey} tooltipText="Copied Stripe ID" />
                </PageBar>
                <Row className="mb-2">
                    <Col>
                        <div className="two-col-align-first">
                            <div>Payout Amount</div>
                            <div>
                                {showMoney( payout.amount )}
                            </div>

                            <div>Created</div>
                            <div>{showInstantDateTime( payout.processorCreatedWhen, zn )}</div>

                            <div>Est. Arrival</div>
                            <div>{showInstantDateTime( payout.arrivalWhen, zn )}</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="three-col-compact">
                            <div className="d-flex-align-center">
                                Data is Complete?
                            </div>
                            <div className="d-flex-align-center">
                                <InfoIconSmall>This check ensures there are no missing records</InfoIconSmall>
                            </div>
                            <div className="d-flex-align-center">
                                {missingPmts === 0 ? <Check className="fs-4 text-success" /> : <X className="fs-4 text-danger" />}
                            </div>

                            <div className="d-flex-align-center">
                                Tran. Net = Payout
                            </div>
                            <div className="d-flex-align-center">
                                <InfoIconSmall>This check ensures that the sum of the net of all transactions in this payout is equal to the payout amount</InfoIconSmall>
                            </div>
                            <div>
                                {tranNetSumEqualPayout ? <Check className="fs-4 text-success" /> : <X className="fs-4 text-danger" />}
                            </div>

                            <div className="d-flex-align-center">
                                Tran. Gross = Payment Sum
                            </div>
                            <div className="d-flex-align-center">
                                <InfoIconSmall>This check ensures that the sum of the gross of all transactions in this payout equals the sum of all payments in this payout</InfoIconSmall>
                            </div>
                            <div className="text-end">
                                {tranGrossSumEqualPaymentsSum ? <Check className="fs-4 text-success" /> : <X className="fs-4 text-danger" />}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="three-col-compact">
                            <div>Gross/Revenue</div>
                            <div className="text-end">{showMoney( paySum )}</div>
                            <div></div>

                            <div>Fee</div>
                            <div className="text-end">{showMoney( tranFee )}</div>
                            <div></div>

                            <div>Net</div>
                            <div className="text-end">{showMoney( tranNet )}</div>
                            <div></div>
                        </div>
                    </Col>
                </Row>
                <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid
                        exportAllColumns
                        rowData={recs}
                        columnDefs={transactionColumns}
                        onGridReady={onGridReady}
                        onRowClicked={onRowClicked}            
                        getRowStyle={( x: RowClassParams<Rec> ) => {
                                                        return x.data!.transaction.payoutTransactionId % 2 === 0 ? {
                                                            borderBottom: "1px solid var(--bs-success)",
                                                            backgroundColor: "var(--color-lighter)"
                                                        } : {
                                                            borderBottom: "1px solid var(--bs-success)",
                                                            backgroundColor: "inherit"
                                                        };
                                                    }}
                    />
                </div>
            </Col>
        </Row>
    </Container>;
}