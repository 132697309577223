import { AllCommunityModule, GridReadyEvent, ICellRendererParams, IDateFilterParams, ModuleRegistry, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { ColDef, ColDefField, themeQuartz } from "ag-grid-community";
import { cents, Money, showMoney } from "../Data/Money";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";
import { DateTimeFmt, toZonedDateTime } from "../Data/Date";
import { compare, equal } from "dinero.js";
import { CheckCircleFill, Download, Search, XCircleFill } from "react-bootstrap-icons";
import { Card, Form, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Instant, LocalDate, LocalDateTime, nativeJs, ZoneId } from "@js-joda/core";
import { EnumType } from "typescript";

ModuleRegistry.registerModules( [AllCommunityModule] );

type ShowFunc<TEnum> = ( value: TEnum ) => string;

const fmt = <TData, TValue,>( params: ValueFormatterParams<TData, TValue>, field: ColDefField<TData, TValue> ) => {
    const data = params.data!;
    const key  = field as unknown as keyof TData;
    return data[key] as TValue;
}

export function colText<T>( field: ColDefField<T, string | null> ): ColDef<T> {
    return {
        field:      field,
        comparator: compareString
    };
}

export function colNumber<T>( field: ColDefField<T, number> ): ColDef<T> {
    return {
        field:      field,
        type:       "rightAligned",
        comparator: compareNumber
    };
}

export function colEnum<TData, TEnum extends number>( field: ColDefField<TData, TEnum>, showFunc: ShowFunc<TEnum> ): ColDef<TData, TEnum> {
    return {
        field:          field,
        valueFormatter: x => showFunc( fmt( x, field ) ),
        type:           "rightAligned",
        comparator:     compareEnum
    };
}

export function colMoney<T>( field: ColDefField<T, Money> ): ColDef<T> {
    return {
        field:          field,
        valueFormatter: x => showMoney( x.value ),
        type:           "rightAligned",
        comparator:     compareMoney
    };
}

// const get = <TData, TValue, TParams extends ValueGetterParams<TData, TValue>, TColDef extends ColDefField<TData, TValue>>
//     ( params: TParams, field: TColDef ) => {
//         const data = params.data!;
//         const key  = field as unknown as keyof TData;
//         return data[key];
//     }

const get = <T,>( x: ValueGetterParams<any, any>, field: ColDefField<any, any> ): any => {
    return x.data![field as string] as T;
} 

export function colInstantDate<T>( field: ColDefField<T, Instant>, func: ( arg: T ) => string ): ColDef<T> {    
    return {
        valueGetter: params => {
            const zone = ZoneId.of( func( params.data! ) );
            return toZonedDateTime( get<Instant>( params, field ), zone ).toLocalDate();
        },
        valueFormatter: ( params ) => {            
            return DateTimeFmt.day.format( ( params.value as LocalDate ) );
        },
        filter:         "agDateColumnFilter",
        comparator:     compareLocalDate,
        filterParams:   filterInstantDate
    }
}

export function colInstantDateTime<T>( field: ColDefField<T, Instant>, func: ( arg: T ) => string ): ColDef<T> {
    return {
        valueGetter: ( params: ValueGetterParams<T, Instant> ): LocalDateTime => {
            const zone = ZoneId.of( func( params.data! ) );
            return toZonedDateTime( get<Instant>( params, field ), zone ).toLocalDateTime();
        },
        valueFormatter: ( params: ValueFormatterParams<T, LocalDateTime> ) => {
            const dateTime = params.value as LocalDateTime;
            return DateTimeFmt.sec.format( dateTime );
        },
        comparator:   compareLocalDateTime,
        filter:       "agDateColumnFilter",
        filterParams: filterInstantDateTime
    };
}
    
export const compareString        = ( a: string,        b: string        ) => a.localeCompare( b );
export const compareEnum          = <T,>( a: T,         b: T             ) => Math.sign( (a as number) - (b as number) );
export const compareNumber        = ( a: number,        b: number        ) => Math.sign( a - b );
export const compareInstant       = ( a: Instant,       b: Instant       ) => a.compareTo( b );
export const compareLocalDate     = ( a: LocalDate,     b: LocalDate     ) => a.compareTo( b );
export const compareLocalDateTime = ( a: LocalDateTime, b: LocalDateTime ) => a.compareTo( b );
export const compareMoney         = ( a: Money,         b: Money         ) => compare( a, b );

export const filterInstantDate: IDateFilterParams = {
    comparator: ( filterLocalDateAtMidnight: Date, cellValue: LocalDate ) => {
        const val = cellValue;
        const filterDate = nativeJs( filterLocalDateAtMidnight ).toLocalDate();
        return val.compareTo( filterDate );
    }
}

export const filterInstantDateTime: IDateFilterParams = {
    comparator: ( filterLocalDateAtMidnight: Date, cellValue: LocalDateTime ) => {
        const val = cellValue;
        const filterDate = nativeJs( filterLocalDateAtMidnight ).toLocalDate();
        return val.toLocalDate().compareTo( filterDate );
    }
}

export function BalanceRenderer( params: ICellRendererParams<any, Money> ) {
    if( !params.value ) {
        return <></>;
    }
    const paid = equal( params.value!, cents( 0 ) );
    return <div className={`d-flex align-items-center justify-content-end gap-1 ${paid ? "text-black" : "text-danger"}`}>
        <div>
            {showMoney( params.value! )}
        </div>
    </div>;
}

export function BalanceStatusRenderer( params: ICellRendererParams<any, Money> ) {
    if( !params.value ) {
        return <></>;
    }
    const paid = equal( params.value!, cents( 0 ) );
    const size    = 16;
    const textCls = paid ? "text-black" : "text-danger";
    return <div className="d-flex align-items-center justify-content-start gap-1">
        {paid ? <CheckCircleFill color="green" size={size} className={textCls}  />
              : <XCircleFill size={size} className={textCls} /> }
        <div className={textCls}>
            {paid ? 'Paid' : 'Unpaid'}
        </div>
    </div>;
}

export const gridTheme = themeQuartz.withParams( {
    fontFamily:          "silka",
    headerFontFamily:    "silka",
    cellFontFamily:      "silka",
    wrapperBorderRadius: "0px 0px 8px 8px",
    wrapperBorder:       false
} );

export interface DataGridProps extends AgGridReactProps {
    exportAllColumns?: boolean;
}

export const xsCol  = ``;
export const smCol  = `d-none d-sm-block`;
export const mdCol  = `d-none d-md-block`;
export const lgCol  = `d-none d-lg-block`;
export const xlCol  = `d-none d-xl-block`;
export const xxlCol = `d-none d-xxl-block`;

export interface GridForm {
    searchText: string;
}

export function DataGrid( props: DataGridProps ) {
    const { exportAllColumns = false } = props;
    const gridRef = useRef<AgGridReact>( null );
    const { register, watch } = useForm<GridForm>( { defaultValues: { searchText: "" } } );

    const defaultColDef = useMemo<ColDef>( () => ( {
        sortable: true,
        filter:   true,
    } ), [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const handleExport = useCallback( () => {
        gridRef.current!.api.exportDataAsCsv( { allColumns: exportAllColumns } );
    }, [] );

    const searchText = watch( "searchText" );
    const exportTooltip = <Tooltip>Export as CSV</Tooltip>
    return <div className="h-100 border" style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
        <Card className="rounded-0 border-0">
            <Card.Header className="border-bottom ps-3 pe-3">
                <div className="d-flex align-items-center gap-3">
                    <div className="d-flex-align-center flex-grow-1 gap-3">
                        <Search /> <Form.Control {...register( "searchText" )} size="sm" className="mx-0" />
                    </div>
                    <Download style={{ cursor: "pointer" }} onClick={handleExport} />
                </div>
            </Card.Header>
        </Card>
        <AgGridReact
            ref={gridRef}
            quickFilterText={searchText}            
            containerStyle={{ borderTop: 0 }}
            defaultColDef={defaultColDef}
            animateRows={false}
            theme={gridTheme}
            onGridReady={onGridReady}
            {...props} />
    </div>
}

