
import { showInBetweenOutBetween, showStartEndLocalTime } from "../Data/Date";
import { notSet } from "../Data/Api";
import { RateProgram } from "./ApiTypes";

export function rateProgramType( prog?: RateProgram ) {
    if( !prog ) {
        return "";
    }
    if( notSet( prog.inAfter )
     && notSet( prog.inBefore )
     && notSet( prog.outAfter )
     && notSet( prog.outBefore ) ) {
        return "Any time";
    }
    if( ( prog.inAfter && prog.outBefore && notSet( prog.inBefore ) && notSet( prog.outAfter ) ) ) {
        return `From ${showStartEndLocalTime( prog.inAfter, prog.outBefore )} `
    }
    if( prog.inAfter && prog.inBefore && prog.outAfter && prog.outBefore ) {
        var { inRange, outRange } = showInBetweenOutBetween( prog.inAfter, prog.inBefore, prog.outAfter, prog.outBefore );
        return <div className="d-flex justify-content-center">
            <table cellPadding={3}>
                <thead>
                    <tr>
                        <td className="text-start">
                            Check-In
                        </td>
                        <td>
                            {inRange}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-start">
                            Check-Out
                        </td>
                        <td>
                            {outRange}
                        </td>
                    </tr>
                </thead>
            </table>
        </div>;
    }
}