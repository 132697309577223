import { Col, Container, Row } from "react-bootstrap";
import { ArrowUpCircle, BarChart, Calendar, Calendar2Day, Calendar2Heart, CalendarDate, CalendarRange, CarFront, GearWideConnected, House, IconProps, ListCheck, PCircle, PCircleFill, People, PersonBadge, Shield, ShieldExclamation, Thermometer, Ticket } from "react-bootstrap-icons";
import { NavLink, Outlet, useLocation, useRouteLoaderData } from "react-router-dom";
import { ExtendedFacility } from ".";
import { PageTitle } from "./Controls/PageTitle";
import { MenuItem } from "./Controls/MenuItem";

interface MenuHeaderProps {    
    text: string;    
}

export function MenuHeader( props: MenuHeaderProps ) {        
    return <div className={`facility-menu-header`}>        
        <div className="fs-4 d-flex align-items-center justify-content-center gap-1 grid-col-span-5 text-center align-self-center">
            <PageTitle className="fw-normal text-truncate">
                Finance
            </PageTitle>
        </div>
    </div>;
}

export function AppFacility() {
    const loc        = useLocation();
    const facility   = useRouteLoaderData("facility") as ExtendedFacility;
    const facilityId = facility.facilityId;

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/facility/${facilityId}` ) ? ["", "d-none"] : ["d-none", ""];
    const activeOverride = loc.pathname === ( `/facility/${facilityId}` ) ? true : false;
    return <Container fluid className="fullheight pt-2">
            <Row className="fullheight">
                <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight scroll-y-auto border-end`}>
                    <MenuItem active={activeOverride} default icon={<House />} to="./overview" text="Overview" />                    
                    <MenuItem icon={<ListCheck      />} to="./listing"      text="Listing"   />
                    <MenuItem icon={<PCircle        />} to="./status"       text="Status"    />                    
                    <MenuItem icon={<People         />} to="./parker"       text="Monthly"   />                    
                    <MenuItem icon={<Calendar2Heart />} to="./plan"         text="Plans"     />
                    <MenuItem icon={<Ticket         />} to="./rate"         text="Rates"     />
                    <MenuItem icon={<BarChart       />} to="./finance"      text="Finance"   />                    
                    <MenuItem icon={<PersonBadge    />} to="./key"          text="Access"    />
                </Col>
                <Col md={7} lg={7} xl={8} xxl={9}
                     className={`${contentClassName} p-0 m-0 d-md-grid h-100 scroll-y-auto`}
                     style={{ gridTemplateRows: "1fr" }}>
                    <div className="d-md-none w-100 p-3 d-flex justify-content-center d-print-none">
                        <NavLink to="" className="d-flex align-items-center gap-1">
                            <ArrowUpCircle />Return to Facility Menu
                        </NavLink>
                    </div>
                    <Outlet />
                </Col>
            </Row>
    </Container>;
}