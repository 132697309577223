import {
    emitAccessKeyAssignSub,
    emitAccessKeyParkerAssignReq,
    emitAccessKeyReq,
    emitAccessKeyVehicleSubAssignReq,
    emitChangeEmailReq,
    emitChangePasswordReq,
    emitCheckCreateReq,
    emitFacilityCreateReq,
    emitFacilityUpdateReq,
    emitForgotPasswordReq,
    emitImportPaymentReq,
    emitInviteParkerReq,
    emitInviteStaffReq,
    emitLogInReq,
    emitOrderAdjustReq,
    emitOrderCustomReq,
    emitPaymentAdjustReq,
    emitPlanCreateReq,
    emitPlanInvoiceReq,
    emitPlanPriceAddReq,
    emitPlanUpdateReq,
    emitRateProgramData,
    emitResetCodeCheckReq,
    emitResetPasswordReq,
    emitStaffUpdateReq,
    emitSubscriptionUpdateReq
} from "./ApiEmit";
import {
    parseAccessKey,
    parseBalanceChange,
    parseFacility,
    parseFacilityInvoice,
    parseFacilityPayment,
    parseFacilityPayout,
    parseFacilityStats,
    parseFacilitySub,
    parseInvoice,
    parseOperator,
    parseOrder,
    parseOrderBalance,
    parseOrderDebt,
    parseOrderDetails,
    parseParkerAgingRecord,
    parseParkerDetails,
    parseParkerInfo,
    parsePayment,
    parsePaymentChange,
    parsePlan,
    parsePlanDetails,
    parsePlanInvoiceResp,
    parsePlanPriceListResp,
    parseRateProgram,
    parseSearchResult,
    parseSession,
    parseStaff,
    parseStripeLinkResp,
    parseSubEntry,
    parseSubEntryForEdit,
    parseSubscription,
    parseVehicleDetails,
    parseVehicleLinkResp
} from "./ApiParse";
import {
    StaffApi,
    SubscriptionParkerApi,
    SubscriptionParkerChangeReqApi,
    SubscriptionParkerInviteReqApi,
    SubscriptionParkerRemoveReqApi,
    VehicleAddReqApi,
    VehicleDeleteReqApi,
} from "./ApiTransport";
import {
    AccessKeyAssignSub,
    AccessKeyParkerAssignReq,
    AccessKeyReq,
    AccessKeyVehicleSubAssignReq,
    CheckCreateReq,
    FacilityCreateReq,
    FacilityUpdateReq,
    ImportPaymentReq,
    InviteParkerReq,
    InviteStaffReq,
    OrderAdjustReq,
    OrderCustomReq,
    ParkerUpdateReq,
    PaymentAdjustReq,
    PlanCreateReq,
    PlanInvoiceReq,
    PlanPriceAddReq,
    PlanUpdateReq,
    RateProgramData,
    ResetCodeCheckReq,
    ResetPasswordReq,
    StaffUpdateReq,
    SubscriptionUpdateReq
} from "./ApiTypes";
import { ApiUrl } from "./ApiUrl";
import {
    OkResult,
    Result
} from "./Result";

export const notSet = ( a: any ) => a === null;

export interface LogInReq {
    emailAddress: string;
    password:     string;
}

export interface SignUpReq {
    emailAddress:    string;
    name:            string;
    password:        string;
    passwordConfirm: string;
}

export interface ChgPassReq {
    currentPassword: string;
    newPassword:     string;
}

export interface ChgEmailAddressReq {
    currentPassword: string;
    newEmailAddress: string;
}

export interface ConnectInitResp {
    clientSecret: string;
}

export type LogInResp          = StaffApi;
export type ChgPassResp        = StaffApi;
export type ChgEmailResp       = StaffApi;
export type ResetPassResp      = StaffApi;
export type ResetCodeCheckResp = StaffApi;

export interface ForgotPassReq  { emailAddress: string; }
export type ForgotPassResp = string;

export const showRole = ( r: number ) => {
    if( r === 0 ) { return "Site Admin"; }
    if( r === 1 ) { return "Operator Admin"; }
    return "Err!";
}

const arr = <TValApi, TVal>( func: ( api: TValApi ) => TVal ) => ( x: TValApi[] ) => x.map( func );

const get = (): RequestInit => {
    return {
        method:      "GET",
        credentials: "include"
    }
}

const json = ( verb: string, payload: any ): RequestInit => {
    return {
        method:      verb,
        headers:   { "Content-Type": "application/json" },
        body:        JSON.stringify( payload ),
        credentials: "include"
    }
}

const post = ( payload: any ) => json( "POST",   payload );
const resOk    = <T>( value: T ): OkResult<T>          => ( { isOk: true, value: value } )
const ok       = <T>( value: T ): Promise<OkResult<T>> => Promise.resolve( resOk( value ) );
const okDelay = <TErr, TVal>( value: TVal ): Promise<Result<TErr, TVal>> =>
    new Promise( ( resolve ) => setTimeout( () => { resolve( resOk( value ) ); }, 500 )
);

export const api = <TVal>( url: string, init: RequestInit = {} ): Promise<Result<string, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json   = response.json();
            const isOk = (response.status == 200);
            return json.then( data => {
                if( isOk ) {
                    return { isOk: true, value: data };
                }
                return { isOk: false, error: data }
            } );
        } );
}

const api2 = <TValApi, TVal>( url: string, init: RequestInit = {}, f: (x: TValApi) => TVal ): Promise<Result<string, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json = response.json();
            const isOk = (response.status == 200);
            return json.then( data => {
                if( isOk ) {
                    const parsed = f( data );
                    return { isOk: true, value: parsed };
                }
                return { isOk: false, error: data }
            } );
        } );
}

export function identity<T>( arg: T ) { return arg; }
export function unknown<T>(  arg: T ) { return undefined; }

function parseString( x: string ): string { return x };

export class Api {    

    //auth stuff    
    static amILoggedIn         = (                           ) => api2( ApiUrl.amILoggedIn(),         get(                               ), parseStaff  );
    static logIn               = ( req: LogInReq             ) => api2( ApiUrl.logIn(),               post( emitLogInReq( req )          ), parseStaff  );
    static logOut              = (                           ) => api2( ApiUrl.logOut(),              post( {}                           ), parseStaff  );
    static forgotPassword      = ( req: ForgotPassReq        ) => api2( ApiUrl.forgotPassword(),      post( emitForgotPasswordReq( req ) ), parseString );
    static resetCodeCheck      = ( req: ResetCodeCheckReq    ) => api2( ApiUrl.resetCodeCheck(),      post( emitResetCodeCheckReq( req ) ), parseStaff  );
    static resetPassword       = ( req: ResetPasswordReq     ) => api2( ApiUrl.resetPassword(),       post( emitResetPasswordReq ( req ) ), parseStaff  );
    static changeEmailAddress  = ( req: ChgEmailAddressReq   ) => api2( ApiUrl.changeEmailAddress(),  post( emitChangeEmailReq   ( req ) ), parseStaff  );
    static changePassword      = ( req: ChgPassReq           ) => api2( ApiUrl.changePassword(),      post( emitChangePasswordReq( req ) ), parseStaff  );

    //staff
    static staffInvite  = ( req: InviteStaffReq                  ) => api2( ApiUrl.staffInvite(),           post( emitInviteStaffReq( req ) ), parseStaff        );
    static staffList    = (                                      ) => api2( ApiUrl.staffList(),             get(),                             arr( parseStaff ) );
    static staffGet     = ( staffId: number                      ) => api2( ApiUrl.staffGet( staffId ),     get(),                             parseStaff        );
    static staffUpdate  = ( staffId: number, req: StaffUpdateReq ) => api2( ApiUrl.staffUpdate( staffId ),  post( emitStaffUpdateReq( req ) ), parseStaff        );
    static staffDisable = ( staffId: number                      ) => api2( ApiUrl.staffDisable( staffId ), post( {} ),                        parseStaff        );
    static staffEnable  = ( staffId: number                      ) => api2( ApiUrl.staffEnable( staffId ),  post( {} ),                        parseStaff        );
 
    //facilities 
    static facilityCreate      = ( req: FacilityCreateReq    ) => api2( ApiUrl.facilityCreate(                 ), post(  emitFacilityCreateReq( req ) ), parseString );
    static facilityUpdate      = ( req: FacilityUpdateReq    ) => api2( ApiUrl.facilityUpdate( req.facilityId  ), post(  emitFacilityUpdateReq( req ) ), parseString );
    static facilityList        = (                           ) => api2( ApiUrl.facilityList(                   ), get(), arr( parseFacility ) );
    static facilityDetails     = ( facilityId: number        ) => api2( ApiUrl.facilityDetails( facilityId     ), get(), parseFacility        );
    static facilityStatsGet    = ( facilityId: number        ) => api2( ApiUrl.facilityStatsGet( facilityId    ), get(), parseFacilityStats   );
    static facilityPaymentList = ( facilityId: number        ) => api2( ApiUrl.facilityPaymentList( facilityId ), get(), arr( parseFacilityPayment ) );
    
    static facilitySearch = ( facilityId: number, query: string ) =>
        api2( ApiUrl.facilitySearch( facilityId, query ), get(), arr( parseSearchResult ) );

    static facilityParkerList = ( facilityId: number ) =>
        api2( ApiUrl.facilityParkerList( facilityId ), get(), arr( parseParkerDetails ) );

    static facilitySessionList = ( facilityId: number ) =>
        api2( ApiUrl.facilitySessionList( facilityId ), get(), arr( parseSession ) );

    static facilitySubscriptionList = ( facilityId: number ) =>
        api2( ApiUrl.facilitySubscriptionList( facilityId ), get(), arr( parseFacilitySub ) );

    static facilityParkerDetails = ( facilityId: number, parkerId: number ) =>
        api2( ApiUrl.facilityParkerDetails( facilityId, parkerId ), get(), parseParkerDetails );

    static facilityParkerVehicleList = ( facilityId: number, parkerId: number ) =>
        api2( ApiUrl.facilityParkerVehicleList( facilityId, parkerId ), get(), arr( parseVehicleDetails ) );

    static facilityCustomOrderCreate = ( facId: number, req: OrderCustomReq ) =>
        api2( ApiUrl.facilityCustomOrderCreate( facId ), post( emitOrderCustomReq( req ) ), parseOrder );

    static facilityParkerInvite = ( facilityId: number, req: InviteParkerReq ) =>
        api2<string, string>( ApiUrl.facilityParkerInvite( facilityId ), post( emitInviteParkerReq( req ) ), x => x );

    static facilitySubscriptionGet = ( facilityId: number, subscriptionId: number ) =>
        api2( ApiUrl.facilitySubscriptionGet( facilityId, subscriptionId ), get(), parseSubscription );

    static facilitySubscriptionEditGet = ( facilityId: number, subscriptionId: number ) =>
        api2( ApiUrl.facilitySubscriptionEditGet( facilityId, subscriptionId ), get(), parseSubEntryForEdit );    

    static facilitySubscriptionEditUpdate = ( facilityId: number, subscriptionId: number, req: SubscriptionUpdateReq ) =>
        api2( ApiUrl.facilitySubscriptionEditUpdate( facilityId, subscriptionId ), post( emitSubscriptionUpdateReq( req ) ), parseSubEntry );

    //facility big lists
    static facilityInvoiceList = ( facilityId: number ) =>
        api2( ApiUrl.facilityInvoiceList( facilityId ), get(), arr( parseFacilityInvoice ) );

    //plans
    static facilityPlanCreate = ( facilityId: number, req: PlanCreateReq ) => 
        api2( ApiUrl.facilityPlanCreate( facilityId ), post( emitPlanCreateReq( req ) ), parsePlan );

    static facilityPlanList = ( id: number ) =>
        api2( ApiUrl.facilityPlanList( id ), get(), arr( parsePlan ) );

    static facilityPlanGet = ( facilityId: number, planId: number ) =>
        api2( ApiUrl.facilityPlanGet( facilityId, planId ), get(), parsePlanDetails );

    static facilityPlanUpdate = ( facilityId: number, planId: number, req: PlanUpdateReq ) =>
        api2( ApiUrl.facilityPlanUpdate( facilityId, planId ), post( emitPlanUpdateReq( req ) ), parsePlan );

    //plan prices
    static facilityPlanPriceAdd = ( facilityId: number, planId: number, req: PlanPriceAddReq ) =>
        api2( ApiUrl.facilityPlanPriceCreate(facilityId, planId), post( emitPlanPriceAddReq( req ) ), parsePlanPriceListResp );

    static facilityPlanPriceList = ( facilityId: number, planId: number ) =>
        api2( ApiUrl.facilityPlanPriceList( facilityId, planId ), get(), arr( parsePlanPriceListResp ) );

    static facilityPlanInvoiceInit = ( facilityId: number, planId: number, req: PlanInvoiceReq ) =>
        api2( ApiUrl.facilityPlanInvoiceInit( facilityId, planId ), post( emitPlanInvoiceReq( req ) ), arr( parsePlanInvoiceResp ) );

    static facilityPlanInvoiceConfirm = ( facilityId: number, planId: number, req: PlanInvoiceReq ) =>
        api2( ApiUrl.facilityPlanInvoiceConfirm( facilityId, planId ), post( emitPlanInvoiceReq( req ) ), arr( parsePlanInvoiceResp ) );

    //rates
    static facilityRateList   = ( facilityId: number )                                              => api2(ApiUrl.facilityRateList(facilityId),                   get(), arr( parseRateProgram ) );
    static facilityRateGet    = ( facilityId: number, rateProgramId: number )                       => api2(ApiUrl.facilityRateGet( facilityId, rateProgramId ),   get(), parseRateProgram );
    static facilityRateCreate = ( facilityId: number,                        req: RateProgramData ) => api2( ApiUrl.facilityRateCreate(facilityId),                post( emitRateProgramData( req ) ), parseRateProgram );
    static facilityRateUpdate = ( facilityId: number, rateProgramId: number, req: RateProgramData ) => api2( ApiUrl.facilityRateUpdate(facilityId, rateProgramId), post( emitRateProgramData( req ) ), parseRateProgram );

    //parker
    static parkerUpdate( id: number, req: ParkerUpdateReq ) { return api2( ApiUrl.parkerUpdate( id ), post( req ), parseParkerInfo ); }

    //stripe
    static stripeInit( facilityId: number ) { return api2<ConnectInitResp, ConnectInitResp>(   ApiUrl.stripeInit( facilityId ), post( {} ), identity ); }
    static stripeLink( facilityId: number ) { return api2( ApiUrl.stripeLink( facilityId ), get(), parseStripeLinkResp ); }

    //operator
    static opDetails() { return api2( ApiUrl.opDetails(), get(), parseOperator ); }

    //finance
    static orderList      = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerOrderList(     facilityId, parkerId ), get(),         arr( parseOrder     ) );
    static orderOpenList  = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerOrderOpenList( facilityId, parkerId ), get(),         arr( parseOrderDebt ) );
    static orderDetails   = ( facilityId: number, parkerId: number, orderId:   number ) => api2( ApiUrl.facilityParkerOrderDetails( facilityId, parkerId, orderId ), get(), parseOrderDetails     );

    static invoiceList    = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerInvoiceList(   facilityId, parkerId ), get(),             arr( parseInvoice ) );
    static invoiceDetails = ( facilityId: number, parkerId: number, invoiceId: number ) => api2( ApiUrl.facilityParkerInvoiceDetails( facilityId, parkerId, invoiceId ), get(), parseInvoice        );

    static balanceHistory = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerBalanceHistory( facilityId, parkerId ), get(),     arr( parseBalanceChange ) );
    static paymentList    = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerPaymentList( facilityId, parkerId ),    get(),     arr( parsePayment ) );
    static paymentDetails = ( facilityId: number, parkerId: number, paymentId: number ) => api2( ApiUrl.facilityParkerPaymentDetails( facilityId, parkerId, paymentId ), get(), parsePayment );

    static facilityPayoutList    = ( facilityId: number                   ) => api2( ApiUrl.facilityPayoutList( facilityId              ), get(), arr( parseFacilityPayout ) );
    static facilityPayoutDetails = ( facilityId: number, payoutId: number ) => api2( ApiUrl.facilityPayoutDetails( facilityId, payoutId ), get(),      parseFacilityPayout );
    
    //access keys
    static facilityAccessKeyList = ( facilityId: number ) =>
        api2( ApiUrl.facilityAccessKeyList( facilityId ), get(), arr( parseAccessKey ) );

    static facilityAccessKeyGet = ( facilityId: number, accessKeyId: number ) =>
        api2( ApiUrl.facilityAccessKeyGet( facilityId, accessKeyId ), get(), parseAccessKey );

    static facilityAccessKeyCreate = ( facilityId: number, req: AccessKeyReq ) =>
        api2( ApiUrl.facilityAccessKeyCreate( facilityId ), post( emitAccessKeyReq( req ) ), parseAccessKey );

    static facilityAccessKeyUpdate = ( facilityId: number, accessKeyId: number, req: AccessKeyReq ) =>
        api2( ApiUrl.facilityAccessKeyUpdate( facilityId, accessKeyId ), post( emitAccessKeyReq( req ) ), parseAccessKey );

    static facilityAccessKeyAssignSubscription = ( facilityId: number, accessKeyId: number, req: AccessKeyAssignSub ) =>
        api2( ApiUrl.facilityAccessKeyAssignSubscription( facilityId, accessKeyId ), post( emitAccessKeyAssignSub( req ) ), parseAccessKey );

    static facilityAccessKeyAssignParker = ( facilityId: number, accessKeyId: number, req: AccessKeyParkerAssignReq ) =>
        api2( ApiUrl.facilityAccessKeyAssignParker( facilityId, accessKeyId ), post( emitAccessKeyParkerAssignReq( req ) ), parseAccessKey );

    static facilityAccessKeyAssignVehicle = ( facilityId: number, accessKeyId: number, req: AccessKeyVehicleSubAssignReq ) =>
        api2( ApiUrl.facilityAccessKeyAssignVehicle( facilityId, accessKeyId ), post( emitAccessKeyVehicleSubAssignReq( req ) ), parseAccessKey );

    static facilityAccessKeyRemove = ( facilityId: number, accessKeyId: number, accessKeyLinkId: number ) =>
        api2( ApiUrl.facilityAccessKeyRemove( facilityId, accessKeyId, accessKeyLinkId ), post( {} ), parseString );

    static facilityAccessKeyLinkRemove = ( facilityId: number, accessKeyId: number ) =>
        api2( ApiUrl.facilityAccessKeyLinkRemove( facilityId, accessKeyId ), post( {} ), parseAccessKey );
        
    static paymentAdjust = ( facilityId: number, parkerId: number, req: PaymentAdjustReq ) => 
        api2( ApiUrl.facilityParkerPaymentAdjust( facilityId, parkerId ), post( emitPaymentAdjustReq( req ) ), parsePaymentChange );

    static orderAdjust = ( facilityId: number, parkerId: number, req: OrderAdjustReq ) => 
        api2( ApiUrl.facilityParkerPaymentAdjust( facilityId, parkerId ), post( emitOrderAdjustReq( req ) ), parseOrderBalance );

    static paymentCheckCreate = ( facilityId: number, parkerId: number, req: CheckCreateReq ) =>
        api2( ApiUrl.facilityParkerCheckCreate( facilityId, parkerId ), post( emitCheckCreateReq( req ) ), parsePayment );
    
    static paymentImportCreate = ( facilityId: number, parkerId: number, req: ImportPaymentReq ) =>
        api2( ApiUrl.facilityParkerImportPaymentCreate( facilityId, parkerId ), post( emitImportPaymentReq( req ) ), parsePayment );

    //sub-parker management
    static subParkerInvite( facId: number, subscriptionId: number, req: SubscriptionParkerInviteReqApi )
        { return api<SubscriptionParkerApi>( ApiUrl.subParkerCreate( facId, subscriptionId ), post( req ) ); }

    static subParkerChange( facId: number, subscriptionId: number, req: SubscriptionParkerChangeReqApi )
        { return api<boolean>( ApiUrl.subParkerUpdate( facId, subscriptionId ), post( req ) ); }

    static subParkerRemove( facId: number, subscriptionId: number, req: SubscriptionParkerRemoveReqApi )
        { return api<boolean>( ApiUrl.subParkerDelete( facId, subscriptionId ), post( req ) ); }

    //subscription vehicle management
    static subscriptionVehicleAdd( facId: number, subscriptionId: number, req: VehicleAddReqApi )
        { return api2( ApiUrl.subscriptionVehicleAdd( facId, subscriptionId ), post( req ), parseVehicleLinkResp ); }

    static subscriptionVehicleDelete( facId: number, subscriptionId: number, vehicleId: number, req: VehicleDeleteReqApi )
        { return api( ApiUrl.subscriptionVehicleDelete( facId, subscriptionId, vehicleId), post(req) ); }

    //reports
    static facilityAgingReport( facilityId: number ) { return api2( ApiUrl.facilityReportAging( facilityId ), get(), arr( parseParkerAgingRecord ) ); }
}