import { connect } from "http2";

const detectServ = ( url: string ) => {
    const loc = url;
    if( loc.includes( "localhost" ) || loc.includes( "local.dev" ) ) {
        return "https://local.dev:7063/monitor";
    }
    //else we are on a dev server
    if( loc.includes( "monitor.dev.spotsync.com" ) ) {
        return 'https://api.dev.spotsync.com/monitor';
    }
    //we are live
    return 'https://api.spotsync.com/monitor';
}

//must include / in tail
export const connectUrl = ( tail: string ) => {
    const loc = window.location.href;
    if( loc.includes( "localhost" ) || loc.includes( "local.dev" ) ) {
        return `https://local.dev:3000${tail}`;
    }
    //else we are on a dev server
    if( loc.includes( "monitor.dev.spotsync.com" ) ) {
        return `https://connect.dev.spotsync.com${tail}`;
    }
    const connectUrl = window.location.origin.replace( ".monitor", "" );        
    return `${connectUrl}${tail}`;
}

const serv   = detectServ( window.location.href );
const url    = ( tail: string ) => { return `${serv}${tail}`; }
const facUrl = ( tail: string ) => url( `/facility${tail}` );
const encode = ( text: string ) => encodeURIComponent( text );

export class ApiUrl {

    //operator
    static opDetails() { return url( `/` ); }

    //auth
    static amILoggedIn()                              { return url( `/auth/`                             ); } //GET
    static logIn()                                    { return url( `/auth/login`                        ); } //POST
    static logOut()                                   { return url( `/auth/logout`                       ); } //POST
    static forgotPassword()                           { return url( `/auth/forgot`                       ); } //POST
    static resetCodeCheck()                           { return url( `/auth/check`                        ); } //POST
    static resetPassword()                            { return url( `/auth/reset`                        ); } //POST
    static changeEmailAddress()                       { return url( `/auth/email`                        ); } //POST
    static changePassword()                           { return url( `/auth/password`                     ); } //POST
    static deleteAccount()                            { return url( `/auth/delete`                       ); } //GET (retrieves a random secret)
    static deleteAccountConfirm()                     { return url( `/auth/delete-confirm`               ); } //POST

    //staff
    static staffList()                                { return url( `/staff/`                            ); } //GET
    static staffGet(     id: number )                 { return url( `/staff/${id}`                       ); } //GET
    static staffUpdate(  id: number )                 { return url( `/staff/${id}`                       ); } //POST
    static staffDisable( id: number )                 { return url( `/staff/${id}/disable`               ); } //POST
    static staffEnable(  id: number )                 { return url( `/staff/${id}/enable`                ); } //POST
    static staffInvite()                              { return url( `/staff/invite`                      ); } //GET

    //images
    static imageGet( imgId: number ) { return url( `/img/${imgId}` ); } //GET

    //parker vehicle functionality
    static vehicleCreate                = ()                                 => url( '/vehicle/add' );                     //POST
    static vehicleUpdate                = ( vehId: number )                  => url( `/vehicle/${vehId}` );                //POST
    static vehicleSearch                = ()                                 => url( '/vehicle' );                         //GET
    static vehicleDetails               = ( vehId: number )                  => url( `/vehicle/vehicle/${vehId}` );        //GET
    static vehicleDelete                = ( vehId: number )                  => url( `/vehicle/vehicle/${vehId}/delete` ); //POST

    //facilities
    static facilityCreate               = ()                               => facUrl( `/add`                                    ); //POST
    static facilityUpdate               = ( facId: number )                => facUrl( `/${facId}`                               ); //POST
    static facilityList                 = ()                               => facUrl( ``                                        ); //POST
    static facilitySearch               = ( facId: number, query: string ) => facUrl( `/${facId}/search?query=${encode(query)}` ); //GET
    static facilityDetails              = ( facId: number )                => facUrl( `/${facId}`                               ); //GET
    static facilityStatsGet             = ( facId: number )                => facUrl( `/${facId}/stats`                         ); //GET
    static facilityParkerList           = ( facId: number )                => facUrl( `/${facId}/parker`                        ); //GET
    static facilitySessionList          = ( facId: number )                => facUrl( `/${facId}/session`                       ); //GET
    static facilityInvoiceList          = ( facId: number )                => facUrl( `/${facId}/invoice`                       ); //GET

    static facilityPaymentList          = ( facId: number )                => facUrl( `/${facId}/payment`                       ); //GET
    static facilityPayoutList           = ( facId: number )                => facUrl( `/${facId}/payout`                        ); //GET

    static facilityPayoutDetails        = ( facId: number, payoutId: number) => facUrl( `/${facId}/payout/${payoutId}`          ); //GET
    static facilityParkerDetails        = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}`               ); //GET
    static facilityParkerVehicleList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/vehicle`       ); //GET
    static facilityCustomOrderCreate    = ( facId: number )                => facUrl( `/${facId}/order/custom`                  ); //POST

    static facilityParkerBalanceHistory = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/balance/history` ); //GET

    //parker orders
    static facilityParkerOrderList      = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/order`         ); //GET
    static facilityParkerOrderOpenList  = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/order/open`    ); //GET
    static facilityParkerOrderAdjust    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/order/adjust`  ); //POST

    static facilityParkerInvoiceList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/invoice`       ); //GET
    static facilityParkerPaymentList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment`       ); //GET

    static facilityParkerOrderDetails   = ( facId: number, parId: number, ordId: number ) => facUrl( `/${facId}/parker/${parId}/order/${ordId}`   ); //GET
    static facilityParkerInvoiceDetails = ( facId: number, parId: number, invId: number ) => facUrl( `/${facId}/parker/${parId}/invoice/${invId}` ); //GET
    static facilityParkerPaymentDetails = ( facId: number, parId: number, pmtId: number ) => facUrl( `/${facId}/parker/${parId}/payment/${pmtId}` ); //GET

    //parker payments
    static facilityParkerCheckCreate         = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment/check`  ); //POST
    static facilityParkerImportPaymentCreate = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment/import` ); //POST
    static facilityParkerPaymentAdjust       = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment/adjust` ); //POST

    //reports
    static facilityReportAging     = ( facId: number ) => url( `/report/facility/${facId}/aging` ); //GET
    static facilityReportTableList = ( facId: number ) => url( `/report/facility/${facId}/table` ); //GET
    static facilityReportQuery     = ( facId: number ) => url( `/report/facility/${facId}/query` ); //POST

    //facility plan
    static facilityPlanList           = ( facId: number )                 => facUrl( `/${facId}/plan` );                           //GET
    static facilityPlanCreate         = ( facId: number )                 => facUrl( `/${facId}/plan` );                           //POST
    static facilityPlanGet            = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}` );                 //GET
    static facilityPlanUpdate         = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}` );                 //POST
    static facilityPlanPriceList      = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/price` );           //GET
    static facilityPlanPriceCreate    = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/price` );           //POST
    static facilityPlanInvoiceInit    = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/invoice/init`    ); //POST
    static facilityPlanInvoiceConfirm = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/invoice/confirm` ); //POST

    //facility subscription
    static facilitySubscriptionList       = ( facId: number )                => facUrl( `/${facId}/subscription`               ); //GET
    static facilitySubscriptionGet        = ( facId: number, subId: number ) => facUrl( `/${facId}/subscription/${subId}`      ); //GET
    static facilitySubscriptionEditGet    = ( facId: number, subId: number ) => facUrl( `/${facId}/subscription/${subId}/edit` ); //GET
    static facilitySubscriptionEditUpdate = ( facId: number, subId: number ) => facUrl( `/${facId}/subscription/${subId}/edit` ); //POST

    //sub-parker management
    static subParkerCreate( facId: number, subId: number ) { return facUrl( `/${facId}/subscription/${subId}/invite`        ); } //POST
    static subParkerUpdate( facId: number, subId: number ) { return facUrl( `/${facId}/subscription/${subId}/parker`        ); } //POST
    static subParkerDelete( facId: number, subId: number ) { return facUrl( `/${facId}/subscription/${subId}/parker/remove` ); } //POST

    //subscription vehicle management
    static subscriptionVehicleAdd(    facId: number, subId: number )                    { return facUrl( `/${facId}/subscription/${subId}/vehicle/add` ); } //POST
    static subscriptionVehicleDelete( facId: number, subId: number, vehicleId: number ) { return facUrl( `/${facId}/subscription/${subId}/vehicle/${vehicleId}/delete` ); } //POST

    //facility rate
    static facilityRateList     = ( facId: number )                 => facUrl( `/${facId}/rate` );           //GET
    static facilityRateCreate   = ( facId: number )                 => facUrl( `/${facId}/rate/create` );    //POST
    static facilityRateGet      = ( facId: number, progId: number ) => facUrl( `/${facId}/rate/${progId}` ); //GET
    static facilityRateUpdate   = ( facId: number, progId: number ) => facUrl( `/${facId}/rate/${progId}` ); //POST
    static facilityParkerInvite = ( facId: number )                 => facUrl( `/${facId}/invite` );    //POST

    //access keys
    static facilityAccessKeyList               = ( facId: number  )                               => facUrl( `/${facId}/key`                            ); //GET
    static facilityAccessKeyGet                = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}`                   ); //GET
    static facilityAccessKeyCreate             = ( facId: number  )                               => facUrl( `/${facId}/key`                            ); //POST
    static facilityAccessKeyUpdate             = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}`                   ); //POST
    static facilityAccessKeyAssignSubscription = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}/link/subscription` ); //POST
    static facilityAccessKeyAssignParker       = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}/link/parker`       ); //POST
    static facilityAccessKeyAssignVehicle      = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}/link/vehicle`      ); //POST
    static facilityAccessKeyLinkRemove         = ( facId: number, keyId: number  )                => facUrl( `/${facId}/key/${keyId}/remove`            ); //POST
    static facilityAccessKeyRemove             = ( facId: number, keyId: number, linkId: number ) => facUrl( `/${facId}/key/${keyId}/${linkId}/remove`  ); //POST

    //parker
    static parkerUpdate( id: number ) { return url( `/parker/${id}` ); } //POST

    //e-commerce
    static stripeInit( facId: number ) { return url( `/stripe/${facId}/init` ); } //POST
    static stripeLink( facId: number ) { return url( `/stripe/${facId}/link` ); } //POST
}