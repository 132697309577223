import { useState } from "react";
import { Button as BsButton, Table, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Input } from "./Controls/Input";
import { MediumPage } from "./Controls/MediumPage";
import { Select } from "./Controls/Select";
import { Api } from "./Data/Api";
import { SubscriptionStatus, SubscriptionUpdateReqApi } from "./Data/ApiTransport";
import { parseBoolean, useParamIds } from "./Data/Common";
import { showMoney } from "./Data/Money";
import { processResult } from "./Data/Result";
import { emitSubscriptionUpdateReq } from "./Data/ApiEmit";
import { Facility, ParkerDetails, SubEntryForEdit } from "./Data/ApiTypes";
import { parseSubscriptionUpdateReq } from "./Data/ApiParse";
import { Button } from "./Controls/Button";
import { showSubscriptionStatus } from "./Data/Subscription";
import { showInstantDateTime } from "./Data/Date";
import { CheckCircleFill, CurrencyDollar, ExclamationCircleFill, Folder2Open, Hash } from "react-bootstrap-icons";
import React from "react";
import { filterStatusOptions, getInstructions, StatusOption } from "./Data/Subscription";
import { PageBar, ToolButton } from "./Controls/PageBar";

export function FacilityParkerSubscriptionEditPage() {
    const nav = useNavigate();
    const [loading, setLoading] = useState( false );
    const [msg, setMsg] = useState<string>();
    const { facilityId, parkerId, subscriptionId } = useParamIds();
    const sub      = useLoaderData() as SubEntryForEdit;
    const parker   = useRouteLoaderData( "parker"   ) as ParkerDetails;
    const facility = useRouteLoaderData( "facility" ) as Facility;

    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm<SubscriptionUpdateReqApi>( {
        defaultValues: { ...emitSubscriptionUpdateReq( sub ) }
    } );

    const onSubmit = async ( data: SubscriptionUpdateReqApi ) => {
        setLoading( true );
        Api.facilitySubscriptionEditUpdate( facilityId, subscriptionId, parseSubscriptionUpdateReq( data ) ).then(
            res => processResult( res,
                val => {
                    setMsg( "Subscription Updated Successfully" );
                    setLoading( false );
                    nav( `/facility/${facilityId}/parker/${parkerId}/plan/${subscriptionId}` );
                },
                err => { setMsg( err ); }
            )
        );
    };

    const values = watch();
    const filteredOptions: StatusOption[] = [
        { lbl: "Do Nothing", val: sub.status, expl: "Status will not be changed" },
        ...filterStatusOptions( sub.status )
    ];
    const actionRequired = sub.status == SubscriptionStatus.Applied
                        || sub.status == SubscriptionStatus.WaitingForContract;
    const selectedStatus = filteredOptions.find( x => x.val === values.status );

    function getDisabled( status: SubscriptionStatus ) {
        return status !== SubscriptionStatus.Active;
    }

    return <MediumPage>
        <PageBar title="Edit Parker Plan">
            <ToolButton onClick={ () => nav(`/facility/${facilityId}/parker/${parkerId}/plan/${subscriptionId}/manage`) }>
                <Folder2Open /> Manage
            </ToolButton>
        </PageBar>
        <form onSubmit={handleSubmit( onSubmit )}>
            <Container className="g-0">
                <Row>
                    <Col xs="12" lg="6">
                        <Table className="table w-100" cellPadding={4}>
                            <tbody>
                                <tr>
                                    <td>
                                        Parker
                                    </td>
                                    <td className="text-start">
                                        {parker.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Plan Status</td>
                                    <td className="text-start">{showSubscriptionStatus( sub.status )}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Last Modified
                                    </td>
                                    <td className="text-start">
                                        {showInstantDateTime( sub.createdWhen, "America/New_York" )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <div className={`${actionRequired ? "alert alert-warning" : "card card-body mb-3"} d-flex flex-row align-items-center gap-1 p-2`}>
                { actionRequired ? <React.Fragment>
                    <ExclamationCircleFill color="danger" /> {getInstructions( sub.status )}
                </React.Fragment> : <React.Fragment>
                    <CheckCircleFill floodColor="success" /> No action required at this time.
                </React.Fragment> }
            </div>
            <div className="gap-2 align-items-center mb-3" style={{ display: "grid", gridTemplateColumns: "max-content 1fr" }}>
                {filteredOptions.map( f => {
                    const selected = f.val === getValues( "status" );
                    return <React.Fragment key={f.val}>
                        <BsButton
                            className={ selected ? "bg-primary text-decoration-underline" : "bg-white text-secondary"}
                            onClick={() => setValue( "status", f.val )}>
                            {f.lbl}
                        </BsButton>
                        <div className={selected ? "" : `invisible`}>
                            {f.expl}
                        </div>
                    </React.Fragment>;
                } )}
            </div>
            <div className="two-col">
                <Select
                    icon={<CurrencyDollar />}
                    label="Plan Price"
                    explanation="This will change the parker's next bill!"
                    selectClassName="text-end"
                    disabled={getDisabled( sub.status )}
                    {...register( "planPriceId", {
                        required: "Plan price is required",
                        valueAsNumber: true
                    } )}
                    error={errors.planPriceId}>
                    {sub.prices?.map( price => (
                        <option key={price.planPriceId} value={price.planPriceId}>
                            {showMoney( price.price )}
                        </option>
                    ) )}
                </Select>
                <Input
                    icon={<Hash />}
                    type="number"
                    label="Quantity"
                    explanation="This will change the parker's next bill!"
                    disabled={getDisabled( sub.status )}
                    inputClassName="text-end"
                    min={1}
                    {...register( "quantity", {
                        required: "Quantity is required",
                        min: {
                            value: 1,
                            message: "Quantity must be at least 1"
                        },
                        valueAsNumber: true
                    } )}
                    error={errors.quantity}
                />
            </div>
            <div className="two-col">
                <Input
                    type="date"
                    disabled
                    label="Start Date"
                    {...register( "start", { required: "Start date is required" } )}
                    error={errors.start}
                />
                {/* <Input
                    type="date"
                    label="End Date"
                    {...register( "end", { setValueAs: v => v ? "" : null } )}
                    error={errors.end}
                /> */}
            </div>
            <div className="two-col">
                <Select
                    label="Payment"
                    explanation="Whether payment is required immediately or can be deferred to accounts receievable"
                    disabled={getDisabled( sub.status )}
                    {...register( "isAccountsReceivable",
                        { setValueAs: parseBoolean }
                    )}
                    error={errors.isAccountsReceivable}>
                    <option value="false">Require Payment Immediately</option>
                    <option value="true">Defer to Accounts Receivable</option>
                </Select>
                <Select
                    label="Late Fees"
                    explanation="Enable generating late fees after due date"
                    disabled={getDisabled( sub.status )}
                    {...register( "isLateFeeEnabled",
                        { setValueAs: parseBoolean }
                     )}
                    error={errors.isLateFeeEnabled}>
                    <option value="false">Disabled</option>
                    <option value="true">Enabled</option>
                </Select>
            </div>
            <div className="d-flex justify-content-end gap-2">
                <Button className="ms-auto w-50" type="submit" loading={loading}>
                    Submit
                </Button>
            </div>
        </form>
    </MediumPage>;
}


