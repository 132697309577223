import { ReactNode, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg, EventInput } from "@fullcalendar/core";
import { Braces } from "react-bootstrap-icons";
import { PageTitle } from "./Controls/PageTitle";
import { useAtom } from "jotai";
import { scheduleAtom } from "./Data/Atoms";

interface Tool {
    name:        string;
    icon:        ReactNode;
    color:       string;
    explanation: ReactNode;
}

const tools: Tool[] = [
    {
        name: "Facility Open", 
        icon: <Braces />,
        color: "",
        explanation: <>
                    <p>Click and drag on the schedule to set the facility as open during that time.</p>
                    <p>Schedule changes in the past are not allowed.  </p>
                    <p>You must set a date for the schedule change to take effect. (Not implemented yet).</p>
        </>
    },
];

interface ScheduleToolProps {
    tool:   Tool;
    active: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

function ScheduleTool( { tool: t, active, onClick  }: ScheduleToolProps ) {
    return <div onClick={onClick}
                className={`d-flex align-items-center justify-content-center mt-1 text-center border rounded-3 ${active ? "border-primary" : "" }`}
                style={{ aspectRatio: "1/1", borderColor: t.color }}>
        {t.icon}
    </div>;
}

export function FacilitySchedulePage() {
    const [events,       setEvents] = useAtom<EventInput[]>( scheduleAtom );
    const [evId,         setEvId  ] = useState( 0 );
    const [selectedTool, setSelectedTool] = useState( tools[0] );

    const handleDateSelect = ( selectInfo: DateSelectArg ) => {
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        const newEvent: EventInput = {
            id:     crypto.randomUUID(),
            title:  selectedTool.name,
            start:  selectInfo.startStr,
            end:    selectInfo.endStr,
            allDay: selectInfo.allDay,
            color:  selectedTool.color,
        };
        setEvents( [...events, newEvent] );
        calendarApi.addEvent( newEvent );
        setEvId( evId + 1 );
    };

    const handleEventClick = ( clickInfo: EventClickArg ) => {
        if( window.confirm( `Are you sure you want to delete the event '${clickInfo.event.title}'` ) ) {
            clickInfo.event.remove();
            setEvents( events.filter( event => event.id !== clickInfo.event.id ) );
        }
    };

    return <Container fluid className="h-100 pb-2">
        <Row className="h-100">
            <Col md="3" className="text-justify" style={{ display: "grid", gridTemplateRows: "min-content min-content auto"}}>
                <PageTitle>Facility Schedule</PageTitle>
                <div>
                    <p className="d-lg-none text-danger alert alert-danger">
                        This page is disabled on mobile devices.
                    </p>
                    <div className="d-none d-lg-block text-secondary py-2">
                        {selectedTool.explanation}
                    </div>
                </div>
            </Col>
            <Col>
                <FullCalendar
                    height={"100%"}
                    plugins={[multiMonthPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    dayHeaderFormat={{ weekday: "short" }}
                    initialView="timeGridWeek"
                    firstDay={1}
                    slotDuration={"01:00:00"}
                    eventMinHeight={1}
                    buttonText={{
                        today: 'Today',
                        month: 'Month',
                        week:  'Week',
                        day:   'Day',
                        list:  'list'
                    }}
                    headerToolbar={{
                        center: "title",
                        start:  'timeGridWeek',
                    }}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventDragStart={ info => info.el.style.backgroundColor = selectedTool.color }
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    events={events} />
            </Col>
        </Row>
    </Container>;
}

