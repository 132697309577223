import { forwardRef, ReactNode, useEffect, useId, useImperativeHandle, useRef, useState } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { ChangeHandler, FieldError } from "react-hook-form";
import { cents, showMoney } from "../Data/Money";

export interface MoneyInputProps {
    name:            string;
    className?:      string;
    inputClassName?: string;
    label:           string;
    icon?:           ReactNode;
    placeholder?:    string;
    explanation?:    string;
    onChange:        ChangeHandler;
    onBlur:          ChangeHandler;
    error?:          FieldError | undefined;
    value?:          number;
}

function fakeChangeEvent( elem: HTMLInputElement ): React.ChangeEvent<HTMLInputElement> {
    const event = {
        target: elem,
        preventDefault:  () => {},
        stopPropagation: () => {},
    } as React.ChangeEvent<HTMLInputElement>;
    return event;
}
export const MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
    ( { className = "mb-3",
        inputClassName = "text-end",
        label,
        icon,
        error,
        placeholder = "",
        explanation = "",
        name,
        onChange,
        onBlur,
        value = 0,
    }, ref ) => {
        const validNums     = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const inputRef      = useRef<HTMLInputElement>();
        const [val, setVal] = useState( value );
        const valStr        = val.toString();
        const dispVal       = showMoney( cents( val ) );

        //accessibility stuff
        const inputId = useId();
        const helpId  = `${inputId}-help`;

        useEffect( () => {
            if( !inputRef.current ) { return; }
            setVal( value );
        }, [value] );

        useEffect( () => {
            if( !inputRef.current ) { return; }
            inputRef.current.value = val.toString();
            onChange( fakeChangeEvent( inputRef.current! ) );
        }, [val] );

        function handleKeyDown( e: React.KeyboardEvent<HTMLInputElement> ): void {
            function setAndPrevent( x: number ) { setVal( x ); e.preventDefault(); }

            if( e.key !== "Backspace" && val.toString().length > 8 ) {
                e.preventDefault();
                return;
            }

            if( validNums.includes( e.key ) ) {
                setAndPrevent( parseInt( valStr + e.key.toString() ) );
                return;
            }

            if( e.key === "Backspace" ) {
                const afterBackspace = valStr.slice( 0, Math.max( 0, valStr.length - 1 ) );
                const newVal = afterBackspace.length !== 0 ? parseInt( afterBackspace ) : 0;
                setAndPrevent( newVal );
                return;
            }
        }

        function setRef( element: HTMLInputElement ) {
            if( typeof ref === "function" ) { ref( element );        }
            else if( ref )                  { ref.current = element; }
            inputRef.current = element;
        }

        return <Form.Group className={className}>
            <Form.Label htmlFor={inputId} className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>
                    {icon}
                </InputGroup.Text>}
                <Form.Control
                    id={inputId}
                    aria-describedby={helpId}
                    type={"text"}
                    className={inputClassName}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    value={dispVal} />
            </InputGroup>
            <input
                style={{ display: "none" }}
                type="number"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                ref={setRef} />
            {explanation &&
                <Form.Text id={helpId} className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);