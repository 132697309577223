import { useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Download } from "react-bootstrap-icons";
import { useParamIds } from "./Data/Common";
import { Button } from "./Controls/Button";
import { PageTitle } from "./Controls/PageTitle";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PlanDetails } from "./Data/ApiTypes";
import { connectUrl } from "./Data/ApiUrl";

type DownloadFormat = "svg" | "png";

interface QRCodeRef extends HTMLDivElement {
    querySelector( selectors: string ): SVGElement | null;
}

export function FacilityPlanSignPage() {
    const nav  = useNavigate();
    const { facilityId, planId } = useParamIds();
    const plan = useLoaderData() as PlanDetails;

    const [text, setText] = useState<string>( connectUrl( `/checkout/monthly?facilityId=${facilityId}&planId=${planId}` ) );
    const qrRef = useRef<QRCodeRef>( null );

    const downloadQR = ( format: DownloadFormat ): void => {
        const svg = qrRef.current?.querySelector( "svg" );
        if( !svg ) { return; }
        if( format === "svg" ) {
            const svgData = new XMLSerializer().serializeToString( svg );
            const blob    = new Blob( [svgData], { type: "image/svg+xml" } );
            const url     = URL.createObjectURL( blob );
            downloadFile( url, `qrcode.${format}` );
            URL.revokeObjectURL( url );
        } else {
            const canvas  = document.createElement( "canvas" );
            const ctx     = canvas.getContext( "2d" );
            const img     = new Image();
            const svgBlob = new Blob( [new XMLSerializer().serializeToString( svg )], { type: "image/svg+xml" } );
            const url     = URL.createObjectURL( svgBlob );

            img.onload = (): void => {
                canvas.width  = img.width;
                canvas.height = img.height;
                ctx?.drawImage( img, 0, 0 );
                const imgURI  = canvas.toDataURL( "image/png" );
                downloadFile( imgURI, "qrcode.png" );
                URL.revokeObjectURL( url );
            };
            img.src = url;
        }
    };

    const downloadFile = ( url: string, filename: string ): void => {
        const link    = document.createElement( "a" );
        link.href     = url;
        link.download = filename;
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    };

    return <Container fluid className="h-100">
        <Row className="h-100 w-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto 1fr auto auto 10px", gridTemplateColumns: "1fr" }}>
                <div className="d-flex align-items-center gap-2">
                    <PageTitle>
                        {plan.name}
                    </PageTitle>
                    {/* <Button variant="sm"
                        className="d-flex-align-center"
                        onClick={() => window.print()}>
                        <Asterisk color="maroon" /> Print
                    </Button> */}
                </div>
                <div ref={qrRef} className="text-center h-100"
                                 style={{ minWidth: "0",
                                          minHeight: "0" }}>
                    <QRCodeSVG
                        height={512}
                        width={512}
                        style={{ objectFit: "contain", height: "100%" }}
                        value={text}
                        level="M"
                        includeMargin={true} />
                </div>
                <Card className="text-center mb-3">
                    <Card.Body>
                        <a href={text}>
                            {text}
                        </a>
                    </Card.Body>
                </Card>
                <div className="d-flex justify-content-center gap-3 d-print-none">
                    <Button className="w-100"
                        onClick={() => downloadQR( "svg" )}>
                        <Download className="me-2" />
                        Download SVG
                    </Button>
                    <Button className="border-success w-100"
                        onClick={() => downloadQR( "png" )}>
                        <Download className="me-2" />
                        Download PNG
                    </Button>
                </div>
            </Col>
        </Row>
    </Container>;
}