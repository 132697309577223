import { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { DataGrid } from "./Controls/DataGrid";
import { EnvelopePaper } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Staff, StaffUpdateReq } from "./Data/ApiTypes";
import { showInstantLocalDate } from "./Data/Date";
import StaffInviteModal from "./OperatorStaffListPageInviteModal";
import StaffEditModal, { StaffEditData } from "./OperatorStaffListPageEditModal";
import { Api } from "./Data/Api";

export function OperatorStaffListPage() {
    const nav   = useNavigate();
    const { revalidate } = useRevalidator();
    const staff = useLoaderData() as Staff[];
    const [ showInvite,  setShowInvite  ] = useState( false );
    const [ showEdit,    setShowEdit    ] = useState( false );
    const [ staffToEdit, setStaffToEdit ] = useState<Staff>();

    const columns = useMemo<ColDef<Staff>[]>( () => [
        { field: "staffId",      headerName: "#",       type: "rightAligned"    },
        { field: "name",         headerName: "Name"                             },
        { field: "emailAddress", headerName: "Email"                            },
        { field: "note",         headerName: "Note"                             },
        { field: "phoneNumber",  headerName: "Phone",   valueFormatter: x => x.value || "" },
        { field: "createdWhen",  headerName: "Created", valueFormatter: x => showInstantLocalDate( x.value ) },
        { field: "removedWhen",  headerName: "Removed", valueFormatter: x => x.value ? showInstantLocalDate( x.value ) : "" }
    ], [] );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        const staff: Staff = event.data;
        const staffId = staff.staffId;
        nav( `/operator/staff/${staffId}` );
    };

    return <Container fluid className="h-100">
        <StaffInviteModal
            show={showInvite}
            setShow={setShowInvite} />
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr 5px"}}>
                <PageBar title="Staff">
                    <ToolButton onClick={ () => setShowInvite( true ) }>
                        <EnvelopePaper color="maroon" /> Invite Staff
                    </ToolButton>
                </PageBar>
                <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid onRowClicked={onRowClicked}
                                rowData={staff}
                                columnDefs={columns}
                                onGridReady={onGridReady}
                    />
                </div>
            </Col>
        </Row>
    </Container>;
}