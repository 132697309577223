import React, { ReactNode } from 'react';
import { Modal, Form, Col, Container, Row } from 'react-bootstrap';
import { Button } from "./Button";
import { HeaderText } from "./HeaderText";

interface ConfirmModalProps {
    title:        ReactNode;
    message:      ReactNode;
    cancelText?:  ReactNode;
    confirmText?: ReactNode;
    show?:        boolean;
    setShow:      (show : boolean) => void;
    onConfirm:    () => void;
}

export function ConfirmModal( { show, setShow, onConfirm, title, message, cancelText = "Cancel", confirmText = "Submit" }: ConfirmModalProps ) {
    function submit( e: React.FormEvent ) {
        e.preventDefault();
        onConfirm();
        setShow( false );
    }

    if( !show ) {
        return <></>;
    }

    return <Modal show={!!show} centered onEscapeKeyDown={ () => setShow( false ) }>
        <Modal.Header>
            <HeaderText>{title}</HeaderText>
        </Modal.Header>
        <Modal.Body className="pt-0 mt-0">
            <Form onSubmit={submit}>
                <Container className="g-0 py-4">
                    <Row>
                        <Col className="text-secondary">
                            {message}
                        </Col>
                    </Row>
                </Container>
                <div className="d-flex justify-content-end gap-2">
                    <Button
                        type="button"
                        className="w-100"
                        onClick={ () => setShow( false )}>
                        {cancelText}
                    </Button>
                    <Button
                        type="submit"
                        className="w-100">
                        {confirmText}
                    </Button>
                </div>
            </Form>
        </Modal.Body>
    </Modal>;
}