import { ColDef, ICellRendererParams, RowSelectionOptions, ValueFormatterParams } from "ag-grid-community";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid } from "./Controls/DataGrid";
import { Dropdown, DropdownButton } from "react-bootstrap";
import type { CustomCellEditorProps } from "ag-grid-react";

export const GenderEditor = memo(
  ({ value, onValueChange, stopEditing }: CustomCellEditorProps) => {
    const isHappy = (value: string) => value === "Happy";

    const [ready, setReady] = useState(false);
    const refContainer = useRef<HTMLDivElement>(null);

    const checkAndToggleMoodIfLeftRight = (event: any) => {
      if (ready) {
        if (["ArrowLeft", "ArrowRight"].indexOf(event.key) > -1) {
          // left and right
          const isLeft = event.key === "ArrowLeft";
          onValueChange(isLeft ? "Happy" : "Sad");
          event.stopPropagation();
        }
      }
    };

    useEffect(() => {
      refContainer.current?.focus();
      setReady(true);
    }, []);

    useEffect(() => {
      window.addEventListener("keydown", checkAndToggleMoodIfLeftRight);

      return () => {
        window.removeEventListener("keydown", checkAndToggleMoodIfLeftRight);
      };
    }, [checkAndToggleMoodIfLeftRight, ready]);

    const onClick = (happy: boolean) => {
      onValueChange(happy ? "Happy" : "Sad");
      stopEditing();
    };

    const mood = {
      borderRadius: 15,
      border: "1px solid grey",
      backgroundColor: "#e6e6e6",
      padding: 15,
      textAlign: "center" as const,
      display: "inline-block",
    };

    const unselected = {
      paddingLeft: 10,
      paddingRight: 10,
      border: "1px solid transparent",
      padding: 4,
    };

    const selected = {
      paddingLeft: 10,
      paddingRight: 10,
      border: "1px solid lightgreen",
      padding: 4,
    };

    const happyStyle = isHappy(value) ? selected : unselected;
    const sadStyle = !isHappy(value) ? selected : unselected;

    return (
      <div
        ref={refContainer}
        style={mood}
        tabIndex={1} // important - without this the key presses wont be caught
      >
        <img
          src="https://www.ag-grid.com/example-assets/smileys/happy.png"
          onClick={() => onClick(true)}
          style={happyStyle}
        />
        <img
          src="https://www.ag-grid.com/example-assets/smileys/sad.png"
          onClick={() => onClick(false)}
          style={sadStyle}
        />
      </div>
    );
  },
);

/* Format Date Cells */
const dateFormatter = ( params: ValueFormatterParams ): string => {
    return new Date( params.value ).toLocaleDateString( "en-us", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
    } );
};

// Row Data Interface
interface IRow {
    mission: string;
    company: string;
    location: string;
    date: string;
    time: string;
    rocket: string;
    price: number;
    successful: boolean;
}

const rowSelection: RowSelectionOptions = {
    mode: "multiRow",
    headerCheckbox: false,
};

// Create new GridExample component
export const Sandbox = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState<IRow[]>( [] );

    // Column Definitions: Defines & controls grid columns.
    const [colDefs] = useState<ColDef<IRow>[]>( [{
        field: "mission",
        width: 150,
    },
    {
        field: "company",
        width: 130,
    },
    {
        field: "location",
        width: 225,
    },
    {
        field: "date",
        valueFormatter: dateFormatter,
    },
    {
        field: "price",
        width: 130,
        valueFormatter: ( params: ValueFormatterParams ) => {
            return "£" + params.value.toLocaleString();
        },
    },
    {            
        headerName: 'Actions',
        cellEditor: GenderEditor        
    },
    { field: "rocket" },
    ] );

    // Fetch data & update rowData state
    useEffect( () => {
        fetch( "https://www.ag-grid.com/example-assets/space-mission-data.json" )
            .then( ( result ) => result.json() )
            .then( ( rowData ) => setRowData( rowData ) );
    }, [] );

    // Apply settings across all columns
    const defaultColDef = useMemo<ColDef>( () => {
        return {
            filter: true,
            editable: true,
        };
    }, [] );

    // Container: Defines the grid's theme & dimensions.
    return (
        <div
            className={"ag-theme-quartz"}
            style={{ width: "100%", height: "100vh" }}>
            <DataGrid
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                rowSelection={rowSelection}
                onSelectionChanged={( event ) => console.log( "Row Selected!" )}
                onCellValueChanged={( event ) =>
                    console.log( `New Cell Value: ${event.value}` )
                }
            />
        </div>
    );
};

interface DropdownCellRendererParams extends ICellRendererParams {
    data: IRow;
}

// Props interface for the component
interface DropdownCellRendererProps extends DropdownCellRendererParams {
    onEdit?: ( data: IRow ) => void;
    onDelete?: ( data: IRow ) => void;
    onView?: ( data: IRow ) => void;
}

const AdvancedDropdownCellRenderer: React.FC<DropdownCellRendererProps> = ( props ) => {
    const onEdit = useCallback( (): void => {
        if( props.onEdit ) {
            props.onEdit( props.data );
        }
        props.api.startEditingCell( {
            rowIndex: props.node.rowIndex!,
            colKey: 'editable-column'
        } );
    }, [props] );

    const onDelete = useCallback( (): void => {
        if( props.onDelete ) {
            props.onDelete( props.data );
        }
        props.api.applyTransaction( {
            remove: [props.data]
        } );
    }, [props] );

    const onView = useCallback( (): void => {
        if( props.onView ) {
            props.onView( props.data );
        }
    }, [props] );

    const preventRowSelection = ( e: React.MouseEvent ): void => {
        e.stopPropagation();
    };

    return (
        <div onClick={preventRowSelection}>
            <DropdownButton
                id={`dropdown-${props.node.rowIndex}`}
                title="Actions"
                size="sm"
                variant="outline-secondary"
                className="grid-dropdown-button"
            >
                <Dropdown.Item onClick={onEdit}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={onView}>
                    View Details
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={onDelete}
                    className="text-danger"
                >
                    Delete
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};