import { DateTimeFormatter, Duration, Instant, LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";

// Create functions
export function createLocalDate( str: string ): LocalDate;
export function createLocalDate( str: null ): null;
export function createLocalDate( str: string | null ): LocalDate | null {
    return str !== null ? LocalDate.parse( str, DateTimeFormatter.ISO_LOCAL_DATE ) : null;
}

export function createLocalDateTime( str: string ): LocalDateTime;
export function createLocalDateTime( str: null ): null;
export function createLocalDateTime( str: string | null ): LocalDateTime | null {
    return str !== null ? LocalDateTime.parse( str, DateTimeFormatter.ISO_LOCAL_DATE_TIME ) : null;
}

export function createLocalTime( str: string ): LocalTime;
export function createLocalTime( str: null ): null;
export function createLocalTime( str: string | null ): LocalTime | null {
    return str !== null ? LocalTime.parse( str, DateTimeFormatter.ISO_LOCAL_TIME ) : null;
}

export function createDuration( mins: number ): Duration;
export function createDuration( mins: null ): null;
export function createDuration( mins: number | null ): Duration | null {
    return mins !== null ? Duration.ofMinutes( mins ) : null;
}

export function createInstant( str: string ): Instant;
export function createInstant( str: null ): null;
export function createInstant( str: string | null ): Instant | null {
    return str !== null ? Instant.parse( str ) : null;
}

// Emit functions
export function emitLocalDate( date: LocalDate ): string;
export function emitLocalDate( date: null ): null;
export function emitLocalDate( date: LocalDate | null ): string | null {
    return date !== null ? date.format( DateTimeFormatter.ISO_LOCAL_DATE ) : null;
}

export function emitLocalDateTime( datetime: LocalDateTime ): string;
export function emitLocalDateTime( datetime: null ): null;
export function emitLocalDateTime( datetime: LocalDateTime | null ): string | null {
    return datetime !== null ? datetime.format( DateTimeFormatter.ISO_LOCAL_DATE_TIME ) : null;
}

export function emitLocalTime( time: LocalTime ): string;
export function emitLocalTime( time: null ): null;
export function emitLocalTime( time: LocalTime | null ): string | null {
    return time !== null ? time.format( DateTimeFormatter.ISO_LOCAL_TIME ) : null;
}

export function emitDuration( duration: Duration ): number;
export function emitDuration( duration: null ): null;
export function emitDuration( duration: Duration | null ): number | null {
    return duration !== null ? duration.toMinutes() : null;
}

export function emitInstant( instant: Instant ): string;
export function emitInstant( instant: null ): null;
export function emitInstant( instant: Instant | null ): string | null {
    return instant !== null ? instant.toString() : null;
}