import { Container, Row, Col, ListGroupItem, ListGroup, CloseButton } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { useState } from "react";
import { AddLineItemModal } from "./FacilityCustomOrderCreatePageAddLineItemModal";
import { AddRecipientModal, RecipientForm } from "./FacilityOrderCreatePageAddRecipientModal";
import { showAddressHtml } from "./Data/Location";
import React from "react";
import { Input } from "./Controls/Input";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { Facility, OrderCustomReq } from "./Data/ApiTypes";
import { Check, Envelope, PersonAdd, PlusCircle, Telephone } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { showBrowserDate } from "./Data/Date";
import { LocalDate } from "@js-joda/core";
import { Api } from "./Data/Api";
import { createLocalDate } from "./Data/ApiConverters";
import { OrderCustomItemReqApi } from "./Data/ApiTransport";
import { LargePage } from "./Controls/LargePage";
import { add, multiply } from "dinero.js";
import { cents, showMoney } from "./Data/Money";
import { Hyperlink } from "./Controls/Hyperlink";

export function FacilityInvoiceCreatePage() {
    const nav = useNavigate();
    const facility = useRouteLoaderData( "facility" ) as Facility;
    const facilityId = facility.facilityId;
    const [ showAddRec,  setShowAddRec  ] = useState( false );
    const [ showAddItem, setShowAddItem ] = useState( false );
    const [ recipient,   setRecipients  ] = useState<RecipientForm>();
    const [ items,       setItems       ] = useState<OrderCustomItemReqApi[]>( [] );
    const [ desc,        setDesc        ] = useState( "" );
    const [ dueDate,     setDueDate     ] = useState( showBrowserDate( LocalDate.now() ) );

    function addRecipient( r: RecipientForm ) {
        setRecipients( r );
    }

    function addItem( item: OrderCustomItemReqApi ) {
        setItems( [ ...items, item ] );
    }

    async function submit() {
        if( !recipient ) {
            return;
        }
        const req: OrderCustomReq = {
            name:         recipient.name,
            emailAddress: recipient.emailAddress,
            phoneNumber:  recipient.phoneNumber,
            description:  desc,
            items:        items,
            dueDate:      createLocalDate( dueDate ),
        };
        const res = await Api.facilityCustomOrderCreate( facility.facilityId, req );
    }

    return <LargePage>
        <AddRecipientModal show={showAddRec}  setShow={setShowAddRec}  onSave={addRecipient} />
        <AddLineItemModal  show={showAddItem} setShow={setShowAddItem} onSave={addItem}      />
        <PageBar title="Custom Order">
            <ToolButton onClick={ () => setShowAddRec( true ) }>
                <PersonAdd color="maroon" /> {recipient ? "Change Recipient" : "Set Recipient"}
            </ToolButton>
            <ToolButton onClick={ () => setShowAddItem( true ) }>
                <PlusCircle /> Add Line Item
            </ToolButton>
        </PageBar>
        <Container fluid className="g-0">
            <Row>
                <Col lg="12" xl="4">
                    <div className="mt-2">
                            <Input type="date" label="Due Date"
                                value={dueDate}
                                onChange={e => setDueDate( e.target.value )} />
                            <Input type="text" label="Short Description"
                                value={desc}
                                onChange={e => setDesc( e.target.value )} />
                    </div>
                </Col>
                <Col lg="12" xl="auto" className="d-flex align-items-center">
                    <ListGroup variant="flush" className="text-muted">
                        <ListGroupItem className="d-flex-align-center">
                            <Check size="20" className="text-success" />
                            Use this form to create a custom order
                        </ListGroupItem>
                        <ListGroupItem className="d-flex-align-center">
                            <Check size="20" className="text-success" />
                            The recipient can be an existing parker or a new one (an account will be created)
                        </ListGroupItem>
                        <ListGroupItem className="d-flex-align-center">
                            <Check size="20" className="text-success" />
                            Once you click send an order, invoice and email will be generated
                        </ListGroupItem>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
        <div className="p-0 border-0 p-md-3 shadow-md mt-3 rounded-0">
            <Row>
                <Col xs={6}>
                    <div className="d-flex flex-column">
                        <strong className="fs-5 text-uppercase">
                            {facility.name}
                        </strong>
                        <div>
                            {showAddressHtml( facility.address )}
                        </div>
                        <div className="mt-1 d-flex align-items-center gap-2">
                            <Envelope color="maroon" /> {facility.emailAddress}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <Telephone color="maroon" /> {facility.phoneNumber}
                        </div>
                    </div>
                </Col>
                <Col xs={6} className="text-md-end">
                    <div>
                        <span className="fw-semibold text-secondary">INVOICE</span>
                    </div>
                    <div>
                        Created: {showBrowserDate( LocalDate.now() )}
                    </div>
                    <div>
                        Due Date: {dueDate}
                    </div>
                </Col>
            </Row>
            <hr />
            <Row className="mb-4 gy-2">
                <Col lg={4}>
                    <div className="fs-4 text-secondary text-uppercase">
                        Recipient
                    </div>
                    <div className="d-flex flex-column">
                        {recipient === undefined ? "No Recipient Added" : (
                            <>
                                <div>{recipient.name}</div>
                                <div className="d-flex align-items-center gap-2">
                                    <Envelope color="maroon" /> {recipient.emailAddress}
                                </div>
                                {recipient.phoneNumber && (
                                    <div className="d-flex align-items-center gap-2">
                                        <Telephone color="maroon" /> {recipient.phoneNumber}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Col>
                <Col lg={8}>
                    <div className="fs-4 ms-1 text-secondary text-uppercase">
                        Details
                    </div>                    
                    <div className="d-grid mt-2">
                        <div className="grid-col-1 border-bottom border-2 pb-2 mb-2">Line Item</div>
                        <div className="grid-col-2 border-bottom border-2 pb-2 mb-2 text-end">Unit Price</div>
                        <div className="grid-col-3 border-bottom border-2 pb-2 mb-2 text-end">Quantity </div>
                        <div className="grid-col-4 border-bottom border-2 pb-2 mb-2 text-end">Subtotal </div>
                        {items.length === 0 && "No Items added yet"}
                        {items.map( ( el, i ) => {
                            const subtotal = multiply( cents( el.unitPrice ), el.quantity );
                            return <React.Fragment key={i}>
                                <div className="grid-col-1">{el.name}</div>
                                <div className="grid-col-2 text-end">{showMoney( cents( el.unitPrice ))}</div>
                                <div className="grid-col-3 text-end">{el.quantity}</div>
                                <div className="grid-col-4 text-end">{showMoney( subtotal )}</div>
                            </React.Fragment>;
                        } )}
                    </div>
                    <div>
                        Total: {showMoney( items.map( i => multiply( cents( i.unitPrice ), i.quantity ) ).reduce( (a, b) => add( a, b, ), cents( 0 ) ) )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-muted d-flex flex-column">
                        <div>Note: Make checks payable to {facility.name}</div>
                    </div>
                </Col>
            </Row>
        </div>

        <div className="d-flex gap-2 mt-3">
            <Button className="flex-grow-1" onClick={ () => nav( `/facility/${facility.facilityId}/finance/invoice` ) }>
                Discard
            </Button>
            <Button className="flex-grow-1" onClick={ () => submit() } disabled={(recipient === null || items.length == 0 || desc.length == 0 )} >
                Send Invoice
            </Button>
        </div>
    </LargePage>;
}