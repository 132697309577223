import React from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Copy, IconProps } from "react-bootstrap-icons";

interface ClipboardIconProps {
    icon?:            React.ReactElement<IconProps>;    
    payload:          string;
    tooltipText:      string;
    tooltipDuration?: number;
}

export function ClipboardIcon( {    
    icon = <Copy color="" className="small" />,    
    payload,
    tooltipText = "Copied!",
    tooltipDuration = 1000
}: ClipboardIconProps ) {
    const [showTooltip, setShowTooltip] = useState( false );
    async function handleCopy() {
        try {            
            await navigator.clipboard.writeText( payload );
            setShowTooltip( true );
            setTimeout( () => setShowTooltip( false ), tooltipDuration );
        } catch( err ) {
            console.error( 'Failed to copy text:', err );
        }
    }
    const iconWithProps = React.cloneElement( icon, { onClick: handleCopy, style: { cursor: 'pointer' } } );
    return <OverlayTrigger
        placement="top"
        show={showTooltip}
        //TODO: this position-fixed workaround is weird!
        //https://stackoverflow.com/questions/74726036/how-to-fix-bootstrap-react-tooltip-blinking-scrolled-in-body
        overlay={<Tooltip className="position-fixed">{tooltipText}</Tooltip>}>
        <span className="d-inline-flex gap-1 align-items-center">
            {iconWithProps}
        </span>
    </OverlayTrigger>;
}