import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from "react-bootstrap"

export interface SquareButtonProps extends BootstrapButtonProps {
    loading?:     boolean;
    loadingText?: string;
}

export function SquareButton( props: SquareButtonProps ) {
    const { loading = false, children, className, loadingText = "Loading...", style, ...rest } = { ...props };
    const newClassName = `rounded-3 border-primary border-2 status-btn text-black ${className}`;
    return <BootstrapButton variant="body-secondary" disabled={loading} className={newClassName} style={{ ...style, opacity: "1" }} {...rest}>
        {loading ? <>
            <span className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"></span>
            {loadingText}
        </> : children}
    </BootstrapButton>
}