import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { useParamIds } from "./Data/Common";
import { BalanceStatusRenderer, colMoney, DataGrid } from "./Controls/DataGrid";
import { EnvelopeOpenHeart, EnvelopePaper, EnvelopePlus, Plus } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Facility, FacilityInvoice } from "./Data/ApiTypes";
import { showDate, showInstantDate } from "./Data/Date";
import { showOrderType } from "./Data/Finance";

export function FacilityFinanceInvoiceListPage() {
    const nav            = useNavigate();
    const { facilityId } = useParamIds();
    const invoices       = useLoaderData() as FacilityInvoice[];
    const facility       = useRouteLoaderData("facilityReport") as Facility;

    const columns = useMemo<ColDef<FacilityInvoice>[]>( () => [        
        { field: "invoiceNumber", headerName: "Invoice #", type: "rightAligned" },
        { field: "parker.name", headerName: "Parker" },        
        { field: "order.orderType", headerName: "Invoice Type", valueFormatter: x => showOrderType( x.value ) },
        { field: "createdWhen", headerName: "Created", valueFormatter: x => showInstantDate( x.value, facility.timeZone ) },
        { field: "dueWhen", headerName: "Due Date",    valueFormatter: x => showDate( x.value ) },        
        { ...colMoney( "openingBalance" ),        headerName: "Opening"     },
        { ...colMoney( "amountDue" ),             headerName: "Amount Due"  },
        { ...colMoney( "endingBalance" ),         headerName: "Closing"     },
        { ...colMoney( "order.balance.balance" ), headerName: "Status", cellRenderer: BalanceStatusRenderer }
    ], [] );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => { 
        const invoice: FacilityInvoice = event.data;
        const invoiceId = invoice.invoiceId;
        const parkerId = invoice.order.parkerId;        
        nav( `/facility/${facility.facilityId}/parker/${parkerId}/invoice/${invoiceId}` );
    };
 
    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr 5px"}}>                    
                <PageBar title="Invoices">                    
                    <ToolButton onClick={ () => nav(`/facility/${facilityId}/finance/invoice/batch`) }>
                        <EnvelopePaper color="maroon" /> Send Invoices
                    </ToolButton>
                    <ToolButton onClick={ () => nav(`/facility/${facilityId}/finance/invoice/create`) }>
                        <Plus color="maroon" /> Custom Order
                    </ToolButton>
                </PageBar>
                <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid onRowClicked={onRowClicked}
                                rowData={invoices}
                                columnDefs={columns}
                                onGridReady={onGridReady}
                    />
                </div>
            </Col>
        </Row>
    </Container>;
}