import { useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

//spotsync controls
import { AddressModal, AddressAndEntry } from "./FacilityOverviewPageAddressModal";
import { CapacityModal, CapacityForm } from "./FacilityOverviewPageCapacityModal";
import { Hyperlink } from "./Controls/Hyperlink";

//spotsync data
import { showAddressFunc } from "./Data/Location";
import { showMoneyShort } from "./Data/Money";
import { PageTitle } from "./Controls/PageTitle";
import NameDescriptionModal from "./FacilityOverviewPageNameDescriptionModal";
import { NameAndDescription } from "./FacilityOverviewPageNameDescriptionModal";

import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { Facility, FacilityUpdateReq } from "./Data/ApiTypes";
import { Api } from "./Data/Api";
import { Button } from "./Controls/Button";
import { PencilFill } from "react-bootstrap-icons";
import { RateProgramTable } from "./FacilityRateListPage";

export function FacilityListingPage() {
    const nav = useNavigate();
    const [showNameAndDesc, setShowNameAndDesc] = useState( false );
    const [showAddress, setShowAddress] = useState( false );
    const [showCapacity, setShowCapacity] = useState( false );
    const facility = useRouteLoaderData( "facility" ) as Facility;

    const handleNameSave = ( data: NameAndDescription ) => {
        facility.name            = data.name;
        facility.description     = data.description;
        facility.clearanceInches = data.clearanceInches;
        facility.website         = data.website;
        saveFacilityUpdates();
    }

    const handleAddressEntrySave = ( data: AddressAndEntry ) => {
        facility.address.addressLine1 = data.addressLine1;
        facility.address.addressLine2 = data.addressLine2;
        facility.address.city         = data.city;
        facility.address.state        = data.state;
        facility.address.zipCode      = data.zipCode;
        facility.entryInstructions    = data.entry; //fixed a bug!
        saveFacilityUpdates();
    }

    const handleCapacitySave = ( data: CapacityForm ) => {
        facility.enforcedCapacity = data.enforcedCapacity;
        facility.physicalCapacity = data.postedCapacity;
        saveFacilityUpdates();
    }

    const saveFacilityUpdates = () => {
        const updateReq: FacilityUpdateReq = {
            address: facility.address,
            facilityId: facility.facilityId,
            instructions: facility.entryInstructions,
            description: facility.description,
            name: facility.name,
            enforcedCapacity:  Number( facility.enforcedCapacity ),
            postedCapacity:    Number( facility.physicalCapacity ),
            clearanceInches:   facility.clearanceInches,
            website:           facility.website,
            emailAddress:      facility.emailAddress,
            phoneNumber:       facility.phoneNumber,
        }

        Api.facilityUpdate( updateReq ).then( res => {
            //TODO: implement some type of global toast for success and failure scenarios
            if( res.isOk ) {
                console.log( 'Updated Successfully' );
            }
            else {
                console.log( res.error );
            }
        } );
    }

    function Header( { children }: { children: React.ReactNode } ) {
        return <div className="fs-5 fw-bold">{children}</div>;
    }

    return <Container>
        <NameDescriptionModal show={showNameAndDesc}
            setShow={setShowNameAndDesc}
            onSave={handleNameSave}
            initialData={{ name:            facility.name,
                           description:     facility.description,
                           clearanceInches: facility.clearanceInches,
                           website:         facility.website,
                           emailAddress:    facility.emailAddress,
                           phoneNumber:     facility.phoneNumber
                           }} />

        <AddressModal address={facility.address}
            entry={facility.entryInstructions}
            show={showAddress}
            setShow={setShowAddress}
            onSave={handleAddressEntrySave} />

        <CapacityModal show={showCapacity}
            postedCapacity={facility.physicalCapacity}
            enforcedCapacity={facility.enforcedCapacity}
            setShow={setShowCapacity}
            onSave={handleCapacitySave} />

        <Row>
            <Col>                
                <PageTitle>
                    {facility.name}
                </PageTitle>
                <Card className="mx-0 px-0 border-0 bg-white position-relative">
                    <Card.Body className="px-0 pt-0">
                        <Row className="g-4">
                            {/* Left Column */}
                            <Col xl={6}>
                                {/* Name & Description Section */}
                                <Card className="mb-4 shadow">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Header>Name & Description</Header>
                                            <Hyperlink onClick={() => setShowNameAndDesc( true )}>
                                                Edit
                                            </Hyperlink>
                                        </div>
                                        <Row>
                                            <Col md={9}>
                                                <Container fluid className="g-0">
                                                    <Row as="dl" className="mb-0">
                                                        <Col as="dt" sm={4}>Name</Col>
                                                        <Col as="dd" sm={8}>{facility.name}</Col>
                                                        <Col as="dt" sm={4}>Description</Col>
                                                        <Col as="dd" sm={8}>{facility.description}</Col>
                                                        <Col as="dt" sm={4}>Website</Col>
                                                        <Col as="dd" sm={8}>
                                                            <a target="_blank" href={facility.website} rel="noopener noreferrer">
                                                                {facility.website}
                                                            </a>
                                                        </Col>
                                                        <Col as="dt" sm={4}>Email</Col>
                                                        <Col as="dd" sm={8}>{facility.emailAddress}</Col>
                                                        <Col as="dt" sm={4}>Phone</Col>
                                                        <Col as="dd" sm={8}>
                                                            <a href={`tel:${facility.phoneNumber}`}>{facility.phoneNumber}</a>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                            <Col md={3}>
                                                <Card className="bg-white text-center">
                                                    <Card.Body>
                                                        <h3 className="mb-0">77"</h3>
                                                        <small className="text-muted">Bar Height</small>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* Location Section */}
                                <Card className="border-1 shadow bg-white mb-4">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Header>Location</Header>
                                            <Hyperlink onClick={() => setShowAddress( true )}>
                                                Edit
                                            </Hyperlink>
                                        </div>
                                        <dl className="row mb-0">
                                            {showAddressFunc( facility.address, ( s, c, st, z ) => (
                                                <>
                                                    <dt className="col-sm-4">Street Address</dt>
                                                    <dd className="col-sm-8">{s}</dd>
                                                    <dt className="col-sm-4">City</dt>
                                                    <dd className="col-sm-8">{c}</dd>
                                                    <dt className="col-sm-4">State</dt>
                                                    <dd className="col-sm-8">{st}</dd>
                                                    <dt className="col-sm-4">Zip Code</dt>
                                                    <dd className="col-sm-8">{z}</dd>
                                                </>
                                            ) )}
                                            <dt className="col-sm-4">Entry</dt>
                                            <dd className="col-sm-8">{facility.entryInstructions}</dd>
                                        </dl>
                                    </Card.Body>
                                </Card>

                                {/* Capacity Section */}
                                <Card className="border-1 shadow bg-white">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Header>Capacity</Header>
                                            <Hyperlink onClick={() => setShowCapacity( true )}>
                                                Edit
                                            </Hyperlink>
                                        </div>
                                        <Row className="g-3">
                                            <Col sm={6}>
                                                <Card className="bg-white">
                                                    <Card.Body className="text-center">
                                                        <h3 className="mb-0">{facility.physicalCapacity}</h3>
                                                        <small className="text-muted">Posted Capacity</small>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={6}>
                                                <Card className="bg-white">
                                                    <Card.Body className="text-center">
                                                        <h3 className="mb-0">{facility.enforcedCapacity}</h3>
                                                        <small className="text-muted">Enforced Capacity</small>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Right Column */}
                            <Col xl={6}>
                                {/* Plans Section */}
                                <Card className="border-1 shadow mb-3">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Header>Monthly Plans</Header>
                                            <Hyperlink to={`/facility/${facility.facilityId}/plan`}>
                                                Manage
                                            </Hyperlink>
                                        </div>
                                        <Table size="sm" className="mb-4">
                                            <tbody>
                                                {facility.listedPlans.map( plan => (
                                                    <tr key={plan.planEntryId}>
                                                        <td>{plan.name}</td>
                                                        <td className="text-end">{showMoneyShort( plan.price )}</td>
                                                    </tr>
                                                ) )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Card className="shadow">
                                    <Card.Body>
                                        {facility.hourlyRates?.map( prog =>
                                            <div key={prog.rateProgramId}>
                                                <div className="d-flex-align-center gap-2 mb-2">
                                                    <span className="fs-5 fw-bold">
                                                        {prog.name}
                                                    </span>
                                                    <Button variant="sm"
                                                        className="d-flex-align-center"
                                                        style={{ padding: "1px 0.5em 1px 0.5em" }}
                                                        onClick={ () => nav( `/facility/${facility.facilityId}/rate/${prog.rateProgramId}` ) }>
                                                        <PencilFill color="maroon" /> Edit Rate
                                                    </Button>
                                                </div>
                                                <RateProgramTable prog={prog} />
                                            </div> )}
                                        {!facility.hourlyRates?.length && (
                                            <div className="text-center text-muted py-4">
                                                No hourly rates configured
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>;
}
