import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { Session } from "./Data/ApiTypes";
import { colEnum, DataGrid } from "./Controls/DataGrid";
import { SessionType } from "./Data/ApiTransport";
import { PageBar } from "./Controls/PageBar";
import { SimpleButton } from "./Controls/Button";
import { cents } from "./Data/Money";
import { add, equal } from "dinero.js";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";

interface PlateButtonProps {
    className?: string;
    onClick:    () => void;
    children:   React.ReactNode;
}

export function PlateButton( { className = "", onClick, children }: PlateButtonProps ) {    
    return <div style={{ flex: "1 1 0px" }}>
        <SimpleButton variant="sm"
                       className={`font-monospace shadow d-flex-align-center p-1 px-2 justify-content-center w-100`}
                       onClick={onClick}>
            {children}
        </SimpleButton>
    </div>;
}

function PlateRenderer( params: ICellRendererParams<any, string> ) {
    if( !params.value ) { return <></>; }
    var plates = params.value.split( ", " );
    return <div className={`d-flex flex-row py-1 align-content-center gap-2`}>
        {plates.map( p => <PlateButton className="flex-grow-1" onClick={ () => 0 } key={p}>
            {p}
        </PlateButton> )}
    </div>;
}

function showSessionTypeEnum( value: SessionType ): string {
    if( value === SessionType.Subscription       ) { return "Plan Owner"  }
    if( value === SessionType.SubscriptionParker ) { return "Plan Guest"  }
    if( value === SessionType.Reservation        ) { return "Reservation" }
    return "Err!";
}

enum MonthlyParkerTab {
    Applied = "applied", //waiting for approval
    Waiting = "waiting", //waiting for contract
    Active  = "active",  //active
}

const tabs = [
    MonthlyParkerTab.Active,
    MonthlyParkerTab.Applied,
    MonthlyParkerTab.Waiting
];

function showSessionOption( s: Session ): string {
    if( s.type === SessionType.Subscription       ) {
        return s.subscription!.plan.name;
    }
    if( s.type === SessionType.SubscriptionParker ) {
        try {
            return s.subscriptionGuest!.plan.name;
        }        
        catch {
            debugger;
        }
    }
    if( s.type === SessionType.Reservation ) {
        return s.reservation!.rateProgram.name;
    }
    return "Err!";
}

function getPaid( s: Session ): boolean {
    if( s.type === SessionType.Subscription ) {
        const aps = s.subscription!.accessPeriods;
        const totalDebt = aps.map( ap => ap.orderBalance.totalDebt ).reduce( (a, b) => add( a, b ), cents( 0 ) );
        const totalPaid = aps.map( ap => ap.orderBalance.totalPaid ).reduce( (a, b) => add( a, b ), cents( 0 ) );
        if ( equal( totalDebt, totalPaid ) ) {
            return true;
        }
        return false;
    }
    if( s.type === SessionType.SubscriptionParker ) {
        const aps = s.subscriptionGuest!.accessPeriods;
        const totalDebt = aps.map( ap => ap.orderBalance.totalDebt ).reduce( (a, b) => add( a, b ), cents( 0 ) );
        const totalPaid = aps.map( ap => ap.orderBalance.totalPaid ).reduce( (a, b) => add( a, b ), cents( 0 ) );
        if ( equal( totalDebt, totalPaid ) ) {
            return true;
        }
        return false;
    }
    if( s.type === SessionType.Reservation ) {
        return true;
    }
    throw "Err!";
}

function PaidRenderer( params: ICellRendererParams<any, boolean> ) {
    var paid = params.value;
    return <div className="fs-5">
        {paid ? <CheckCircleFill className="text-success" /> : <XCircleFill className="text-danger" />}
    </div>;
}

export function FacilitySessionListPage() {
    const nav  = useNavigate();
    const loc  = useLocation();
    const last = loc.pathname.split( "/" ).pop();
    const tab  = tabs.includes( last as MonthlyParkerTab ) ? last as MonthlyParkerTab : MonthlyParkerTab.Active;

    const { facilityId } = useParamIds();
    const parkers = useLoaderData() as Session[];

    const activeColumns = useMemo<ColDef<Session>[]>(() => [
        { field: "parker.parkerId",     headerName: "ID"                        },
        { field: "parker.name",         headerName: "Name"                      },        
        { ...colEnum( "type", showSessionTypeEnum ),                            },
        { valueGetter: x => showSessionOption( x.data! ), headerName: "Details" },        
        { valueGetter: x => getPaid( x.data! ), headerName: "Paid", cellRenderer: PaidRenderer },
        { 
            valueGetter:  x => x.data!.vehicles,            
            headerName:   "Vehicles",
            type:         "rightAligned",
            cellRenderer: PlateRenderer
        }
    ], [] );

    const onGridReady  = (params: GridReadyEvent) => { params.api.sizeColumnsToFit(); };
    const onRowClicked = ( event: RowClickedEvent<any> ) => {
        const data: Session = event.data;
        if( data.type === SessionType.Subscription ) {
            const sub = data.subscription!;
            nav( `/facility/${facilityId}/parker/${sub.parkerId}/plan/${sub.subscriptionId}`);
        }
        if( data.type === SessionType.SubscriptionParker ) {
            const guest = data.subscriptionGuest!;
            nav( `/facility/${facilityId}/parker/${guest.parkerId}/plan/${guest.subscriptionId}`);
        }
        if( data.type === SessionType.Reservation ) {
            const res = data.reservation!;
            nav( `/facility/${facilityId}/parker/${res.parkerId}/reservation/${res.reservationId}`);
        }
    };
    
    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto 1fr 5px", gridAutoColumns: "1fr" }}>                                
                <PageBar title="Status" />
                <div style={{ height: "100%", width: '100%' }}>
                    <DataGrid
                        rowData={parkers}
                        columnDefs={activeColumns}
                        onGridReady={onGridReady}
                        onRowClicked={onRowClicked}
                    />
                </div>                
            </Col>
        </Row>
    </Container>;
}