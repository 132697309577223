import { CSSProperties, ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

export interface MediumPageProps {
    style?:   CSSProperties;
    children: ReactNode;
}

export function LargePage( { style = {}, children }: MediumPageProps ) {    
    return <Container fluid>
        <Row className="justify-content-center" style={{ ...style } }>
            <Col>
                {children}
            </Col>
        </Row>
    </Container>;
}