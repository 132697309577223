import { forwardRef, ReactNode, useId } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { FieldError, UseFormStateReturn } from "react-hook-form";

type InputType = "text" | "date" | "time" | "month" | "password" | "email" | "number" | "file" | "tel" | "url";

export interface InputProps extends FormControlProps {
    type:            InputType;
    className?:      string;
    inputClassName?: string;
    label:           string;
    icon?:           ReactNode;
    placeholder?:    string;
    explanation?:    string;
    rows?:           number;
    error?:          FieldError | undefined;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export const Input = forwardRef<FormControlElement, InputProps>(
    ( { type,
        className = "mb-3",
        inputClassName = type === "number" ? "text-end" : "", //this feels hacky :/
        label,
        icon,
        error,
        placeholder = "",
        explanation = "",
        rows,
        ...props
    }, ref ) => {
        const inputId = useId();
        const helpId  = `${inputId}-help`;
        return <Form.Group className={className}>
            <Form.Label htmlFor={inputId} className="mb-1">{label}</Form.Label>
            <InputGroup>
                {icon && <InputGroup.Text>
                    {icon}
                </InputGroup.Text>}
                <Form.Control
                    id={inputId}
                    ref={ref}
                    type={type}
                    placeholder={placeholder}
                    rows={rows}
                    aria-describedby={helpId}
                    className={inputClassName}
                    {...props} />
            </InputGroup>
            {explanation &&
                <Form.Text id={helpId} className="d-block text-muted ms-1">
                    {explanation}
                </Form.Text>}
            {error &&
                <Form.Text className="d-block text-danger ms-1">
                    {error.message}
                </Form.Text>}
        </Form.Group>;
    }
);