import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { MoneyInput } from "./Controls/MoneyInput";
import { Money, showMoney } from "./Data/Money";

interface PaymentAdjustModalProps {
    currentAmount: Money;
    show:          boolean;
    setShow:       ( show:       boolean           ) => void;
    onSave:        ( adjustData: PaymentAdjustForm ) => void;
}

export interface PaymentAdjustForm { 
    newAmount: number;
    note:      string;
}

export function PaymentAdjustModal( { currentAmount: remaining, show, setShow, onSave }: PaymentAdjustModalProps ) {
    const { register, watch, setValue, formState: { errors }, reset, handleSubmit } = useForm<PaymentAdjustForm>({
        defaultValues: {
            newAmount: 0,
            note:   "", 
         }
    } );
    
    function submit( data: PaymentAdjustForm ) {
        onSave( data );
        reset();
        setShow(false);
    }

    const amt = watch( "newAmount" );
    const note = watch( "note" );
    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header className="fs-5">
                <HeaderText>
                    Adjust Payment
                </HeaderText>
            </Modal.Header>
            <Modal.Body>
                <p className="p-2 text-secondary">
                    Use this page to set the payment amount to a new value.
                    You should only use this when there was an error in data entry 
                    such as entering 10 when you intended 100 
                </p>
                <Input
                    type="text"
                    label="Note"
                    maxLength={64}
                    placeholder="Accidentally Entered 10 instead of 100"
                    explanation={`Why is an adjustment needed? ${note.length}/64`}
                    {...register( "note", {
                        required: "Note is required"
                    })}
                    error={errors.note} />

                <Input
                    inputClassName="text-end"
                    type="text"
                    label="Current Payment Amount"
                    disabled
                    value={showMoney( remaining )}
                    placeholder=""
                    explanation={`The current value of the payment in the system`} />

                <MoneyInput
                    label="New Amount"
                    inputClassName="text-end"
                    explanation="The payment's amount will be set to this amount"
                    min="0"
                    {...register("newAmount", {
                        valueAsNumber: true,
                        required:      "Amount is required",
                        validate: (value: number) => value != 0 && (!isNaN(value)) || "Please enter a valid number"
                    })}
                    error={errors.newAmount} />
                    
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}