import React from "react";
import { IconProps } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

interface MyIconProps {
    size?:        number;
    strokeWidth?: number;
}

interface MenuItemProps {
    icon:     React.ReactElement<MyIconProps>;
    to:       string;
    text:     string;
    default?: boolean;
    active?:  boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const { active = false } = { ...props };
    const iconWithProps = React.cloneElement<IconProps>( props.icon, {
        size: props.icon.props.size !== undefined ? 24 : props.icon.props.size,
    } );
    return <NavLink to={props.to}
                    className={`facility-menu-item ${active ? "my-active" : "" }`}
                    end={props.default}>
        <div className="grid-col-2 align-self-center d-flex-align-center">
            {iconWithProps}
        </div>
        <div className="grid-col-4 align-self-center d-flex-align-center">
            {props.text}
        </div>
    </NavLink>;
}
