import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { Input } from "./Controls/Input";
import { Select } from "./Controls/Select";
import { SpotSyncTable } from "./Controls/SpotSyncTable";
import { createLocalDate, createLocalTime, showBrowserDate, showBrowserTime, showDuration } from "./Data/Date";
import { cents, Money, showMoney } from "./Data/Money";
import { hours, mins } from "./Data/Duration";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useOptionalParamIds, useParamIds } from "./Data/Common";
import { Duration, LocalDate, LocalTime } from "@js-joda/core";
import { Button } from "./Controls/Button";
import { useLoaderData } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { RateProgram, RateProgramData } from "./Data/ApiTypes";
import { Api, identity } from "./Data/Api";
import { loadResult } from "./Data/Result";
import { MoneyInput } from "./Controls/MoneyInput";

interface DurationRateForm {
    name:      string;
    startDate: string;
    startTime: string;
    endDate:   string | null;
    endTime:   string | null;
    inAfter:   string | null;
    inBefore:  string | null;
    outAfter:  string | null;
    outBefore: string | null;
    upTo:      number; //default to 0
    units:     "minutes" | "hours";
    price:     number;
}

interface RateEntryKeyless {
    upTo: Duration;
    rate: Money;
}

export function FacilityRateDurationPage() {
    const { facilityId } = useParamIds();
    const { rateId     } = useOptionalParamIds();
    const prg = useLoaderData() as RateProgram;
    let initForm: DurationRateForm = {
        units:     "minutes",
        upTo:      0,
        price:     1,
        name:      "",
        startDate: showBrowserDate( LocalDate.now() ),
        startTime: showBrowserTime( LocalTime.now() ),
        endDate:   null,
        endTime:   null,
        inAfter:   null,
        inBefore:  null,
        outAfter:  null,
        outBefore: null
    };
    let initRates: RateEntryKeyless[] = [];
    let verb = "Create";
    if( rateId !== undefined ) {
        verb      = "Edit";
        initRates = [...prg.entries];
        initForm = {
            units:     "hours",
            upTo:      0,
            price:     1,
            name:      prg.name,
            startDate: showBrowserDate( prg.start.toLocalDate() ),
            startTime: showBrowserTime( prg.start.toLocalTime() ),
            endDate:   prg.end ? showBrowserDate( prg.end.toLocalDate() ) : null,
            endTime:   prg.end ? showBrowserTime( prg.end.toLocalTime() ) : null,
            inAfter:   prg.inAfter   ? showBrowserTime( prg.inAfter   ) : null,
            inBefore:  prg.inBefore  ? showBrowserTime( prg.inBefore  ) : null,
            outAfter:  prg.outAfter  ? showBrowserTime( prg.outAfter  ) : null,
            outBefore: prg.outBefore ? showBrowserTime( prg.outBefore ) : null
        };
    }

    const [ msg,   setMsg   ] = useState<string>();
    const [ prog,  setProg  ] = useState<DurationRateForm>  ( initForm  );
    const [ rates, setRates ] = useState<RateEntryKeyless[]>( initRates );

    //form handling
    const frm = useForm<DurationRateForm>( { defaultValues: initForm } );
    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, } = { ...frm };
    const vals = watch(); //watch entire form

    function addRateEntry(): void {
        const toDur = vals.units === "hours" ? hours : mins;
        if( vals.upTo <= 0 ) {
            setError( "upTo", { type: "min", message: "Up To must be greater than 0" } );
            return;
        }
        const findRes = rates.find( r => r.upTo.equals( toDur( vals.upTo ) ) )
        if( findRes ) {
            setError( "upTo", { type: "validate", message: "There is already an entry with that value" } );
            return;
        }
        setRates( [...rates, { upTo: toDur( vals.upTo ), rate: cents( vals.price ) }] );
        setValue( "upTo",  0 );
        setValue( "price", 0 );
    }

    function deleteRate( el: RateEntryKeyless ): void {
        setRates( rates.filter( r => r !== el ) );
    }

    const onSubmit = async ( data: DurationRateForm ) => {
        const req: RateProgramData = {
            facilityId: facilityId,
            name:       data.name,
            start:      createLocalDate( data.startDate ).atTime( createLocalTime( data.startTime ) ),
            end:        data.endDate && data.endTime ? createLocalDate( data.endDate ).atTime( createLocalTime( data.endTime ) ) : null,
            inAfter:    data.inAfter   ? createLocalTime( data.inAfter   ) : null,
            inBefore:   data.inBefore  ? createLocalTime( data.inBefore  ) : null,
            outAfter:   data.outAfter  ? createLocalTime( data.outAfter  ) : null,
            outBefore:  data.outBefore ? createLocalTime( data.outBefore ) : null,
            entries:    rates
        }
        let res: RateProgram | string;
        if( !rateId ) {
            res = loadResult( await Api.facilityRateCreate( facilityId!, req ), identity, identity );
            return;
        }
        res = loadResult( await Api.facilityRateUpdate( facilityId!, rateId!, req ), identity, identity );
        if( res instanceof String ) {
            setMsg( res as string );
        }
    }

    const localDate = showBrowserDate( LocalDate.now() );

    return <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col lg="6">
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }} className="gap-2">
                        <div className="grid-col-span-4 h3">
                            {verb} Rate
                        </div>
                        <Input
                            className="grid-col-span-4"
                            type="text"
                            label="Name"
                            explanation="This name will be shown to parkers on SpotSync"
                            min={localDate}
                            {...register( "name" )} />
                        <Input type="date"
                            label="Start Date"
                            explanation="The start date and time "
                            min={localDate}
                            {...register( "startDate", { required: "Start Date is required" } )}
                            error={errors.startDate} />
                        <Input type="time"
                            label="Start Time"
                            {...register( "startTime", { required: "Start Time is required" } )}
                            error={errors.startTime} />
                        <Input type="date"
                            label="End Date"
                            {...register( "endDate" )}
                            error={errors.endDate} />
                        <Input type="time"
                            label="End Time"
                            {...register( "endTime" )}
                            error={errors.endTime} />
                        <Input type="time"
                            label="In After"
                            // explanation="Optional. The time the parker must be in after to qualify."
                            {...register( "inAfter" )}
                            error={errors.inAfter} />
                        <Input type="time"
                            label="In Before"
                            // explanation="Optional. The time the parker must be in before to qualify."
                            {...register( "inBefore" )}
                            error={errors.inBefore} />
                        <Input type="time"
                            label="Out After"
                            // explanation="Optional. The time the parker must be out after to qualify."
                            {...register( "outAfter" )}
                            error={errors.outAfter} />
                        <Input type="time"
                            label="Out Before"
                            // explanation="Optional. The time the parker must be out before to qualify."
                            {...register( "outBefore" )}
                            error={errors.outBefore} />
                    </div>
                    <div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "25fr 25fr 50fr"
                        }}
                            className="gap-2">
                            <div className="grid-col-span-4 h5">
                                Add Rate Entry
                            </div>

                            <Input type="number"
                                label="Stay Up To"
                                explanation="Time limit"
                                {...register( "upTo", { valueAsNumber: true, required: "Up To is required" } )}
                                error={errors.upTo} />

                            <Select label="Time Units"
                                {...register( "units", { required: true } )}
                                error={errors.units}>                                
                                <option value="minutes">Minutes</option>
                                <option value="hours"  >Hours  </option>
                            </Select>

                            <MoneyInput
                                icon="$"
                                label="Price"
                                {...register( "price", { valueAsNumber: true, required: "Price is required" } )}
                                error={errors.price} />

                            <div className="grid-col-span-2"></div>
                            <Button className="grid-col-span-2" type="button" onClick={addRateEntry}>
                                Add Rate Entry
                            </Button>
                        </div>
                    </div>
                    <PageTitle>
                        Rate Preview
                    </PageTitle>
                    <div className="text-secondary">
                        Add rate entries to fill the duration based rate table
                    </div>
                    {rates.length > 0 && <SpotSyncTable className="mt-3">
                        <thead>
                            <tr>
                                <th className="w-50 text-center">Up To </th>
                                <th className="w-50 text-center">Rate  </th>
                                <th className="w-50 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.sort( ( a, b ) => a.upTo.compareTo( b.upTo ) ).map( el => <tr>
                                <td className="text-center align-middle">
                                    {showDuration( el.upTo )}
                                </td>
                                <td className="text-center align-middle">
                                    {showMoney( el.rate )}
                                </td>
                                <td>
                                    <Button onClick={() => deleteRate( el )}>
                                        Delete
                                    </Button>
                                </td>
                            </tr> )}
                        </tbody>
                    </SpotSyncTable>}
                    {msg && <Alert variant="danger">
                        {msg}
                    </Alert>}
                    <div className="d-flex">
                        <Button className="ms-auto w-50" type="submit">
                            Submit
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    </Container>;
}
