import { useMemo, useState } from "react";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { gridOneCol, useParamIds } from "./Data/Common";
import { colMoney, DataGrid } from "./Controls/DataGrid";
import { Calendar, CheckCircleFill, ExclamationCircleFill } from "react-bootstrap-icons";
import { PageBar } from "./Controls/PageBar";
import { Facility, FacilityInvoice, PlanInvoiceResp } from "./Data/ApiTypes";
import { useForm } from "react-hook-form";
import MonthInput from "./Controls/MonthInput";
import { Select } from "./Controls/Select";
import { YearMonth } from "@js-joda/core";
import { Api } from "./Data/Api";
import { Button } from "./Controls/Button";
import { Button as BsButton } from "react-bootstrap"
import { add, equal } from "dinero.js";
import { cents, Money, showMoney } from "./Data/Money";
import { tab } from "@testing-library/user-event/dist/tab";

function SanityCheckRenderer( params: ICellRendererParams<any, boolean> ) {
    if( params.value === undefined ) { return <></>; }
    const size    = 16;
    const val     = params.value;
    const textCls = params.value ? "text-black" : "text-black";
    return <div className="d-flex align-items-center justify-content-start gap-1">
        {val ? <CheckCircleFill color="green" size={size} className={textCls}  />
              : <ExclamationCircleFill size={size} className={"text-warning"} /> }
        <div className={textCls}>
            {val ? "Correct" : "Review" }
        </div>
    </div>;
}

export function FacilityFinanceInvoiceBatchPage() {
    const nav                   = useNavigate();
    const { facilityId }        = useParamIds();
    const invoices              = useLoaderData() as FacilityInvoice[];
    const facility              = useRouteLoaderData("facilityReport") as Facility;
    const [tab,     setTab    ] = useState<"invoice" | "email">( "invoice" );
    const [consent, setConsent] = useState( false );
    const [preview, setPreview] = useState<PlanInvoiceResp[]>( [] );
    const [results, setResults] = useState<PlanInvoiceResp[]>( [] );

    const { register, watch, handleSubmit, formState: { isValid, errors } } = useForm<InvoiceBatchInitReq>( { defaultValues: {
        serviceStart: YearMonth.now().plusMonths( 1 ).toString()
    } } );

    const vals = watch();
    const plan = facility.listedPlans.find( lp => lp.planId === vals.planId );

    /**
     * Yes this is a very hacky thing that is going on
     */
    function getOrderPriceFromEmail( x: ValueGetterParams<PlanInvoiceResp, Money> ) {
        return cents( parseInt( x.data!.emailArgs.orderPrice.replace( ".", "" ).replace( "$", "" ) ) );
    }

    const invCols = useMemo<ColDef<PlanInvoiceResp>[]>(() => [
        { field: "invoice.parker.name",            headerName: "Parker"                           },
        { ...colMoney( "invoice.openingBalance" ), headerName: "Opening"                          },
        { valueGetter: getOrderPriceFromEmail, valueFormatter: x => showMoney( x.value ), headerName: "Order Price", type: "rightAligned" },
        { ...colMoney( "invoice.endingBalance"  ), headerName: "Ending"                           },
        { ...colMoney( "invoice.amountDue"      ), headerName: "Amount Due"                       },
        { headerName: "Review", cellRenderer: SanityCheckRenderer, valueGetter: x => {
            const d = x.data!.invoice;
            const orderAmt = getOrderPriceFromEmail( x );
            const chk = add( d.openingBalance, orderAmt );
            return equal( chk, d.endingBalance );
        } },
    ], [] );

    const emailCols = useMemo<ColDef<PlanInvoiceResp>[]>( () => [
        { field: "emailArgs.invoiceId",         headerName: "Invoice #"     },
        { field: "emailArgs.invoiceDate",       headerName: "Created"       },        
        { field: "emailArgs.orderDesc",         headerName: "Order Desc"   },
        { field: "emailArgs.accessPeriodStart", headerName: "Svc. Start"    },
        { field: "emailArgs.accessPeriodEnd",   headerName: "Svc. End"      },
        { field: "emailArgs.openingBalance",    headerName: "Opening Bal."  },
        { field: "emailArgs.orderPrice",        headerName: "Order Price"   },
        
        { field: "emailArgs.totalDue",          headerName: "Total Due"     },
        
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    interface InvoiceBatchInitReq {
        facilityId:   number;
        planId:       number;
        serviceStart: string;
    };

    async function submitInit( req: InvoiceBatchInitReq ) {
        const yearMonth = req.serviceStart.split( "-" );
        const year      = parseInt( yearMonth[0] );
        const month     = parseInt( yearMonth[1] );
        const init = await Api.facilityPlanInvoiceInit( facilityId, req.planId, { year: year, month: month } );
        if( init.isOk ) {
            setPreview( init.value );
            return;
        }
        alert( init.error );
    }

    async function submitConfirm() {
        const req = vals;
        if( preview.length === 0 ) {
            alert( "" );
        }
        const yearMonth = req.serviceStart.split( "-" );
        const year      = parseInt( yearMonth[0] );
        const month     = parseInt( yearMonth[1] );
        const confirm = await Api.facilityPlanInvoiceConfirm( facilityId, req.planId, { year:  year, month: month } );
        if( confirm.isOk ) {
            alert( "Invoices Created Successfully" );
            nav( `/facility/${facilityId}/finance/invoice` );
        }
    }

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr"}}>
                <PageBar title="Send Invoices" />
                <Container fluid className="g-0" style={{ ...gridOneCol("max-content 1fr") }}>
                    <Row className="g-2">
                        <Col lg={6}>
                            <Form onSubmit={handleSubmit( submitInit )}>
                                <div className="two-col">
                                    <Select label="Plan"                                            
                                            {...register( "planId", {
                                                required:   "Plan is required",
                                                setValueAs: v => v === "" ? null : parseInt( v )
                                            } )}
                                        error={errors.planId}>
                                        <option value="">Select a Plan</option>
                                        {facility.listedPlans?.map( lp => (
                                            <option key={lp.planId} value={lp.planId}>
                                                {lp.name}
                                            </option>
                                        ) )}
                                    </Select>
                                    <MonthInput
                                        {...register( "serviceStart", { required: "Service Month is required" } )}
                                        label={"Service Month"}                                        
                                        icon={<Calendar />}
                                        placeholder={"12/01/2025"}
                                        error={errors.serviceStart} />
                                    <Button type="button"
                                            className="w-100 mb-2"
                                            onClick={ () => nav(`/facility/${facilityId}/finance/invoice`) }>
                                        Cancel
                                    </Button>
                                    <Button type="submit" className={`w-100 mb-2`}
                                        disabled={!isValid}>
                                        Preview Invoices
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col lg={6} className="d-flex flex-column">
                            {preview.length !== 0 && <div>
                                <p>If you click "Send Invoices" {preview.length} new invoices will be created</p>
                            </div>}
                            <Form.Check
                                className="mb-3"
                                disabled={preview.length === 0}
                                checked={consent}
                                onClick={() => setConsent( !consent )}
                                label="I have reviewed the preview invoices and confirm there are no errors" />
                            <div className="flex-grow-1">

                            </div>
                            <Button className="w-100 mb-2"
                                    onClick={submitConfirm}
                                    disabled={((!consent) || preview.length === 0 || ( !isValid ))}>
                                Send Invoices
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ ...gridOneCol( "max-content 1fr 5px" )}}>
                            <Tabs
                                defaultActiveKey={"invoice"}
                                activeKey={tab}
                                onSelect={ k => setTab( k as any) }
                                className="w-100 border-bottom-0"
                                variant="underline"
                                justify>
                                <Tab eventKey={"invoice"} title="Review Invoices" className="h-100">
                                    <div className="h-100 w-100">
                                        <DataGrid
                                            rowData={preview}
                                            columnDefs={invCols}
                                            onGridReady={onGridReady} />
                                    </div>
                                </Tab>
                                <Tab as={"div"} eventKey={"email"} title={"Review Emails"} className="h-100">
                                    <div className="h-100 w-100">
                                        <DataGrid
                                            rowData={preview}
                                            columnDefs={emailCols}
                                            onGridReady={onGridReady} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}