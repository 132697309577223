import { useMemo, useState } from "react";
import { ColDef, RowClassParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { useLoaderData } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { BalanceChange, Facility } from "./Data/ApiTypes";
import { BalanceChangeType, OrderBalanceType, PaymentType } from "./Data/ApiTransport";
import { showMoney } from "./Data/Money";
import { compare, equal, multiply } from "dinero.js";
import { cents } from "./Data/Money";
import { DataGrid, colInstantDateTime, colNumber } from "./Controls/DataGrid";
import { PageBar } from "./Controls/PageBar";
import { gridOneCol } from "./Data/Common";
import { ZoneId } from "@js-joda/core";
import { toZonedDateTime } from "./Data/Date";

const showPaymentType = ( type: PaymentType ): string => {
    switch( type ) {
        case PaymentType.ImportedPayment       : return 'External Payment';
        case PaymentType.CashPayment           : return 'Cash Payment';
        case PaymentType.CheckPayment          : return 'Check Payment';
        case PaymentType.CheckBounce           : return 'Bounce Check';
        case PaymentType.WriteCheck            : return 'Write Check';
        case PaymentType.ProcessorPayment      : return 'Online Payment';
        case PaymentType.ProcessorRefund       : return 'Online Refund';
        case PaymentType.ProcessorDisputeChange: return 'Dispute';
        case PaymentType.Adjustment            : return 'Adjustment';
        default:
            return 'Unknown Payment Type';
    }
};

const showOrderBalanceType = ( type: OrderBalanceType, pmtId: number | null ): string => {
    switch( type ) {
        case OrderBalanceType.OrderPlaced     : return `Order Placed`;
        case OrderBalanceType.OrderFee        : return `Order Fee`;
        case OrderBalanceType.ApplyPayment    : return `Payment #${pmtId ?? "Err!"} Applied`;
        case OrderBalanceType.UndoApplyPayment: return `Payment #${pmtId ?? "Err!"} Removed`;
        case OrderBalanceType.RefundPayment   : return `Payment #${pmtId ?? "Err!"} Refund`;
        case OrderBalanceType.OrderAdjustment : return `Order Adjustment`;
        default:
            return 'Unknown Balance Type';
    }
};

const srcGetter = ( params: ValueGetterParams<BalanceChange, string> ) => {
    if( !params.data ) { return "Err!"; }
    const rec = params.data as BalanceChange;
    switch( rec.type ) {
        case BalanceChangeType.OrderBalance: {
            return `Order #${rec.orderBalance?.orderId}`;
        }
        case BalanceChangeType.Payment: {
            return `Payment #${rec.payment?.paymentId}`;
        }
        case BalanceChangeType.Invoice: {
            return `Invoice #${rec.invoice?.invoiceId}`;
        }
        default:
            return "Err!";
    }
}

const typeGetter = ( params: ValueFormatterParams<BalanceChange, string> ) => {
    if( !params.data ) { return "Err!"; }
    const rec = params.data as BalanceChange;
    switch( rec.type ) {
        case BalanceChangeType.OrderBalance: {
            const bal = rec.orderBalance!;
            return showOrderBalanceType( bal.type, bal.paymentId );
        }
        case BalanceChangeType.Payment: {
            const pmt = rec.payment!;
            return showPaymentType( pmt.type );
        }
        case BalanceChangeType.Invoice: {
            const inv = rec.invoice!;
            return `Invoice for Order #${inv.orderId}`;
        }
        default:
            return "Err!";
    }
}

const changeGetter = ( params: ValueGetterParams<BalanceChange, any> ) => {
    if( !params.data ) return null;
    switch( params.data.type ) {
        case BalanceChangeType.OrderBalance: {
            const rec = params.data.orderBalance!;
            if( !equal( rec.debt, cents( 0 ) ) ) { return rec.debt; }
            if( !equal( rec.paid, cents( 0 ) ) ) { return multiply( rec.paid, -1 ); }
            break;
        }
        case BalanceChangeType.Payment: {
            const rec = params.data.payment!;
            return multiply( rec.amount, -1 );
        }
        case BalanceChangeType.Invoice: {
            const rec = params.data.invoice!;
            return cents( 0 );
        }
    }
}

const changeFormatter = ( params: ValueFormatterParams<BalanceChange, any> ) => {
    if( !params.data ) return "Err!";
    switch( params.data.type ) {
        case BalanceChangeType.OrderBalance: {
            const rec = params.data.orderBalance!;
            if( !equal( rec.debt, cents( 0 ) ) ) { return `+${showMoney( rec.debt )}`; }
            if( !equal( rec.paid, cents( 0 ) ) ) { return `-${showMoney( rec.paid )}`; }
            return "Err!";
        }
        case BalanceChangeType.Payment: {
            return `-${showMoney( params.data.payment!.amount )}`;
        }
        case BalanceChangeType.Invoice: {
            return `-`;
        }
        default:
            return "Err!";
    }
}

export function FacilityParkerBalanceHistoryPage() {
    const [history,    facility     ] = useLoaderData() as [BalanceChange[], Facility];
    const [showExtras, setShowExtras] = useState( true );
    const zn = ZoneId.of( facility.timeZone );
    
    const paidWhenGetter = ( params: ValueGetterParams<BalanceChange, any> ) => {
        if( !params.data ) { return "Err!"; }
        const rec = params.data as BalanceChange;
        switch( rec.type ) {
            case BalanceChangeType.OrderBalance:
                return toZonedDateTime( rec.orderBalance!.createdWhen, zn ).toLocalDateTime();
            case BalanceChangeType.Payment:
                return toZonedDateTime( rec.payment!.createdWhen, zn ).toLocalDateTime();
            case BalanceChangeType.Invoice:
                return toZonedDateTime( rec.invoice!.createdWhen, zn ).toLocalDateTime();
            default:
                return "Err!";
        }
    }

    const cols = useMemo<ColDef<BalanceChange>[]>( () => [
        { ...colNumber( "number" ), headerName: "Transaction", type: "rightAligned" },
        {
            ...colInstantDateTime( "paidWhen", () => facility.timeZone ),
            headerName: "Date",
            valueGetter: paidWhenGetter
        },
        { valueGetter: srcGetter, headerName: "Type" },
        { field: "type", headerName: "Description", valueFormatter: typeGetter },
        {
            headerName:     "Change",
            type:           "rightAligned",
            comparator:     ( a, b ) => compare( a, b ),
            valueGetter:    changeGetter,
            valueFormatter: changeFormatter
        },
        {
            field:          "balance",
            headerName:     "Balance",
            type:           "rightAligned",
            valueFormatter: ( params ) => showMoney( params.value )
        }
    ], [facility.timeZone] );

    // const filtered = useMemo<BalanceChange[]>( () => {
    //     if( showExtras ) {
    //         return history;
    //     }
    //     //else
    //     return history.filter( h => {
    //         if( h.type === BalanceChangeType.Payment ) { return true; }
    //         if( h.type === BalanceChangeType.OrderBalance && ( false === equal( h.orderBalance!.debt, cents( 0 ) ) ) ) {
    //             return true;
    //         }
    //     } )
    // }, [showExtras, history] );

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col style={gridOneCol( "max-content 1fr 5px" )}>
                    <PageBar title="Balance History">
                        {/* <div className="d-flex">
                            <Form.Switch checked={showExtras}
                                         onChange={ () => setShowExtras( !showExtras )} />
                            <div>
                                Show Extras
                            </div>
                        </div> */}
                    </PageBar>
                    <div style={{ height: "100%", width: '100%' }}>
                        <DataGrid
                            getRowStyle={( x: RowClassParams<BalanceChange> ) => {
                                return x.data!.number % 2 === 0 ? {
                                    borderBottom: "1px solid var(--bs-success)",
                                    backgroundColor: "var(--color-lighter)"
                                } : {
                                    borderBottom: "1px solid var(--bs-success)",
                                    backgroundColor: "inherit"
                                };
                            }}
                            rowData={history}
                            columnDefs={cols}
                            className="font-monospace"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}