import { OrderType } from "./ApiTransport"; 
import { PaymentType } from "./ApiTransport"; 
import { OrderInitiator } from "./ApiTransport"; 
import { OrderBalanceType } from "./ApiTransport"; 
import { SubscriptionStatus } from "./ApiTransport"; 
import { VehicleLinkType } from "./ApiTransport"; 
import { StaffRole } from "./ApiTransport"; 
import { SearchEntityType } from "./ApiTransport"; 
import { AccessKeyLinkType } from "./ApiTransport"; 
import { BalanceChangeType } from "./ApiTransport"; 
import { SessionType } from "./ApiTransport"; import { OperatorApi } from "./ApiTransport"; 
import { FacilityInfoApi } from "./ApiTransport"; 
import { UsAddressApi } from "./ApiTransport"; 
import { CoordinatesApi } from "./ApiTransport"; 
import { OrderDebtApi } from "./ApiTransport"; 
import { VehicleApi } from "./ApiTransport"; 
import { RateProgramApi } from "./ApiTransport"; 
import { RateEntryApi } from "./ApiTransport"; 
import { FacilityImageApi } from "./ApiTransport"; 
import { ListedPlanApi } from "./ApiTransport"; 
import { OrderApi } from "./ApiTransport"; 
import { OrderDetailsApi } from "./ApiTransport"; 
import { OrderBalanceApi } from "./ApiTransport"; 
import { InvoiceApi } from "./ApiTransport"; 
import { PaymentApi } from "./ApiTransport"; 
import { ApplyPaymentApi } from "./ApiTransport"; 
import { OrderInfoApi } from "./ApiTransport"; 
import { PaymentChangeApi } from "./ApiTransport"; 
import { LogInReqApi } from "./ApiTransport"; 
import { ChangeNameReqApi } from "./ApiTransport"; 
import { ChangePhoneReqApi } from "./ApiTransport"; 
import { StripeLinkRespApi } from "./ApiTransport"; 
import { ChangePasswordReqApi } from "./ApiTransport"; 
import { ForgotPasswordReqApi } from "./ApiTransport"; 
import { StaffApi } from "./ApiTransport"; 
import { StaffUpdateReqApi } from "./ApiTransport"; 
import { InviteStaffReqApi } from "./ApiTransport"; 
import { SearchResultApi } from "./ApiTransport"; 
import { PlanCreateReqApi } from "./ApiTransport"; 
import { PlanUpdateReqApi } from "./ApiTransport"; 
import { PlanPriceAddReqApi } from "./ApiTransport"; 
import { PlanPriceListRespApi } from "./ApiTransport"; 
import { ReservationApi } from "./ApiTransport"; 
import { SubscriptionApi } from "./ApiTransport"; 
import { SubscriptionEntryApi } from "./ApiTransport"; 
import { PlanInfoApi } from "./ApiTransport"; 
import { FacilityApi } from "./ApiTransport"; 
import { ParkerApi } from "./ApiTransport"; 
import { ParkerListEntryApi } from "./ApiTransport"; 
import { ParkerDetailsApi } from "./ApiTransport"; 
import { CheckCreateReqApi } from "./ApiTransport"; 
import { PmtApplyApi } from "./ApiTransport"; 
import { PlanApi } from "./ApiTransport"; 
import { PlanDetailsApi } from "./ApiTransport"; 
import { PlanChangeApi } from "./ApiTransport"; 
import { PlanPriceInfoApi } from "./ApiTransport"; 
import { PlanPriceDetailsApi } from "./ApiTransport"; 
import { InviteParkerReqApi } from "./ApiTransport"; 
import { FacilityCreateReqApi } from "./ApiTransport"; 
import { FacilityUpdateReqApi } from "./ApiTransport"; 
import { ParkerAgingRecordApi } from "./ApiTransport"; 
import { ParkerInfoApi } from "./ApiTransport"; 
import { RateProgramDataApi } from "./ApiTransport"; 
import { RateProgramInfoApi } from "./ApiTransport"; 
import { RateEntryDataApi } from "./ApiTransport"; 
import { SubscriberInfoApi } from "./ApiTransport"; 
import { SubscriptionUpdateReqApi } from "./ApiTransport"; 
import { SubEntryApi } from "./ApiTransport"; 
import { SubEntryForEditApi } from "./ApiTransport"; 
import { AccessPeriodInfoApi } from "./ApiTransport"; 
import { SubscriptionParkerApi } from "./ApiTransport"; 
import { ParkerUpdateReqApi } from "./ApiTransport"; 
import { SubListEntryApi } from "./ApiTransport"; 
import { PlanInfoBasicApi } from "./ApiTransport"; 
import { FacilitySubApi } from "./ApiTransport"; 
import { DeleteConfirmApi } from "./ApiTransport"; 
import { ChangeEmailReqApi } from "./ApiTransport"; 
import { VehicleDetailsApi } from "./ApiTransport"; 
import { VehicleLinkApi } from "./ApiTransport"; 
import { FacilityInvoiceApi } from "./ApiTransport"; 
import { ResetCodeCheckReqApi } from "./ApiTransport"; 
import { ResetPasswordReqApi } from "./ApiTransport"; 
import { AccessKeyApi } from "./ApiTransport"; 
import { AccessKeyListEntryApi } from "./ApiTransport"; 
import { AccessKeyLinkInfoApi } from "./ApiTransport"; 
import { SubscriptionInfoApi } from "./ApiTransport"; 
import { AccessKeyReqApi } from "./ApiTransport"; 
import { AccessKeyAssignSubApi } from "./ApiTransport"; 
import { AccessKeyParkerAssignReqApi } from "./ApiTransport"; 
import { AccessKeyVehicleSubAssignReqApi } from "./ApiTransport"; 
import { SubscriptionParkerAccessKeyApi } from "./ApiTransport"; 
import { SubscriptionParkerVehicleAccessKeyApi } from "./ApiTransport"; 
import { FacilityStatsApi } from "./ApiTransport"; 
import { SubscriptionStatApi } from "./ApiTransport"; 
import { ReservationStatApi } from "./ApiTransport"; 
import { OrderStatApi } from "./ApiTransport"; 
import { ParkerSubInfoApi } from "./ApiTransport"; 
import { FacilityPaymentApi } from "./ApiTransport"; 
import { BalanceChangeApi } from "./ApiTransport"; 
import { ImportPaymentReqApi } from "./ApiTransport"; 
import { SessionApi } from "./ApiTransport"; 
import { SessionSubscriptionApi } from "./ApiTransport"; 
import { SessionAccessPeriodApi } from "./ApiTransport"; 
import { PlanInvoiceReqApi } from "./ApiTransport"; 
import { PlanInvoiceRespApi } from "./ApiTransport"; 
import { InvoiceInfoApi } from "./ApiTransport"; 
import { InvoiceArgsApi } from "./ApiTransport"; 
import { SubscriptionParkerInviteReqApi } from "./ApiTransport"; 
import { SubscriptionParkerChangeReqApi } from "./ApiTransport"; 
import { SubscriptionParkerRemoveReqApi } from "./ApiTransport"; 
import { VehicleAddReqApi } from "./ApiTransport"; 
import { VehicleDeleteReqApi } from "./ApiTransport"; 
import { VehicleLinkRespApi } from "./ApiTransport"; 
import { OrderCustomReqApi } from "./ApiTransport"; 
import { OrderCustomItemReqApi } from "./ApiTransport"; 
import { FacilityPayoutApi } from "./ApiTransport"; 
import { PayoutTransactionApi } from "./ApiTransport"; 
import { PayoutTransactionPaymentApi } from "./ApiTransport"; 
import { OrderAdjustReqApi } from "./ApiTransport"; 
import { PaymentAdjustReqApi } from "./ApiTransport"; 
import { PlanBillInitRespApi } from "./ApiTransport"; 
import { PlanBillConfirmRespApi } from "./ApiTransport"; import { Operator } from "./ApiTypes"; 
import { FacilityInfo } from "./ApiTypes"; 
import { UsAddress } from "./ApiTypes"; 
import { Coordinates } from "./ApiTypes"; 
import { OrderDebt } from "./ApiTypes"; 
import { Vehicle } from "./ApiTypes"; 
import { RateProgram } from "./ApiTypes"; 
import { RateEntry } from "./ApiTypes"; 
import { FacilityImage } from "./ApiTypes"; 
import { ListedPlan } from "./ApiTypes"; 
import { Order } from "./ApiTypes"; 
import { OrderDetails } from "./ApiTypes"; 
import { OrderBalance } from "./ApiTypes"; 
import { Invoice } from "./ApiTypes"; 
import { Payment } from "./ApiTypes"; 
import { ApplyPayment } from "./ApiTypes"; 
import { OrderInfo } from "./ApiTypes"; 
import { PaymentChange } from "./ApiTypes"; 
import { LogInReq } from "./ApiTypes"; 
import { ChangeNameReq } from "./ApiTypes"; 
import { ChangePhoneReq } from "./ApiTypes"; 
import { StripeLinkResp } from "./ApiTypes"; 
import { ChangePasswordReq } from "./ApiTypes"; 
import { ForgotPasswordReq } from "./ApiTypes"; 
import { Staff } from "./ApiTypes"; 
import { StaffUpdateReq } from "./ApiTypes"; 
import { InviteStaffReq } from "./ApiTypes"; 
import { SearchResult } from "./ApiTypes"; 
import { PlanCreateReq } from "./ApiTypes"; 
import { PlanUpdateReq } from "./ApiTypes"; 
import { PlanPriceAddReq } from "./ApiTypes"; 
import { PlanPriceListResp } from "./ApiTypes"; 
import { Reservation } from "./ApiTypes"; 
import { Subscription } from "./ApiTypes"; 
import { SubscriptionEntry } from "./ApiTypes"; 
import { PlanInfo } from "./ApiTypes"; 
import { Facility } from "./ApiTypes"; 
import { Parker } from "./ApiTypes"; 
import { ParkerListEntry } from "./ApiTypes"; 
import { ParkerDetails } from "./ApiTypes"; 
import { CheckCreateReq } from "./ApiTypes"; 
import { PmtApply } from "./ApiTypes"; 
import { Plan } from "./ApiTypes"; 
import { PlanDetails } from "./ApiTypes"; 
import { PlanChange } from "./ApiTypes"; 
import { PlanPriceInfo } from "./ApiTypes"; 
import { PlanPriceDetails } from "./ApiTypes"; 
import { InviteParkerReq } from "./ApiTypes"; 
import { FacilityCreateReq } from "./ApiTypes"; 
import { FacilityUpdateReq } from "./ApiTypes"; 
import { ParkerAgingRecord } from "./ApiTypes"; 
import { ParkerInfo } from "./ApiTypes"; 
import { RateProgramData } from "./ApiTypes"; 
import { RateProgramInfo } from "./ApiTypes"; 
import { RateEntryData } from "./ApiTypes"; 
import { SubscriberInfo } from "./ApiTypes"; 
import { SubscriptionUpdateReq } from "./ApiTypes"; 
import { SubEntry } from "./ApiTypes"; 
import { SubEntryForEdit } from "./ApiTypes"; 
import { AccessPeriodInfo } from "./ApiTypes"; 
import { SubscriptionParker } from "./ApiTypes"; 
import { ParkerUpdateReq } from "./ApiTypes"; 
import { SubListEntry } from "./ApiTypes"; 
import { PlanInfoBasic } from "./ApiTypes"; 
import { FacilitySub } from "./ApiTypes"; 
import { DeleteConfirm } from "./ApiTypes"; 
import { ChangeEmailReq } from "./ApiTypes"; 
import { VehicleDetails } from "./ApiTypes"; 
import { VehicleLink } from "./ApiTypes"; 
import { FacilityInvoice } from "./ApiTypes"; 
import { ResetCodeCheckReq } from "./ApiTypes"; 
import { ResetPasswordReq } from "./ApiTypes"; 
import { AccessKey } from "./ApiTypes"; 
import { AccessKeyListEntry } from "./ApiTypes"; 
import { AccessKeyLinkInfo } from "./ApiTypes"; 
import { SubscriptionInfo } from "./ApiTypes"; 
import { AccessKeyReq } from "./ApiTypes"; 
import { AccessKeyAssignSub } from "./ApiTypes"; 
import { AccessKeyParkerAssignReq } from "./ApiTypes"; 
import { AccessKeyVehicleSubAssignReq } from "./ApiTypes"; 
import { SubscriptionParkerAccessKey } from "./ApiTypes"; 
import { SubscriptionParkerVehicleAccessKey } from "./ApiTypes"; 
import { FacilityStats } from "./ApiTypes"; 
import { SubscriptionStat } from "./ApiTypes"; 
import { ReservationStat } from "./ApiTypes"; 
import { OrderStat } from "./ApiTypes"; 
import { ParkerSubInfo } from "./ApiTypes"; 
import { FacilityPayment } from "./ApiTypes"; 
import { BalanceChange } from "./ApiTypes"; 
import { ImportPaymentReq } from "./ApiTypes"; 
import { Session } from "./ApiTypes"; 
import { SessionSubscription } from "./ApiTypes"; 
import { SessionAccessPeriod } from "./ApiTypes"; 
import { PlanInvoiceReq } from "./ApiTypes"; 
import { PlanInvoiceResp } from "./ApiTypes"; 
import { InvoiceInfo } from "./ApiTypes"; 
import { InvoiceArgs } from "./ApiTypes"; 
import { SubscriptionParkerInviteReq } from "./ApiTypes"; 
import { SubscriptionParkerChangeReq } from "./ApiTypes"; 
import { SubscriptionParkerRemoveReq } from "./ApiTypes"; 
import { VehicleAddReq } from "./ApiTypes"; 
import { VehicleDeleteReq } from "./ApiTypes"; 
import { VehicleLinkResp } from "./ApiTypes"; 
import { OrderCustomReq } from "./ApiTypes"; 
import { OrderCustomItemReq } from "./ApiTypes"; 
import { FacilityPayout } from "./ApiTypes"; 
import { PayoutTransaction } from "./ApiTypes"; 
import { PayoutTransactionPayment } from "./ApiTypes"; 
import { OrderAdjustReq } from "./ApiTypes"; 
import { PaymentAdjustReq } from "./ApiTypes"; 
import { PlanBillInitResp } from "./ApiTypes"; 
import { PlanBillConfirmResp } from "./ApiTypes"; 
import { toCents } from "./Money";
import { emitDuration, emitLocalDateTime, emitLocalTime, emitLocalDate, emitInstant } from "./ApiConverters";
import { Duration } from "@js-joda/core";
export function emitOperator(o: Operator): OperatorApi {
    return {
        operatorId: o.operatorId,
        name: o.name,
        address: emitUsAddress( o.address ),
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber,
        website: o.website,
        topTitle: o.topTitle,
        topText: o.topText,
        bottomLeftTitle: o.bottomLeftTitle,
        bottomLeftText: o.bottomLeftText,
        bottomRightTitle: o.bottomRightTitle,
        bottomRightText: o.bottomRightText,
    }
}

export function emitFacilityInfo(f: FacilityInfo): FacilityInfoApi {
    return {
        facilityId: f.facilityId,
        operatorId: f.operatorId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        phoneNumber: f.phoneNumber,
        emailAddress: f.emailAddress,
        clearanceInches: f.clearanceInches,
    }
}

export function emitUsAddress(u: UsAddress): UsAddressApi {
    return {
        addressLine1: u.addressLine1,
        addressLine2: u.addressLine2,
        city: u.city,
        state: u.state,
        zipCode: u.zipCode,
    }
}

export function emitCoordinates(c: Coordinates): CoordinatesApi {
    return {
        lat: c.lat,
        lng: c.lng,
    }
}

export function emitOrderDebt(o: OrderDebt): OrderDebtApi {
    return {
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        orderTotal: toCents( o.orderTotal ),
        paid: toCents( o.paid ),
        unpaid: toCents( o.unpaid ),
    }
}

export function emitVehicle(v: Vehicle): VehicleApi {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
    }
}

export function emitRateProgram(r: RateProgram): RateProgramApi {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => emitRateEntry( el ) ),
    }
}

export function emitRateEntry(r: RateEntry): RateEntryApi {
    return {
        rateEntryId: r.rateEntryId,
        rateProgramId: r.rateProgramId,
        upTo: emitDuration( r.upTo ),
        rate: toCents( r.rate ),
    }
}

export function emitFacilityImage(f: FacilityImage): FacilityImageApi {
    return {
        facilityImageId: f.facilityImageId,
        storedImageId: f.storedImageId,
        orderId: f.orderId,
        caption: f.caption,
        altText: f.altText,
    }
}

export function emitListedPlan(l: ListedPlan): ListedPlanApi {
    return {
        planId: l.planId,
        planEntryId: l.planEntryId,
        name: l.name,
        description: l.description,
        accessHours: l.accessHours,
        dayOfMonthInvoice: l.dayOfMonthInvoice,
        dayOfMonthDue: l.dayOfMonthDue,
        dayOfMonthLate: l.dayOfMonthLate,
        price: toCents( l.price ),
    }
}

export function emitOrder(o: Order): OrderApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        type: o.type,
        facility: emitFacilityInfo( o.facility ),
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitOrderDetails(o: OrderDetails): OrderDetailsApi {
    return {
        order: emitOrder( o.order ),
        balanceChanges: o.balanceChanges.map( el => emitOrderBalance( el ) ),
        summary: o.summary,
    }
}

export function emitOrderBalance(o: OrderBalance): OrderBalanceApi {
    return {
        orderBalanceId: o.orderBalanceId,
        type: o.type,
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        debt: toCents( o.debt ),
        paid: toCents( o.paid ),
        balance: toCents( o.balance ),
        totalDebt: toCents( o.totalDebt ),
        totalPaid: toCents( o.totalPaid ),
        reason: o.reason != null ? o.reason : null,
        paymentId: o.paymentId != null ? o.paymentId : null,
    }
}

export function emitInvoice(i: Invoice): InvoiceApi {
    return {
        invoiceId: i.invoiceId,
        invoiceNumber: i.invoiceNumber,
        orderId: i.orderId,
        createdWhen: emitInstant( i.createdWhen ),
        dueWhen: emitLocalDate( i.dueWhen ),
        openingBalance: toCents( i.openingBalance ),
        amountDue: toCents( i.amountDue ),
        endingBalance: toCents( i.endingBalance ),
        emailSentWhen: i.emailSentWhen != null ? emitInstant( i.emailSentWhen ) : null,
        facility: emitFacilityInfo( i.facility ),
        order: emitOrderInfo( i.order ),
        parker: emitParkerInfo( i.parker ),
    }
}

export function emitPayment(p: Payment): PaymentApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        facility: emitFacilityInfo( p.facility ),
        type: p.type,
        amount: toCents( p.amount ),
        remaining: toCents( p.remaining ),
        unapplied: toCents( p.unapplied ),
        paymentMethod: p.paymentMethod,
        paymentIntentKey: p.paymentIntentKey != null ? p.paymentIntentKey : null,
        chargeKey: p.chargeKey != null ? p.chargeKey : null,
        paymentKey: p.paymentKey != null ? p.paymentKey : null,
        changes: p.changes.map( el => emitPaymentChange( el ) ),
        applications: p.applications.map( el => emitApplyPayment( el ) ),
        createdWhen: emitInstant( p.createdWhen ),
        paidWhen: emitInstant( p.paidWhen ),
    }
}

export function emitApplyPayment(a: ApplyPayment): ApplyPaymentApi {
    return {
        orderBalanceId: a.orderBalanceId,
        type: a.type,
        facilityId: a.facilityId,
        parkerId: a.parkerId,
        orderId: a.orderId,
        debt: toCents( a.debt ),
        paid: toCents( a.paid ),
        balance: toCents( a.balance ),
        totalDebt: toCents( a.totalDebt ),
        totalPaid: toCents( a.totalPaid ),
        createdWhen: emitInstant( a.createdWhen ),
    }
}

export function emitOrderInfo(o: OrderInfo): OrderInfoApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        orderType: o.orderType,
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitPaymentChange(p: PaymentChange): PaymentChangeApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        parkerId: p.parkerId,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitLogInReq(l: LogInReq): LogInReqApi {
    return {
        emailAddress: l.emailAddress,
        password: l.password,
    }
}

export function emitChangeNameReq(c: ChangeNameReq): ChangeNameReqApi {
    return {
        name: c.name,
    }
}

export function emitChangePhoneReq(c: ChangePhoneReq): ChangePhoneReqApi {
    return {
        phoneNumber: c.phoneNumber,
    }
}

export function emitStripeLinkResp(s: StripeLinkResp): StripeLinkRespApi {
    return {
        isOnboarded: s.isOnboarded,
        connectLink: s.connectLink != null ? s.connectLink : null,
    }
}

export function emitChangePasswordReq(c: ChangePasswordReq): ChangePasswordReqApi {
    return {
        currentPassword: c.currentPassword,
        newPassword: c.newPassword,
    }
}

export function emitForgotPasswordReq(f: ForgotPasswordReq): ForgotPasswordReqApi {
    return {
        emailAddress: f.emailAddress,
    }
}

export function emitStaff(s: Staff): StaffApi {
    return {
        staffId: s.staffId,
        role: s.role,
        operatorId: s.operatorId,
        name: s.name,
        note: s.note,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber,
        createdWhen: emitInstant( s.createdWhen ),
        removedWhen: s.removedWhen != null ? emitInstant( s.removedWhen ) : null,
    }
}

export function emitStaffUpdateReq(s: StaffUpdateReq): StaffUpdateReqApi {
    return {
        name: s.name,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber,
        note: s.note,
    }
}

export function emitInviteStaffReq(i: InviteStaffReq): InviteStaffReqApi {
    return {
        role: i.role,
        name: i.name,
        emailAddress: i.emailAddress,
        phoneNumber: i.phoneNumber,
        password: i.password,
        note: i.note,
    }
}

export function emitSearchResult(s: SearchResult): SearchResultApi {
    return {
        searchResultId: s.searchResultId,
        type: s.type,
        id: s.id,
        name: s.name,
        parkerId: s.parkerId != null ? s.parkerId : null,
    }
}

export function emitPlanCreateReq(p: PlanCreateReq): PlanCreateReqApi {
    return {
        facilityId: p.facilityId,
        name: p.name,
        accessHours: p.accessHours,
        description: p.description,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        initialPrice: p.initialPrice,
        terms: p.terms,
    }
}

export function emitPlanUpdateReq(p: PlanUpdateReq): PlanUpdateReqApi {
    return {
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        note: p.note,
        terms: p.terms,
    }
}

export function emitPlanPriceAddReq(p: PlanPriceAddReq): PlanPriceAddReqApi {
    return {
        name: p.name,
        price: toCents( p.price ),
    }
}

export function emitPlanPriceListResp(p: PlanPriceListResp): PlanPriceListRespApi {
    return {
        planPriceId: p.planPriceId,
        name: p.name,
        price: p.price,
    }
}

export function emitReservation(r: Reservation): ReservationApi {
    return {
        reservationId: r.reservationId,
        facilityId: r.facilityId,
        parkerId: r.parkerId,
        start: emitLocalDateTime( r.start ),
        end: emitLocalDateTime( r.end ),
        actualStart: emitInstant( r.actualStart ),
        actualEnd: emitInstant( r.actualEnd ),
        duration: emitDuration( r.duration ),
        rateProgramId: r.rateProgramId,
        price: toCents( r.price ),
        createdWhen: emitInstant( r.createdWhen ),
        rateProgram: emitRateProgramInfo( r.rateProgram ),
    }
}

export function emitSubscription(s: Subscription): SubscriptionApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        start: emitLocalDate( s.start ),
        currentEntry: emitSubscriptionEntry( s.currentEntry ),
        history: s.history.map( el => emitSubscriptionEntry( el ) ),
        facility: emitFacilityInfo( s.facility ),
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
        parkers: s.parkers.map( el => emitSubscriptionParker( el ) ),
        vehicles: s.vehicles.map( el => emitVehicle( el ) ),
        paymentMethod: s.paymentMethod != null ? s.paymentMethod : null,
    }
}

export function emitSubscriptionEntry(s: SubscriptionEntry): SubscriptionEntryApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
        price: toCents( s.price ),
        plan: emitPlanInfo( s.plan ),
    }
}

export function emitPlanInfo(p: PlanInfo): PlanInfoApi {
    return {
        planId: p.planId,
        facilityId: p.facilityId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
    }
}

export function emitFacility(f: Facility): FacilityApi {
    return {
        facilityId: f.facilityId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        physicalCapacity: f.physicalCapacity,
        enforcedCapacity: f.enforcedCapacity,
        clearanceInches: f.clearanceInches,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
        images: f.images.map( el => emitFacilityImage( el ) ),
        hourlyRates: f.hourlyRates.map( el => emitRateProgram( el ) ),
        listedPlans: f.listedPlans.map( el => emitListedPlan( el ) ),
    }
}

export function emitParker(p: Parker): ParkerApi {
    return {
        parkerId: p.parkerId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        note: p.note,
    }
}

export function emitParkerListEntry(p: ParkerListEntry): ParkerListEntryApi {
    return {
        parkerId: p.parkerId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        note: p.note,
        balance: toCents( p.balance ),
        monthly: toCents( p.monthly ),
    }
}

export function emitParkerDetails(p: ParkerDetails): ParkerDetailsApi {
    return {
        parkerId: p.parkerId,
        name: p.name,
        note: p.note,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        balance: toCents( p.balance ),
        subscriptions: p.subscriptions.map( el => emitParkerSubInfo( el ) ),
        reservations: p.reservations.map( el => emitReservation( el ) ),
        vehicles: p.vehicles.map( el => emitVehicle( el ) ),
    }
}

export function emitCheckCreateReq(c: CheckCreateReq): CheckCreateReqApi {
    return {
        checkDate: emitLocalDate( c.checkDate ),
        checkNumber: c.checkNumber,
        checkMemo: c.checkMemo,
        amount: toCents( c.amount ),
    }
}

export function emitPmtApply(p: PmtApply): PmtApplyApi {
    return {
        orderId: p.orderId,
        amount: toCents( p.amount ),
    }
}

export function emitPlan(p: Plan): PlanApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => emitPlanPriceDetails( el ) ),
        note: p.note,
    }
}

export function emitPlanDetails(p: PlanDetails): PlanDetailsApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        terms: p.terms,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => emitPlanPriceDetails( el ) ),
        history: p.history.map( el => emitPlanChange( el ) ),
    }
}

export function emitPlanChange(p: PlanChange): PlanChangeApi {
    return {
        planEntryId: p.planEntryId,
        planId: p.planId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? emitPlanPriceInfo( p.planPrice ) : null,
        createdWhen: emitInstant( p.createdWhen ),
        createdByStaffId: p.createdByStaffId,
        note: p.note,
    }
}

export function emitPlanPriceInfo(p: PlanPriceInfo): PlanPriceInfoApi {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: toCents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitPlanPriceDetails(p: PlanPriceDetails): PlanPriceDetailsApi {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: toCents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: emitInstant( p.createdWhen ),
        subscribers: p.subscribers.map( el => emitSubscriberInfo( el ) ),
    }
}

export function emitInviteParkerReq(i: InviteParkerReq): InviteParkerReqApi {
    return {
        name: i.name,
        emailAddress: i.emailAddress,
        phoneNumber: i.phoneNumber != null ? i.phoneNumber : null,
        planId: i.planId,
        planPriceId: i.planPriceId,
        quantity: i.quantity,
        message: i.message,
    }
}

export function emitFacilityCreateReq(f: FacilityCreateReq): FacilityCreateReqApi {
    return {
        name: f.name,
        address: emitUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
        clearanceInches: f.clearanceInches,
        imageThumb: f.imageThumb,
        bannerImage: f.bannerImage,
    }
}

export function emitFacilityUpdateReq(f: FacilityUpdateReq): FacilityUpdateReqApi {
    return {
        facilityId: f.facilityId,
        name: f.name,
        address: emitUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
        clearanceInches: f.clearanceInches,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
    }
}

export function emitParkerAgingRecord(p: ParkerAgingRecord): ParkerAgingRecordApi {
    return {
        parker: emitParkerInfo( p.parker ),
        orderId: p.orderId,
        days30: p.days30,
        days60: p.days60,
        days90: p.days90,
    }
}

export function emitParkerInfo(p: ParkerInfo): ParkerInfoApi {
    return {
        parkerId: p.parkerId,
        operatorId: p.operatorId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitRateProgramData(r: RateProgramData): RateProgramDataApi {
    return {
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => emitRateEntryData( el ) ),
    }
}

export function emitRateProgramInfo(r: RateProgramInfo): RateProgramInfoApi {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
    }
}

export function emitRateEntryData(r: RateEntryData): RateEntryDataApi {
    return {
        upTo: emitDuration( r.upTo ),
        rate: toCents( r.rate ),
    }
}

export function emitSubscriberInfo(s: SubscriberInfo): SubscriberInfoApi {
    return {
        parkerId: s.parkerId,
        name: s.name,
        note: s.note,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber != null ? s.phoneNumber : null,
        quantity: s.quantity,
    }
}

export function emitSubscriptionUpdateReq(s: SubscriptionUpdateReq): SubscriptionUpdateReqApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
    }
}

export function emitSubEntry(s: SubEntry): SubEntryApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: emitInstant( s.createdWhen ),
    }
}

export function emitSubEntryForEdit(s: SubEntryForEdit): SubEntryForEditApi {
    return {
        prices: s.prices.map( el => emitPlanPriceInfo( el ) ),
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: emitInstant( s.createdWhen ),
    }
}

export function emitAccessPeriodInfo(a: AccessPeriodInfo): AccessPeriodInfoApi {
    return {
        accessPeriodId: a.accessPeriodId,
        subscriptionEntryId: a.subscriptionEntryId,
        quantity: a.quantity,
        start: emitLocalDate( a.start ),
        end: emitLocalDate( a.end ),
        isDelivered: a.isDelivered,
        price: toCents( a.price ),
    }
}

export function emitSubscriptionParker(s: SubscriptionParker): SubscriptionParkerApi {
    return {
        parker: emitParkerInfo( s.parker ),
        quantity: s.quantity,
        vehicles: s.vehicles.map( el => emitVehicle( el ) ),
    }
}

export function emitParkerUpdateReq(p: ParkerUpdateReq): ParkerUpdateReqApi {
    return {
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        note: p.note,
    }
}

export function emitSubListEntry(s: SubListEntry): SubListEntryApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        quantity: s.quantity,
        unitPrice: s.unitPrice != null ? s.unitPrice : null,
        price: s.price != null ? s.price : null,
        plan: emitPlanInfoBasic( s.plan ),
        facility: emitFacilityInfo( s.facility ),
    }
}

export function emitPlanInfoBasic(p: PlanInfoBasic): PlanInfoBasicApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        accessHours: p.accessHours,
    }
}

export function emitFacilitySub(f: FacilitySub): FacilitySubApi {
    return {
        subscriptionId: f.subscriptionId,
        parkerId: f.parkerId,
        status: f.status,
        quantity: f.quantity,
        unitPrice: toCents( f.unitPrice ),
        price: toCents( f.price ),
        createdWhen: emitInstant( f.createdWhen ),
        parker: emitParkerInfo( f.parker ),
        plan: emitPlanInfoBasic( f.plan ),
        facility: emitFacilityInfo( f.facility ),
    }
}

export function emitDeleteConfirm(d: DeleteConfirm): DeleteConfirmApi {
    return {
        deleteCode: d.deleteCode,
    }
}

export function emitChangeEmailReq(c: ChangeEmailReq): ChangeEmailReqApi {
    return {
        currentPassword: c.currentPassword,
        newEmailAddress: c.newEmailAddress,
    }
}

export function emitVehicleDetails(v: VehicleDetails): VehicleDetailsApi {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
        links: v.links.map( el => emitVehicleLink( el ) ),
    }
}

export function emitVehicleLink(v: VehicleLink): VehicleLinkApi {
    return {
        type: v.type,
        reservationId: v.reservationId != null ? v.reservationId : null,
        subscriptionId: v.subscriptionId != null ? v.subscriptionId : null,
        reservation: v.reservation != null ? emitReservation( v.reservation ) : null,
        subscription: v.subscription != null ? emitSubListEntry( v.subscription ) : null,
    }
}

export function emitFacilityInvoice(f: FacilityInvoice): FacilityInvoiceApi {
    return {
        invoiceId: f.invoiceId,
        invoiceNumber: f.invoiceNumber,
        orderId: f.orderId,
        createdWhen: emitInstant( f.createdWhen ),
        dueWhen: emitLocalDate( f.dueWhen ),
        openingBalance: toCents( f.openingBalance ),
        amountDue: toCents( f.amountDue ),
        endingBalance: toCents( f.endingBalance ),
        emailSentWhen: f.emailSentWhen != null ? emitInstant( f.emailSentWhen ) : null,
        order: emitOrderInfo( f.order ),
        parker: emitParkerInfo( f.parker ),
    }
}

export function emitResetCodeCheckReq(r: ResetCodeCheckReq): ResetCodeCheckReqApi {
    return {
        resetPasswordCode: r.resetPasswordCode,
    }
}

export function emitResetPasswordReq(r: ResetPasswordReq): ResetPasswordReqApi {
    return {
        resetPasswordCode: r.resetPasswordCode,
        newPassword: r.newPassword,
    }
}

export function emitAccessKey(a: AccessKey): AccessKeyApi {
    return {
        accessKeyId: a.accessKeyId,
        facilityId: a.facilityId,
        accessKeyNumber: a.accessKeyNumber,
        createdWhen: emitInstant( a.createdWhen ),
        createdByStaff: emitStaff( a.createdByStaff ),
        note: a.note,
        facility: emitFacilityInfo( a.facility ),
        links: a.links.map( el => emitAccessKeyLinkInfo( el ) ),
    }
}

export function emitAccessKeyListEntry(a: AccessKeyListEntry): AccessKeyListEntryApi {
    return {
        accessKeyId: a.accessKeyId,
        accessKeyNumber: a.accessKeyNumber,
        createdWhen: emitInstant( a.createdWhen ),
        note: a.note,
    }
}

export function emitAccessKeyLinkInfo(a: AccessKeyLinkInfo): AccessKeyLinkInfoApi {
    return {
        accessKeyLinkId: a.accessKeyLinkId,
        accessKeyId: a.accessKeyId,
        type: a.type,
        createdWhen: emitInstant( a.createdWhen ),
        note: a.note,
        subscription: a.subscription != null ? emitSubscriptionInfo( a.subscription ) : null,
        guest: a.guest != null ? emitSubscriptionParkerAccessKey( a.guest ) : null,
        vehicle: a.vehicle != null ? emitSubscriptionParkerVehicleAccessKey( a.vehicle ) : null,
    }
}

export function emitSubscriptionInfo(s: SubscriptionInfo): SubscriptionInfoApi {
    return {
        subscriptionId: s.subscriptionId,
        status: s.status,
        start: emitLocalDate( s.start ),
        parker: emitParkerInfo( s.parker ),
    }
}

export function emitAccessKeyReq(a: AccessKeyReq): AccessKeyReqApi {
    return {
        accessKeyNumber: a.accessKeyNumber,
        note: a.note,
    }
}

export function emitAccessKeyAssignSub(a: AccessKeyAssignSub): AccessKeyAssignSubApi {
    return {
        accessKeyId: a.accessKeyId,
        subscriptionId: a.subscriptionId,
        note: a.note != null ? a.note : null,
    }
}

export function emitAccessKeyParkerAssignReq(a: AccessKeyParkerAssignReq): AccessKeyParkerAssignReqApi {
    return {
        accessKeyId: a.accessKeyId,
        subscriptionParkerId: a.subscriptionParkerId,
        note: a.note != null ? a.note : null,
    }
}

export function emitAccessKeyVehicleSubAssignReq(a: AccessKeyVehicleSubAssignReq): AccessKeyVehicleSubAssignReqApi {
    return {
        accessKeyId: a.accessKeyId,
        vehicleSubscriptionId: a.vehicleSubscriptionId,
        note: a.note != null ? a.note : null,
    }
}

export function emitSubscriptionParkerAccessKey(s: SubscriptionParkerAccessKey): SubscriptionParkerAccessKeyApi {
    return {
        subscription: emitSubscriptionInfo( s.subscription ),
        parker: emitParkerInfo( s.parker ),
    }
}

export function emitSubscriptionParkerVehicleAccessKey(s: SubscriptionParkerVehicleAccessKey): SubscriptionParkerVehicleAccessKeyApi {
    return {
        isPrimary: s.isPrimary,
        subscription: emitSubscriptionInfo( s.subscription ),
        parker: emitParkerInfo( s.parker ),
        vehicle: emitVehicle( s.vehicle ),
    }
}

export function emitFacilityStats(f: FacilityStats): FacilityStatsApi {
    return {
        totalMonthlyParkerCount: f.totalMonthlyParkerCount,
        pendingParkers: f.pendingParkers,
        activeReservations: f.activeReservations,
        upcomingReservations: f.upcomingReservations,
        monthlyRecurringRevenue: f.monthlyRecurringRevenue,
        currentMonthTransientRevenue: f.currentMonthTransientRevenue,
        subscriptions: f.subscriptions.map( el => emitSubscriptionStat( el ) ),
        reservations: f.reservations.map( el => emitReservationStat( el ) ),
        orderStats: f.orderStats.map( el => emitOrderStat( el ) ),
    }
}

export function emitSubscriptionStat(s: SubscriptionStat): SubscriptionStatApi {
    return {
        status: s.status,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        qty: s.qty,
        unit: toCents( s.unit ),
        total: toCents( s.total ),
    }
}

export function emitReservationStat(r: ReservationStat): ReservationStatApi {
    return {
        start: emitInstant( r.start ),
        end: emitInstant( r.end ),
        price: toCents( r.price ),
    }
}

export function emitOrderStat(o: OrderStat): OrderStatApi {
    return {
        created: emitInstant( o.created ),
        price: toCents( o.price ),
        type: o.type,
        isPaid: o.isPaid,
    }
}

export function emitParkerSubInfo(p: ParkerSubInfo): ParkerSubInfoApi {
    return {
        subscriptionId: p.subscriptionId,
        parkerId: p.parkerId,
        status: p.status,
        quantity: p.quantity,
        unitPrice: toCents( p.unitPrice ),
        price: toCents( p.price ),
        createdWhen: emitInstant( p.createdWhen ),
        guests: p.guests.map( el => emitSubscriptionParker( el ) ),
        plan: emitPlanInfoBasic( p.plan ),
    }
}

export function emitFacilityPayment(f: FacilityPayment): FacilityPaymentApi {
    return {
        paymentId: f.paymentId,
        parentPaymentId: f.parentPaymentId != null ? f.parentPaymentId : null,
        type: f.type,
        amount: toCents( f.amount ),
        balance: toCents( f.balance ),
        remaining: toCents( f.remaining ),
        paymentMethod: f.paymentMethod,
        paymentIntentKey: f.paymentIntentKey != null ? f.paymentIntentKey : null,
        chargeKey: f.chargeKey != null ? f.chargeKey : null,
        createdWhen: emitInstant( f.createdWhen ),
        parker: emitParkerInfo( f.parker ),
        changes: f.changes.map( el => emitPaymentChange( el ) ),
        applications: f.applications.map( el => emitApplyPayment( el ) ),
    }
}

export function emitBalanceChange(b: BalanceChange): BalanceChangeApi {
    return {
        number: b.number,
        type: b.type,
        paidWhen: emitInstant( b.paidWhen ),
        payment: b.payment != null ? emitPayment( b.payment ) : null,
        orderBalance: b.orderBalance != null ? emitOrderBalance( b.orderBalance ) : null,
        invoice: b.invoice != null ? emitInvoiceInfo( b.invoice ) : null,
        rank: b.rank,
        balance: toCents( b.balance ),
    }
}

export function emitImportPaymentReq(i: ImportPaymentReq): ImportPaymentReqApi {
    return {
        note: i.note,
        amount: toCents( i.amount ),
    }
}

export function emitSession(s: Session): SessionApi {
    return {
        parker: emitParkerInfo( s.parker ),
        type: s.type,
        subscription: s.subscription != null ? emitSessionSubscription( s.subscription ) : null,
        subscriptionGuest: s.subscriptionGuest != null ? emitSessionSubscription( s.subscriptionGuest ) : null,
        reservation: s.reservation != null ? emitReservation( s.reservation ) : null,
        vehicles: s.vehicles,
    }
}

export function emitSessionSubscription(s: SessionSubscription): SessionSubscriptionApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        guests: s.guests.map( el => emitSubscriptionParker( el ) ),
        plan: emitPlanInfoBasic( s.plan ),
        accessPeriods: s.accessPeriods.map( el => emitSessionAccessPeriod( el ) ),
    }
}

export function emitSessionAccessPeriod(s: SessionAccessPeriod): SessionAccessPeriodApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        quantity: s.quantity,
        price: toCents( s.price ),
        createdWhen: emitInstant( s.createdWhen ),
        guests: s.guests.map( el => emitSubscriptionParker( el ) ),
        plan: emitPlanInfoBasic( s.plan ),
        orderBalance: emitOrderBalance( s.orderBalance ),
    }
}

export function emitPlanInvoiceReq(p: PlanInvoiceReq): PlanInvoiceReqApi {
    return {
        year: p.year,
        month: p.month,
    }
}

export function emitPlanInvoiceResp(p: PlanInvoiceResp): PlanInvoiceRespApi {
    return {
        invoice: emitInvoiceInfo( p.invoice ),
        emailArgs: emitInvoiceArgs( p.emailArgs ),
    }
}

export function emitInvoiceInfo(i: InvoiceInfo): InvoiceInfoApi {
    return {
        invoiceId: i.invoiceId,
        invoiceNumber: i.invoiceNumber,
        orderId: i.orderId,
        createdWhen: emitInstant( i.createdWhen ),
        dueWhen: emitLocalDate( i.dueWhen ),
        openingBalance: toCents( i.openingBalance ),
        amountDue: toCents( i.amountDue ),
        endingBalance: toCents( i.endingBalance ),
        emailSentWhen: i.emailSentWhen != null ? emitInstant( i.emailSentWhen ) : null,
        parker: emitParkerInfo( i.parker ),
    }
}

export function emitInvoiceArgs(i: InvoiceArgs): InvoiceArgsApi {
    return {
        facilityName: i.facilityName,
        parkerName: i.parkerName,
        invoiceId: i.invoiceId,
        invoiceDate: i.invoiceDate,
        accessPeriodStart: i.accessPeriodStart,
        accessPeriodEnd: i.accessPeriodEnd,
        openingBalance: i.openingBalance,
        orderDesc: i.orderDesc,
        orderPrice: i.orderPrice,
        totalDue: i.totalDue,
        staffName: i.staffName,
        staffEmail: i.staffEmail,
        link: i.link,
        linkText: i.linkText,
    }
}

export function emitSubscriptionParkerInviteReq(s: SubscriptionParkerInviteReq): SubscriptionParkerInviteReqApi {
    return {
        subscriptionId: s.subscriptionId,
        quantity: s.quantity,
        name: s.name,
        emailAddress: s.emailAddress,
    }
}

export function emitSubscriptionParkerChangeReq(s: SubscriptionParkerChangeReq): SubscriptionParkerChangeReqApi {
    return {
        parkerId: s.parkerId,
        quantity: s.quantity,
    }
}

export function emitSubscriptionParkerRemoveReq(s: SubscriptionParkerRemoveReq): SubscriptionParkerRemoveReqApi {
    return {
        parkerId: s.parkerId,
    }
}

export function emitVehicleAddReq(v: VehicleAddReq): VehicleAddReqApi {
    return {
        vehicleId: v.vehicleId,
    }
}

export function emitVehicleDeleteReq(v: VehicleDeleteReq): VehicleDeleteReqApi {
    return {
        vehicleId: v.vehicleId,
    }
}

export function emitVehicleLinkResp(v: VehicleLinkResp): VehicleLinkRespApi {
    return {
        vehicleLinkId: v.vehicleLinkId,
        vehicleId: v.vehicleId,
        type: v.type,
        subscriptionId: v.subscriptionId,
    }
}

export function emitOrderCustomReq(o: OrderCustomReq): OrderCustomReqApi {
    return {
        name: o.name,
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber != null ? o.phoneNumber : null,
        description: o.description,
        items: o.items.map( el => emitOrderCustomItemReq( el ) ),
        dueDate: emitLocalDate( o.dueDate ),
    }
}

export function emitOrderCustomItemReq(o: OrderCustomItemReq): OrderCustomItemReqApi {
    return {
        category: o.category != null ? o.category : null,
        name: o.name,
        unitPrice: o.unitPrice,
        quantity: o.quantity,
    }
}

export function emitFacilityPayout(f: FacilityPayout): FacilityPayoutApi {
    return {
        payoutId: f.payoutId,
        facilityId: f.facilityId,
        payoutKey: f.payoutKey,
        amount: toCents( f.amount ),
        createdWhen: emitInstant( f.createdWhen ),
        processorCreatedWhen: emitInstant( f.processorCreatedWhen ),
        arrivalWhen: emitInstant( f.arrivalWhen ),
        transactions: f.transactions.map( el => emitPayoutTransaction( el ) ),
    }
}

export function emitPayoutTransaction(p: PayoutTransaction): PayoutTransactionApi {
    return {
        payoutTransactionId: p.payoutTransactionId,
        payoutId: p.payoutId,
        balanceTransactionKey: p.balanceTransactionKey,
        type: p.type,
        key: p.key,
        paymentIntentKey: p.paymentIntentKey != null ? p.paymentIntentKey : null,
        chargeKey: p.chargeKey != null ? p.chargeKey : null,
        refundKey: p.refundKey != null ? p.refundKey : null,
        disputeKey: p.disputeKey != null ? p.disputeKey : null,
        payment: p.payment != null ? emitPayoutTransactionPayment( p.payment ) : null,
        gross: toCents( p.gross ),
        fee: toCents( p.fee ),
        net: toCents( p.net ),
        createdWhen: emitInstant( p.createdWhen ),
        processorCreatedWhen: emitInstant( p.processorCreatedWhen ),
        availableWhen: emitInstant( p.availableWhen ),
    }
}

export function emitPayoutTransactionPayment(p: PayoutTransactionPayment): PayoutTransactionPaymentApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        createdWhen: emitInstant( p.createdWhen ),
        parker: emitParkerInfo( p.parker ),
        applications: p.applications.map( el => emitApplyPayment( el ) ),
    }
}

export function emitOrderAdjustReq(o: OrderAdjustReq): OrderAdjustReqApi {
    return {
        orderId: o.orderId,
        currentTotalDebt: toCents( o.currentTotalDebt ),
        newTotalDebt: toCents( o.newTotalDebt ),
        reason: o.reason,
    }
}

export function emitPaymentAdjustReq(p: PaymentAdjustReq): PaymentAdjustReqApi {
    return {
        paymentId: p.paymentId,
        currentAmount: toCents( p.currentAmount ),
        newAmount: toCents( p.newAmount ),
        note: p.note,
    }
}

export function emitPlanBillInitResp(p: PlanBillInitResp): PlanBillInitRespApi {
    return {
        parker: emitParkerInfo( p.parker ),
        order: emitOrderInfo( p.order ),
    }
}

export function emitPlanBillConfirmResp(p: PlanBillConfirmResp): PlanBillConfirmRespApi {
    return {
        parker: emitParkerInfo( p.parker ),
        order: emitOrderInfo( p.order ),
        success: p.success,
        amountPaid: toCents( p.amountPaid ),
        error: p.error,
    }
}


