import { Button } from "./Button";
import { Duration, LocalDateTime } from "@js-joda/core";
import { Card, Container } from "react-bootstrap";
import { facilityImg } from "../Data/Common";
import { CardImage } from "react-bootstrap-icons";

interface FacilityHourlyDetailsProps {
    image:             string | undefined;
    type:              string;
    name:              string;
    entryInstructions: string;
}

export function FacilityHourlyDetails(
    { image,
      name = "Enter Facility Name",
      type,
      entryInstructions } : FacilityHourlyDetailsProps ) {
    return <div>
        {/* <img className="rounded-1"
            src={image}
            width="100%"
            style={{ aspectRatio: "16 / 11", objectFit: "cover" }} />
         */}
        {image ? <img className="facility-result-pic"
             src={image}
             style={{ aspectRatio: "16 / 11", objectFit: "cover", minWidth: "100px" }}
             /> : <CardImage width={"100%"} height={"100"} style={{ aspectRatio: "16 / 11", objectFit: "cover" }} />}

        <Container className="my-2">
            <div>
                <div className="my-3">
                    <div className="text-center fs-4">
                        {name}
                    </div>
                </div>
            </div>
        </Container>
    </div>;
};