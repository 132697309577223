import { useMemo } from "react";
import { Badge, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { cents, Money, showMoney } from "./Data/Money";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { FacilitySub, ParkerDetails, ParkerListEntry, ParkerSubInfo, Reservation, Vehicle } from "./Data/ApiTypes";
import { SubscriptionStatus } from "./Data/ApiTransport";
import { Asterisk } from "react-bootstrap-icons";
import { colInstantDateTime, colMoney, colNumber, DataGrid } from "./Controls/DataGrid";
import { add } from "dinero.js";
import { showSubscriptionStatus } from "./Data/Subscription";
import { ToolButton } from "./Controls/PageBar";

enum MonthlyParkerTab {
    Applied = "applied", //waiting for approval
    Waiting = "waiting", //waiting for contract
    Active  = "active",  //active
}

export interface GridParker {
    parkerId:      number;
    name:          string;
    note:          string;
    emailAddress:  string;
    phoneNumber:   string | null;
    guests:        number;
    spots:         number;
    balance:       Money;
    mrr:           Money;
    subscriptions: ParkerSubInfo[];
    reservations:  Reservation[];
    vehicles:      Vehicle[];
}

const tabs = [
    MonthlyParkerTab.Active,
    MonthlyParkerTab.Applied,
    MonthlyParkerTab.Waiting
];

export function FacilityParkerListPage() {
    const nav  = useNavigate();
    const loc  = useLocation();
    const last = loc.pathname.split( "/" ).pop();
    const tab  = tabs.includes( last as MonthlyParkerTab ) ? last as MonthlyParkerTab : MonthlyParkerTab.Active;

    const { facilityId }      = useParamIds();    
    const [ parkers, subs   ] = useLoaderData() as [ParkerDetails[], FacilitySub[]];

    const spotCount = ( p: ParkerDetails ) =>        
        p.subscriptions.map( s => s.quantity ).reduce( ( a, b ) => a + b, 0 );

    const guestCount = ( p: ParkerDetails ) =>
        p.subscriptions.map( s => s.guests.length ).reduce( ( a, b ) => a + b, 0 );

    const mrr = ( p: ParkerDetails ) =>
        p.subscriptions.map( s => s.price ).reduce( (a, b) => add( a, b ), cents( 0 ) );

    const activeParkers = useMemo( () => {
        const innerParkers: GridParker[] = [];
        for( const parker of parkers ) {
            const p: GridParker = {
                ...parker,
                guests:  guestCount( parker ),
                spots:   spotCount( parker ),
                balance: parker.balance,
                mrr:     mrr( parker ),
            }
            innerParkers.push( p );
        }
        return innerParkers;
    }, [parkers] );

    const activeColumns = useMemo<ColDef<GridParker>[]>(() => [
        { field: "parkerId",     headerName: "#"    },
        { field: "name",         headerName: "Name"  },
        // { field: "emailAddress", headerName: "Email" },
        { valueGetter: x => x.data?.subscriptions?.length, headerName: "Plans", type: "rightAligned" },
        { ...colNumber( "guests" ), headerName: "Guests" },
        { ...colNumber( "spots"  ), },
        { ...colMoney( "balance" ), },
        { ...colMoney( "mrr"     ), headerName: "MRR"  },
        { field: "note",            headerName: "Note" },
    ], []);

    const appliedColumns = useMemo<ColDef<FacilitySub>[]>(() => [
        { field: "parker.parkerId",     headerName: "#"     },
        { field: "parker.name",         headerName: "Name"  },
        { field: "parker.emailAddress", headerName: "Email" },
        { field: "quantity", headerName: "Spots" },
        { ...colInstantDateTime( "createdWhen", x => x.facility.timeZone ), headerName: "Created Date" },
        {
            field: "unitPrice",
            headerName: "Unit Price",
            type: "rightAligned",
            valueFormatter: x => showMoney( x.value )
        },
        {
            field: "price",
            headerName: "Total Price",
            type: "rightAligned",
            valueFormatter: x => showMoney( x.value )
        },        
        {
            field: "plan.name",
            headerName: "Plan",
            valueGetter: (params) => params.data?.plan?.name || ''
        },
    ], [] );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: RowClickedEvent<any> ) => {
        //fixed clicking the footer row
        if( event.rowPinned ) {
            return;
        }
        if( tab === MonthlyParkerTab.Active ) {            
            const data: ParkerListEntry = event.data;
            nav( `/facility/${facilityId}/parker/${event.data.parkerId}` );
        } else if( tab === MonthlyParkerTab.Applied || tab === MonthlyParkerTab.Waiting ) {
            const data: FacilitySub = event.data;
            nav( `/facility/${facilityId}/parker/${data.parkerId}/plan/${data.subscriptionId}` );
        }
    };
        
    const applied = subs.filter( s => s.status == SubscriptionStatus.Applied            );
    const waiting = subs.filter( s => s.status == SubscriptionStatus.WaitingForContract );

    //footer
    const footer = useMemo( () => {
        const totalGuests = activeParkers.map( p => p.guests  ).reduce( (a, b) => a + b, 0 );
        const totalBal    = activeParkers.map( p => p.balance ).reduce( ( a, b ) => add( a, b ), cents( 0 ) );
        const totalSpots  = activeParkers.map( p => p.spots   ).reduce( ( a, b ) => a + b, 0 );
        const totalMrr    = activeParkers.map( p => p.mrr     ).reduce( ( a, b ) => add( a, b ), cents( 0 ) );

        const f = {
            spots:    totalSpots,
            balance:  totalBal,
            mrr:      totalMrr,
        }
        return [ f ];
    }, [activeParkers] );

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto auto 1fr 5px", gridAutoColumns: "1fr" }}>
                <div className="d-flex align-items-center gap-2">
                    <PageTitle>
                        Monthly Parkers
                    </PageTitle>
                    <ToolButton onClick={() => nav( `/facility/${facilityId}/parker/invite` )}>
                        <Asterisk color="maroon" /> Invite Parker
                    </ToolButton>
                </div>
                <Tabs
                    defaultActiveKey={tab}
                    activeKey={tab}
                    onSelect={ ( k ) => nav( `/facility/${facilityId}/parker/${k}`) }
                    className="w-100 border-bottom-0"
                    variant="underline"
                    justify>
                    <Tab eventKey={MonthlyParkerTab.Active} title="Active" className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={activeParkers}
                                columnDefs={activeColumns}
                                onGridReady={onGridReady}
                                onRowClicked={onRowClicked}
                                pinnedBottomRowData={footer}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey={MonthlyParkerTab.Waiting} 
                         title={<div className="d-flex-align-center justify-content-center">
                            Waiting for Contract {waiting.length !== 0 && <Badge className="rounded-5 d-flex fw-normal justify-content-center px-2">{waiting.length} New</Badge>}
                        </div>}
                        className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={waiting}
                                columnDefs={appliedColumns}
                                onGridReady={onGridReady}
                                onRowClicked={onRowClicked} />
                        </div>
                    </Tab>
                    <Tab eventKey={MonthlyParkerTab.Applied}
                         title={<div className="d-flex-align-center justify-content-center">
                             Applied {applied.length !== 0 && <Badge className="rounded-5 d-flex fw-normal justify-content-center px-2">{applied.length} New</Badge>}
                         </div>}
                         className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={applied}
                                columnDefs={appliedColumns}
                                onGridReady={onGridReady}
                                onRowClicked={onRowClicked} />
                        </div>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>;
}