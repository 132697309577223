import { useEffect } from 'react';
import { Modal, Form, Col, Container, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { SubscriptionParkerChangeReq } from "./Data/ApiTypes";
import { Button } from "./Controls/Button";
import { HeaderText } from "./Controls/HeaderText";
import { Input } from "./Controls/Input";

interface QuantityModalProps {
    show?:   SubscriptionParkerChangeReq;
    setShow: (show?: SubscriptionParkerChangeReq) => void;
    onSave:  (data: SubscriptionParkerChangeReq) => void;
}

export function QuantityModal( { show, setShow, onSave }: QuantityModalProps) {
    const val = show ? show : {};
    const { handleSubmit, register, reset, watch, setValue, formState: { errors } } =
        useForm<SubscriptionParkerChangeReq>( { defaultValues: show } );

    const values = watch();

    function submit( data: SubscriptionParkerChangeReq ) {
        onSave(data);
        reset( {} );
        setShow(undefined);
    }

    useEffect( () => {
        if( show ) {
            reset( show );
        }
    }, [show] )

    return (
        <Modal show={!!show} centered>
            <Modal.Header>
                <HeaderText>Change Quantity</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit( submit )}>
                    <Container className="g-0">
                        <Row>
                            <Col className="text-secondary">
                                This will change the number of spots allocated to the parker and will limit the number of vehicles they can add
                            </Col>
                        </Row>
                        <Row className="py-4">
                            <Col className="wauto text-center">
                                <Input
                                    type="number"
                                    label="Number of Spaces"
                                    autoFocus
                                    className="text-end"
                                    inputClassName="text-end"
                                    min={1}
                                    {...register( "quantity", { required: true, valueAsNumber: true } ) }
                                    error={errors.quantity} />
                                    {/* <AddSubtract quantity={values.quantity} set={ n => setValue("quantity", n ) } /> */}
                            </Col>
                        </Row>
                    </Container>
                    <div className="d-flex justify-content-end gap-2">
                        <Button
                            type="button"
                            className="w-100"
                            onClick={() => setShow(undefined)}>
                            Cancel
                        </Button>
                        <Button type="submit" className="w-100">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}