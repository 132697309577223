import { LocalDateTime } from "@js-joda/core";
import { cents, Money         } from "./Money";
import { Dinero } from "dinero.js";

export enum VehicleColor {
    White  = "White",
    Black  = "Black",
    Gray   = "Gray",
    Silver = "Silver",
    Red    = "Red",
    Green  = "Green",
    Blue   = "Blue",
    Brown  = "Brown",
    Orange = "Orange",
  //Beige  = "Beige",
    Purple = "Purple",
    Gold   = "Gold",
    Yellow = "Yellow"
}

export const colors = [
    VehicleColor.White,
    VehicleColor.Black,
    VehicleColor.Gray,
    VehicleColor.Silver,
    VehicleColor.Red,
    VehicleColor.Green,
    VehicleColor.Blue,
    VehicleColor.Brown,
    VehicleColor.Orange,
  //VehicleColor.Beige,
    VehicleColor.Purple,
    VehicleColor.Gold,
    VehicleColor.Yellow
];

export interface Vehicle {
    stateCode         : string;
    licensePlateNumber: string;
    make              : string;
    model             : string;
    color             : VehicleColor;
}

export interface BillingAddress {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: string;
};

export interface PaymentMethod {
    cardHolderName: string;
    cardBrand:      string;
    cardNo:         string;
    expDate:        { mon: number, year: number };
    billingAddress: BillingAddress;
    isDefault:      boolean;
}