import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { ApplyPayment, Payment, PaymentAdjustReq, PaymentChange } from "./Data/ApiTypes";
import { PageTitle } from "./Controls/PageTitle";
import { cents, showMoney, toCents } from "./Data/Money";
import { showInstantDateTime } from "./Data/Date";
import { MediumPage } from "./Controls/MediumPage";
import { gridCols, useParamIds } from "./Data/Common";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { PlusSlashMinus, Receipt } from "react-bootstrap-icons";
import { ConnectPaymentDetails } from "@stripe/react-connect-js";
import { Fragment, useState } from "react";
import { Instant } from "@js-joda/core";
import { PaymentAdjustForm, PaymentAdjustModal } from "./FacilityParkerPaymentDetailsPagePaymentAdjustModal";
import { Api } from "./Data/Api";
import { OrderBalanceType } from "./Data/ApiTransport";
import { Hyperlink } from "./Controls/Hyperlink";
import { showPaymentTypeAction } from "./Data/Finance";
import { InfoIconSmall, TooltipContent } from "./Controls/InfoIcon";

interface PaymentChangeInfoProps {
    chg: PaymentChange;
}

function PaymentChangeInfo( { chg } : PaymentChangeInfoProps ): import( "react" ).ReactNode {
    const { amount, balance } = chg;
    return <Fragment>
        <div>
            {showPaymentTypeAction( chg.type )} by {showMoney( amount )}
        </div>
        <div className="text-end">
            {showMoney( balance )}
        </div>
    </Fragment>;
}

interface ApplicationInfoProps {
    appl: ApplyPayment;
}

function ApplicationInfo( { appl } : ApplicationInfoProps ): import( "react" ).ReactNode {
    const { facilityId, orderId, paid, parkerId, type, balance } = appl;
    const orderUrl = `/facility/${facilityId}/parker/${parkerId}/order/${orderId}`;
    const hyperlink = <TooltipContent tooltip={`Resulting Order Balance: ${showMoney( balance )}`}>
        <Hyperlink to={orderUrl}>Order #{orderId}</Hyperlink>
    </TooltipContent>;
    switch( type ) {
        case OrderBalanceType.ApplyPayment:
            return <Fragment>
                <div>Applied {showMoney( paid )} to {hyperlink}</div>
                <div></div>
            </Fragment>;

        case OrderBalanceType.UndoApplyPayment:
            const undoAmt = cents( Math.abs( toCents( paid ) ) )
            return <Fragment>
                <div>Removed {showMoney( undoAmt )} from {hyperlink}</div>
                <div></div>
            </Fragment>;

        default:
            return "Unknown OrderBalanceType";
    }
}

export function FacilityParkerPaymentDetailsPage() {
    const { revalidate } = useRevalidator();
    const [showStripe, setShowStripe] = useState( false );
    const [showAdjust, setShowAdjust] = useState( false );
    const { facilityId, parkerId, paymentId } = useParamIds();
    const payment  = useLoaderData() as Payment;
    const facility = payment.facility;
    const changes  = payment.changes;

    interface ApplicationEntry {
        type:       "Application";
        application: ApplyPayment;
        when: Instant;
    }
    interface PaymentChangeEntry { 
        type:   "PaymentChange";
        change: PaymentChange;
        when: Instant;
    }
    type Entry = PaymentChangeEntry | ApplicationEntry;
    const merged: Entry[] = [
        ...payment.applications.map<Entry>( app => ( {
            type:        "Application",
            application: app,
            when:        app.createdWhen
        } ) ),
        ...payment.changes.map<Entry>( chg => ( {
            type:   "PaymentChange",
            change: chg,
            when:   chg.createdWhen
        } ) )
    ].toSorted( (a, b) => a.when.compareTo( b.when ) );

    function viewInStripe(): void { setShowStripe( true ); }
    function adjustModal():  void { setShowAdjust( true ); }

    async function saveAdjustment( adjustData: PaymentAdjustForm ) {
        const req: PaymentAdjustReq = {
            paymentId:     paymentId,
            currentAmount: payment.remaining, 
            newAmount:     cents( adjustData.newAmount ),
            note:          adjustData.note,
        };
        const res = await Api.paymentAdjust( facilityId, parkerId, req );
        if( res.isOk ) {
            revalidate(); //reload this page
            return;
        } //show the err
        alert( res.error );
    }

    return <MediumPage>
        {showStripe && <ConnectPaymentDetails
            payment={payment.paymentKey!}
            onClose={ () => setShowStripe( false )} />}
        <PaymentAdjustModal
            currentAmount={payment.remaining}
            show={showAdjust}
            setShow={setShowAdjust}
            onSave={saveAdjustment} />
        <PageBar title={`Payment #${payment.paymentId}`}>
            {payment.paymentKey !== null && <ToolButton onClick={viewInStripe}>
                <Receipt className="fs-6" /> Stripe Details
            </ToolButton>}
            {payment.paymentKey === null && <ToolButton onClick={adjustModal}>
                <PlusSlashMinus className="fs-6" /> Adjust Payment
            </ToolButton>}
        </PageBar>
        <Container className="g-0">
            <Row>
                <Col className="fs-6" sm="12" lg="6">
                    <table className="table w-100">
                        <tbody>
                            <tr>
                                <td>Date</td>
                                <td className="text-end" colSpan={2}>{showInstantDateTime( payment.createdWhen, facility.timeZone )}</td>
                            </tr>
                            <tr>
                                <td>Method</td>
                                <td className="text-end" colSpan={2}>
                                    {payment.paymentMethod}
                                </td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td className="fw-bold text-end">{showMoney( payment.amount )}</td>
                            </tr>
                            {changes.length != 0 && <tr className="fw-bold">
                                <td>Remaining</td>
                                <td className="text-end">{showMoney( payment.remaining )}</td>
                            </tr>}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
        <PageTitle>
            Payment History
        </PageTitle>
        <div style={{ ...gridCols( "max-content 1fr max-content" ), columnGap: "1em", rowGap: "0.5em" }} className="mb-2">
            <div>Date   </div>
            <div>Entry  </div>
            <div className="text-center">
                Result
            </div>
            <Card className="border-1 border-primary" style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1" }}>
                <Card.Body style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1" }}>
                    <div>
                        {showInstantDateTime( payment.createdWhen, facility.timeZone )}
                    </div>
                    <div>
                        {showPaymentTypeAction( payment.type )} by {showMoney( payment.amount )}
                    </div>
                    <div className="text-end d-flex-align-center">
                        {/* <TooltipContent tooltip="The amount of money represented by the payment at the end of the history entry."> */}
                            {showMoney( payment.amount )}     
                        {/* </TooltipContent> */}
                    </div>
                </Card.Body>
            </Card>
            {merged.map( x => {
                if( x.type === "Application" ) {
                    const appl = x.application;
                    return <Card style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1"}}>
                        <Card.Body style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1"}}>
                            <div>
                                {showInstantDateTime( appl.createdWhen, facility.timeZone )}
                            </div>
                            <ApplicationInfo appl={appl} />
                        </Card.Body>
                    </Card>;
                }
                if( x.type === "PaymentChange" ) {
                    const chg = x.change;
                    return <Card className="border-primary" style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1"}}>
                        <Card.Body style={{ display: "grid", gridTemplateColumns: "subgrid", gridColumn: "1 / -1" }}>
                            <div>
                                {showInstantDateTime( chg.createdWhen, facility.timeZone )}
                            </div>
                            <PaymentChangeInfo chg={chg} />
                        </Card.Body>
                    </Card>;
                }
            } ) }
        </div>
    </MediumPage>;
}