import { Badge, Col, Container, Row } from "react-bootstrap";
import { ArrowLeftCircle, ArrowUpCircle, CarFrontFill, ClockHistory, CurrencyDollar, InboxFill, PersonCircle, Receipt } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation } from "react-router-dom";
import { useParamIds   } from "./Data/Common";
import { ParkerDetails } from "./Data/ApiTypes";
import { PageTitle     } from "./Controls/PageTitle";
import { cents, Money, showMoneyShort } from "./Data/Money";
import { equal } from "dinero.js";
import { MenuItem } from "./Controls/MenuItem";

function OrdersMenuBadge( { balance }: { balance: Money } ) {
    const zero = cents( 0 );
    const isPaid = equal( balance, zero );
    return <Badge className="border border-primary bg-white fw-normal text-primary">
        {isPaid ? "Paid" : `${showMoneyShort( balance )} Due`}
    </Badge>;
}

export function AppFacilityParker() {
    const loc = useLocation();
    const { facilityId, parkerId } = useParamIds();
    const parker = useLoaderData() as ParkerDetails;

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/facility/${facilityId}/parker/${parkerId}` ) ? ["", "d-none"] : ["d-none", ""];
    const active = loc.pathname === ( `/facility/${facilityId}/parker/${parkerId}` ) ? true : false;

    return <Container fluid className="fullheight pt-2">
        <Row className="fullheight">
            <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight border-end border-1 d-print-none`}>
                <div className="facility-menu-item">
                    <div className="fs-4 d-flex align-items-center justify-content-center gap-1 grid-col-span-5 text-center align-self-center">
                        <PageTitle className="fw-normal text-truncate">                            
                            {parker.name}
                        </PageTitle>
                        <span className="fs-6">
                            <OrdersMenuBadge balance={parker.balance} />
                        </span>
                    </div>
                </div>
                <MenuItem default icon={<ArrowLeftCircle />} to={`/facility/${facilityId}/parker`} text="Back to Parkers" />
                <MenuItem active={active}
                          default icon={<PersonCircle   />} to="./overview" text="Overview" />
                <MenuItem         icon={<ClockHistory   />} to="./balance/history" text="History" />
                <MenuItem         icon={<InboxFill      />} to="./order"    text="Orders" />
                <MenuItem         icon={<Receipt        />} to="./invoice"  text="Invoices" />
                <MenuItem         icon={<CurrencyDollar />} to="./payment"  text="Payments" />
                <MenuItem         icon={<CarFrontFill   />} to="./vehicle"  text="Vehicles" />
            </Col>
            <Col md={7} lg={7} xl={8} xxl={9} className={`${contentClassName} d-md-block fullheight scroll-y-auto`}>
                <div className="d-md-none w-100 p-3 d-flex justify-content-center d-print-none">
                    <NavLink to="" className="d-flex align-items-center gap-1">
                        <ArrowUpCircle />Return to Parker Menu
                    </NavLink>
                </div>
                <Outlet />
            </Col>
        </Row>
    </Container>;
}